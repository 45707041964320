import { Navigate, useLocation, matchRoutes } from "react-router-dom";
import { IUser, OnboardingStatus } from "../types/user";
import Layout from "../components/layout/Layout";

type ProtectedRouteProps = {
    isAuthenticated: Boolean,
    children: React.ReactNode,
    user?: IUser,
    onProductTourEnable: () => void,
    onLogout: () => void,
}

const ProtectedRoutes = ({ isAuthenticated, children, user, onLogout, onProductTourEnable }: ProtectedRouteProps) => {
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (user?.customStatus === 'unverified' && location.pathname !== "/verify") {
        return <Navigate to="/verify" replace />;
    }

    //TODO - Remove this condition if all user states are captured on OnboardingStatus object
    if (user?.customStatus === 'active') {

        if (user?.onboardingStage === OnboardingStatus.ProfileSetup && location.pathname !== "/update-profile") {
          //return navigate('/update-profile');
            return <Navigate to="/update-profile" replace />;
        }

        if (user?.onboardingStage === OnboardingStatus.JobSetup) {
            // These are the possible routes within each userRole under JobProfile onboarding stage
            const isUserJobProfilePath = Array.isArray((matchRoutes([{ path: "/jobProfile/*" }, { path: "/profile" }, { path: "/jobRating/*" }], location.pathname)))
            const isRecruiterJobPostPath = Array.isArray((matchRoutes([{ path: "/jobPost/*" }, { path: "/jobPosts/*" }, { path: "/profile" }], location.pathname)))

            if (user?.userRole === 'user' && !isUserJobProfilePath) {
                return <Navigate to="/jobProfile/selectJob" replace />
            }

            if (user?.userRole === 'recruiter' && !isRecruiterJobPostPath) {
                return <Navigate to="/jobPost/selectJob" replace />
            }
        }
    }

    return <Layout user={user} onLogout={onLogout} onProductTourEnable={onProductTourEnable}>{children}</Layout>;
};
export default ProtectedRoutes;