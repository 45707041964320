import { createContext, useContext } from "react";

import { api } from "../api";
import { IJobMatchActivity } from "../types/match";

export type JobMatchActivityContextValue = {
  saveMatchActivity: (jobMatchActivity: IJobMatchActivity) => Promise<boolean>;
};

const JobMatchActivity =
  createContext<JobMatchActivityContextValue | undefined>(undefined);

export const useJobMatchActivity = () => useContext(JobMatchActivity);

const JobMatchActivityProvider = ({ children }: any) => {
  const saveMatchActivity = async (jobMatchActivity: IJobMatchActivity): Promise<boolean> => {
    try {
      const result = await api.post("items/match_activities", jobMatchActivity);
      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  return (
    <JobMatchActivity.Provider value={{ saveMatchActivity }}>
      {children}
    </JobMatchActivity.Provider>
  );
};

export default JobMatchActivityProvider;
