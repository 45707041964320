import { Box, Center, Spinner, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { JobPostContextValue, useJobPost } from "../../stores/JobPostProvider";
import JobPost from "../../components/recruiter/jobPost/JobPost";
import { CompetencyRatingMap } from "../../components/shared/CategoryRater";

import { IJobPost } from "../../types/job-post";
import { JobContextValue, useJob } from "../../stores/JobProvider";

const RecruiterJobPostContainer = () => {
  const { jobPostId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { currentUser } = useUser() as UserContextValue;
  const { jobPost, isFetchingJobPost, fetchJobPost, updateJobPost } = useJobPost() as JobPostContextValue;
  const { job, fetchJob } = useJob() as JobContextValue;

  
  useEffect(() => {
    if (currentUser && !jobPost) {
      fetchJobPost(jobPostId as string);
    }

    if(jobPost && !job) {
      fetchJob((jobPost as any).jobId as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPost, job]);

  if (isFetchingJobPost) {
    <Center height="90vh">
      <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
    </Center>
  }

  if (!currentUser || !jobPost) {
    return null;
  }

  const id = (jobPost as any)?.id || '';

  const onSubmit = (
    jobPostDetails: any,
    updatedValues: CompetencyRatingMap
  ) => {
    updateJobPost(id, jobPostDetails, updatedValues).then(() => {
   
      toast({
        title: "Job Post updated!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(`/profileMatches?job-post=${id}`);
    });
  };

  const _jobPost: IJobPost = jobPost as any as IJobPost;
  return (
    <Box px={20} py={15}>
      <JobPost job={job} jobPost={_jobPost} onSubmit={onSubmit} />
    </Box>
  );
};

export default RecruiterJobPostContainer;
