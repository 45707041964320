import { IFilter, IFilterValue } from "../types/ui/search";

export const getDefaultFilterValues = (filters: IFilter[], statusOverride: string | null, jobRatingOverride: string | null, jobPostOverride: string | null) => {
  const filterDefaultValues: IFilterValue[] = [];
  filters?.forEach((f) => {
    if (f.slug === 'status' && statusOverride !== null) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: statusOverride
      });
    }
    else if (f.slug === 'job-rating' && jobRatingOverride !== null) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: jobRatingOverride
      });
    }
    else if (f.slug === 'job-rating' && jobRatingOverride !== null) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: jobRatingOverride
      });
    }
    else if (f.slug === 'job-post' && jobPostOverride !== null) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: jobPostOverride
      });
    }
    else if (f.defaultValue) {
      filterDefaultValues.push({
        slug: f.slug,
        selectedValue: f.defaultValue,
      });
    }
  });
  return filterDefaultValues;
};