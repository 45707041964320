import {
  Box, Checkbox, CheckboxGroup, HStack, Icon, Radio, RadioGroup, Stack,
} from "@chakra-ui/react";
import { StringOrNumber } from "@chakra-ui/utils";
import { ReactNode } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { IFilter, IFilterOption, IFilterValue } from "../../types/ui/search";
import { text } from "../util/texts";

interface FilterDetailProps {
  filter: IFilter,
  filterValue?: IFilterValue,
  onSelect: (value: string | string[]) => void
  optionRenderer?: (option: IFilterOption) => ReactNode
}

const FilterDetail = ({
  filter,
  filterValue,
  onSelect,
  optionRenderer,
}: FilterDetailProps) => {
  const renderOptionLabel = (option: IFilterOption) => {
    const count = option.count || 0;
    // return optionRenderer ? optionRenderer(option) : text('body.regular', `${option.name} (${count})`);
    return optionRenderer ? optionRenderer(option) : text('body.regular', `${option.name}`);
  }
  // const btnRef = useRef(null);
  // const screenSize = useBreakpointValue({ base: 'small', md: 'medium', lg: 'large' });
  const location = useLocation();
  const renderSingleChoice = () => {
    let selectedOption = (filterValue?.selectedValue as string) || (filter.defaultValue as string) || undefined;
    return (
      <RadioGroup key={location.key} id={filter.slug} onChange={onSelect} defaultValue={selectedOption}>
        <Stack>
          {filter.options.map((option, idx) => {
            return (
              <Radio key={idx} value={option.value}>{renderOptionLabel(option)}</Radio>
            )
          })}
        </Stack>
      </RadioGroup>
    )
  }

  const [searchParams] = useSearchParams();
  const renderMultiChoice = () => {
    let selectedOptions = (filterValue?.selectedValue as string[]) || (filter.defaultValue as string[]) || undefined;

    const handleCheck = (value: StringOrNumber[]) => {
      const transformedValue = value ? (value as string[]) : [];
      onSelect(transformedValue);
    }
    const term = searchParams.get(filter.slug);
    let value = term && term?.length > 0 ? term?.split(',') : selectedOptions;

    return (
      <CheckboxGroup onChange={handleCheck} value={value}>
        <Stack>
          {filter.options.map((option, idx) => {
            let value = false;
            if (term && option.value) {
              value = term?.indexOf(option.value) > -1
            }
            return (
              <Checkbox key={idx} value={option.value} defaultChecked={value}>{renderOptionLabel(option)}</Checkbox>
            )
          })}
        </Stack>
      </CheckboxGroup>
    )
  }

  return (
    <Box ml={0}>
      <HStack align="center">
        {filter.displayIcon && <Icon as={filter.displayIcon} />}
        {text('label.regular', filter.name, 2)}
      </HStack>
      {/* {label(filter.name, 2)} */}
      {filter.isMultiChoice ? renderMultiChoice() : renderSingleChoice()}
    </Box>
  )
};

export default FilterDetail;