import { UserContextValue, useUser } from "../stores/UserStore";
import RecruiterHomeContainer from "./recruiter/RecruiterHomeContainer";
import UserHomeContainer from "./user/UserHomeContainer";
import NavigatorHomeContainer from "./navigator/NavigatorHomeContainer";
import { UserRole } from "../types/user";

const HomeContainer = () => {
  const { currentUser } = useUser() as UserContextValue;
  if (!currentUser) {
    return null;
  }

  if (currentUser.userRole === "user") {
    return <UserHomeContainer />;
  } 

  if (currentUser.userRole === "recruiter") {
    return <RecruiterHomeContainer />;
  }

  if (currentUser.userRole === UserRole.Navigator) {
    return <NavigatorHomeContainer />
  }

  return null;
};

export default HomeContainer;
