import { CompetencyRatingMap } from '../components/shared/CategoryRater';
import { ICategory, IJob } from '../types/job';
import { IJobMatch, IJobMatchCompetency } from '../types/match';
import {
  IGenericCompetencyCategoryRatingsContainer,
  IGenericCompetencyRatingsContainer,
} from '../types/rating';

export const getCompetencyRatingMap = (competencyRatingsContainer?: IGenericCompetencyRatingsContainer): CompetencyRatingMap => {
  const competencyRatingMap: CompetencyRatingMap = {};
  if (competencyRatingsContainer) {
    for (const competencyRating of competencyRatingsContainer?.competencyRatings) {
      competencyRatingMap[competencyRating.competencyId] = competencyRating.rating;
    }
  }  
  return competencyRatingMap;
} 

export const groupRatingsByCategory = (
  jobMap?: IJob,
  competencyRatingsContainer?: IGenericCompetencyRatingsContainer
): IGenericCompetencyCategoryRatingsContainer[] => {
  if (!jobMap || !competencyRatingsContainer) {
    return [];
  }

  const competencyCategoryRatings: IGenericCompetencyCategoryRatingsContainer[] =
    [];
  for (const category of jobMap.categories) {
    competencyCategoryRatings.push(
      getCategoryRating(category, competencyRatingsContainer)
    );
  }
  return competencyCategoryRatings;
};

export const getCategoryRating = (
  category: ICategory,
  competencyRatingsContainer: IGenericCompetencyRatingsContainer
): IGenericCompetencyCategoryRatingsContainer => {
  const competencyCategoryRating: IGenericCompetencyCategoryRatingsContainer = {
    categoryId: category.id,
    competencyRatings: [],
  };
  for (const competency of category.competencies) {
    const competencyRating = competencyRatingsContainer.competencyRatings.find(
      (cr) => cr.competencyId === competency.id
    );

    if (competencyRating) {
      competencyCategoryRating.competencyRatings.push(competencyRating);
    }
  }

  return competencyCategoryRating;
};

export const getCategoryMatches = (
  category: ICategory,
  jobMatch: IJobMatch
): IJobMatchCompetency[] => {
  const categoryCompetencyMatches: IJobMatchCompetency[] = [];
  for (const competency of category.competencies) {
    const competencyMatch = jobMatch.competencies.find(
      (cr) => cr.competencyId === competency.id
    );
    if (competencyMatch) {
      categoryCompetencyMatches.push(competencyMatch);
    }
  }
  return categoryCompetencyMatches;
};
