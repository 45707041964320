import { UnorderedList, ListItem, Flex, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

function PasswordError({password}: {password: string}) {
  const [lengthError, setLengthError] = useState(false);
  const [letterError, setLeterError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [specialCharcterError, setspecialCharcterError] = useState(true);

  useEffect(() => {
    setLengthError(password.length < 8);
    setLeterError(/[a-zA-Z]+/.test(password));
    setNumberError(/[0-9]+/.test(password));
    setspecialCharcterError(/[~`!@#$%^&*()]+/.test(password));
  }, [password]);

  return (
    <Flex flexDirection={"column"}>
      {(lengthError || letterError === false || numberError === false || specialCharcterError === false)  && <Heading as="h3" size={"sm"}>Invalid password. Your password must include </Heading>}
      <UnorderedList>
          {lengthError === true && <ListItem>At least 8 characters long</ListItem> }
          {letterError === false &&<ListItem>Must have at least one letter</ListItem>}
          {numberError === false && <ListItem>Must have at least one number</ListItem>}
          {specialCharcterError === false && <ListItem>Must have at least one special character</ListItem>}
      </UnorderedList>
    </Flex>
  )
}

export default PasswordError