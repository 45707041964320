import { Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import JobPostsPage from "../../components/recruiter/jobPost/JobPostsPage";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { JobPostContextValue, useJobPost } from "../../stores/JobPostProvider";

const RecruiterJobPostsContainer = () => {
  const { currentUser } = useUser() as UserContextValue;

  const { jobPosts, isFetchingJobPosts, fetchJobPosts } = useJobPost() as JobPostContextValue;
  useEffect(() => {
    if (currentUser) {
      fetchJobPosts();
    }
  }, []);

  if (isFetchingJobPosts) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }

  if (!currentUser || !jobPosts) {
    return null;
  }

  return <JobPostsPage jobPosts={jobPosts} />;
};

export default RecruiterJobPostsContainer;
