import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import RecruiterProfile from "../components/recruiter/profile/RecruiterProfile";
import UserProfile from "../components/user/profile/UserProfile";
import { text } from "../components/util/texts";
import { UserContextValue, useUser } from "../stores/UserStore";
import { IRecruiter, OnboardingStatus } from "../types/user";

const ProfileContainer = () => {
  const { currentUser, fetchCurrentUser, updateProfile, updateOnboardingStatus, uploadUserFile } = useUser() as UserContextValue;
  const navigate = useNavigate();
  
  if (!currentUser) {
    return null;
  }

  const handleOnboardingUpdate = async () => {
    if(currentUser?.onboardingStage !== OnboardingStatus.Complete){
      await updateOnboardingStatus(OnboardingStatus.JobSetup);
      return navigate('/');
    }
  }

  const onSubmit = async (values: any, file?: File, avatarImg?: File) => {
    if (!currentUser) {
      return null;
    }    

    if (file !== undefined) {
      const fileId = await uploadUserFile(file);
      values.resume = fileId
    }

    if (avatarImg !== undefined) {
      const fileId = await uploadUserFile(avatarImg);
      values.avatar = fileId;
    }
    
    const updateResult = await updateProfile(values);
    

    if(updateResult === 'success'){
      await fetchCurrentUser();
      await handleOnboardingUpdate();
    }
  };

  switch (currentUser?.userRole) {
    case 'user': {
      return (
        <Box px={20} py={15}>
          {text("page.title", "My Profile")}
          <UserProfile user={currentUser} onSubmit={onSubmit} />
        </Box>
      )
    }
    case 'recruiter': {
      const recruiter = currentUser as IRecruiter;
      return (
        <Box px={20} py={15}>
          {text("page.title", "My Profile")}
          <RecruiterProfile user={recruiter} onSubmit={onSubmit} />
        </Box>
      )
    }
    default:
      return null;
  }
};

export default ProfileContainer;
