import { Box, Divider, Radio, RadioGroup, Stack, Tag, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ICategory, ICompetency } from "../../types/job";
import { ProficiencyLevel } from "../../types/proficiency-level";
import { IGenericCompetencyCategoryRatingsContainer } from "../../types/rating";
import { UserRole } from "../../types/user";
import { getColorForLevel } from "../../utils/proficiency-levels";
import { text } from "../util/texts";

export interface CompetencyRatingMap {
  [competencyId: string]: ProficiencyLevel;
}

interface CategoryRaterProps {
  category: ICategory;
  ratingsValues?: CompetencyRatingMap;
  onChange: (updatedValues: CompetencyRatingMap) => void;
  ratingRole: UserRole;
}

const getInitValues = (categoryRating?: IGenericCompetencyCategoryRatingsContainer) => {
  const initValues: CompetencyRatingMap = {};  
  if (categoryRating) {
    categoryRating.competencyRatings.forEach(c => initValues[c.competencyId] = c.rating.toString() as unknown as ProficiencyLevel);
  }
  return initValues;
}

const CategoryRater = ({
  category,
  ratingsValues,
  onChange,
  ratingRole,
}: CategoryRaterProps) => {
  // const initValues = getInitValues(categoryRating);
  const [values, setValues] = useState<CompetencyRatingMap>(ratingsValues || {});

  useEffect(() => {
    // const initValues = getInitValues(ratingsValues || {});
    setValues(ratingsValues || {});
  }, [ratingsValues]);

  const renderCompetencyRater = (competency: ICompetency, ordinal: number) => {
    const handleCompetencyChange = (value: string) => {
      const updatedValues: CompetencyRatingMap = { ...values, [competency.id]: value as unknown as ProficiencyLevel };
      onChange(updatedValues);
      setValues(updatedValues);
    }

    const rawValue = values[competency.id]
    const currentValue = rawValue !== undefined ? ""+rawValue : undefined;

    const notApplicable = ratingRole === UserRole.User ? 'No Proficiency' : 'Competency Not Applicable'
    return (
      <Box>
        {text('bold.lg', `${ordinal}. ${competency.title}`)}
        <RadioGroup id={competency.id} onChange={handleCompetencyChange} value={currentValue}>
          <Stack>
            <Radio value={ProficiencyLevel.Knowledge.toString()}>              
              <Tag mr={"2"} colorScheme={getColorForLevel(ProficiencyLevel.Knowledge)} variant='outline'>Knowledge</Tag>               
              {competency.knowledgeRubric}
            </Radio>
            <Radio value={ProficiencyLevel.Skill.toString()}>
              <Tag mr={"2"} colorScheme={getColorForLevel(ProficiencyLevel.Skill)} variant='outline'>Skill</Tag> 
              {competency.skillRubric}
            </Radio>
            <Radio value={ProficiencyLevel.Ability.toString()}>
              <Tag mr={"2"} colorScheme={getColorForLevel(ProficiencyLevel.Ability)} variant='outline'>Ability</Tag> 
              {competency.abilityRubric}
            </Radio>
            <Radio value={ProficiencyLevel.Not_Applicable.toString()}>
              <Text fontStyle="italic">
                {notApplicable}
              </Text>
            </Radio>
          </Stack>
        </RadioGroup>
      </Box>
    )
  }
  
  return (
    <Box>
      {text('section.title', `Skills Group: ${category.title}`)}
      {text('section.instruction', category.description ? category.description : '', 5)}
      <Divider mb={3} />
      {text('section.subtitle', 'Skills', 5)}
      { category.competencies.map((c, index) => {
        return (
          <Box mb={5} key={c.id}>
            {renderCompetencyRater(c, index+1)}
          </Box>
        )
      })}
    </Box>
  )
}

export default CategoryRater;