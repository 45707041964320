import { Box, Grid, GridItem, HStack, VStack } from "@chakra-ui/react";

import { IJobMatch, JobMatchStatus } from "../../../types/match";
import MatchPercentage from "../../shared/MatchPercentage";
import { StarIcon } from "@chakra-ui/icons";
import { text } from "../../util/texts";

import { locationTag, salaryTag, statusTag, workTypeTag } from "../../util/tags";

interface JobMatchItemProps {
  jobMatch: IJobMatch,
  onClick: () => void,
  isActive?: boolean,
  tagVariant?: string,
}

let dollarUSLocale = Intl.NumberFormat('en-US');

const JobMatchItem = ({ jobMatch, onClick, isActive, tagVariant = 'subtle' }: JobMatchItemProps) => {
  const tagOptions = { size: 'sm', variant: tagVariant };
  const renderStatus = () => {
    return (
      <HStack mb={2}>
        {jobMatch.userStatus && statusTag(jobMatch.userStatus.status, { colorScheme: 'teal', ...tagOptions })}
        {jobMatch.recruiterStatus?.status as JobMatchStatus === JobMatchStatus.RecruiterInvited && statusTag('Invited to Apply', { colorScheme: 'purple', ...tagOptions })}
      </HStack>
    )
  }

  const color = jobMatch.matchPercentage > 80 ? 'green' : 'orange';
  const starIconSize = { base: 4, md: 5 };

  return (
    <Box px={5} py={5} onClick={onClick} cursor="pointer">
      <Grid templateColumns='repeat(6, 1fr)' gap={2}>
        {/* <GridItem colSpan={1}>
          { isActive && <DotIcon color="blue.500" boxSize={3} /> }
        </GridItem> */}
        {/* <GridItem colSpan={1}>
          <Avatar name={jobMatch.jobPost.company.name} size="sm" />
        </GridItem> */}
        <GridItem colSpan={5}>
          {text('card.subtitle', jobMatch.jobPost.companyJobTitle)}
          {text('body.regular', jobMatch.company.name, 0)}
          {text('body.sm', jobMatch.jobPost.location, 2)}
          {renderStatus()}
          <HStack mb={3}>
            {salaryTag(dollarUSLocale.format(jobMatch.jobPost.salary), tagOptions)}
            {workTypeTag(jobMatch.jobPost.workType, tagOptions)}
            {locationTag(jobMatch.jobPost.locationType, tagOptions)}
          </HStack>
        </GridItem>
        <GridItem>
          <VStack>
            <MatchPercentage color={color} percentage={jobMatch.matchPercentage} size="sm" label="" />
            <StarIcon
              color="gray.400"
              _hover={{
                color: "blue.400",
              }}
              w={starIconSize}
              h={starIconSize}
            />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default JobMatchItem;