import React, { useState } from "react";
import {
  Flex,
  Box,
  Text,
  Tag,
  TagLeftIcon,
  TagLabel,
  Button,
  HStack,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SimpleGrid,  
  Link,
} from "@chakra-ui/react";
import { getColorForLevel } from "../../utils/proficiency-levels";
import { ProficiencyLevel } from "../../types/proficiency-level";
import { ICompetency } from "../../types/job";
import { IUpskillingResource } from "../../types/upskillingResource";
import { get, startCase } from "lodash";
import MatchIndicator from "../shared/MatchIndicator";
import { text } from "../util/texts";
import { borders } from "../../styles/common";
import { IGenericCompetencyRating } from "../../types/rating";
import { IJobMatchCompetency } from "../../types/match";
import { BiLinkExternal } from "react-icons/bi";
import { MdListAlt } from "react-icons/md";
import { CheckIcon } from "@chakra-ui/icons";

interface CompetencyProps {
  competency: ICompetency;
  competencyRating?: IGenericCompetencyRating;
  competencyMatch?: IJobMatchCompetency;
  hideDescription?: boolean;
  isJobSeeker?: boolean;
  inlineUpskillingMode?: boolean;
}

const Competency = ({
  competency,
  competencyRating,
  competencyMatch,
  hideDescription = false,
  isJobSeeker = true,
  inlineUpskillingMode = false,
}: CompetencyProps) => {
  const showRating = competencyRating !== undefined;
  const showMatch = competencyMatch !== undefined;
  const firstResource = (competency.upskillingResources !== undefined && competency.upskillingResources.length > 0) ? competency.upskillingResources[0] : undefined;
  const [resourceForGuide, setResourceForGuide] = useState<IUpskillingResource | undefined>(firstResource);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isResourceGuideOpen, onOpen: onResourceGuideOpen, onClose: onResourceGuideClose } = useDisclosure();

  const getProficiencyLevelStatement = (level: ProficiencyLevel): string => {
    // console.log('getProficiencyLevelStatement', level, level === ProficiencyLevel.Skill, competency)
    if (level === ProficiencyLevel.Knowledge) {
      return competency.knowledgeRubric;
    }
    else if (level === ProficiencyLevel.Skill) {
      return competency.skillRubric;
    }
    else if (level === ProficiencyLevel.Ability) {
      return competency.abilityRubric;
    }
    else {
      return '';
    }
  }

  const renderProficiencyTag = (level: ProficiencyLevel) => {
    const label = level === ProficiencyLevel.Not_Applicable ? 'None' : startCase(ProficiencyLevel[level]);
    return (
      <Box>
        <Tag
          colorScheme={getColorForLevel(level)}
          // variant="outline"
          mb={1}        
        >
          {label}
        </Tag>
      </Box>      
    )
  }

  const renderProficiencyLevel = (label: string, level: ProficiencyLevel, alreadyShownLevel?: ProficiencyLevel) => {
    return (
      <Flex align="left" gap={3}>
        <VStack align="left" width={isJobSeeker ? '7rem' : '9rem'} borderRight='2px' borderColor='gray.200'>
          {text("body.caption", label, 0)}
          {renderProficiencyTag(level)}
        </VStack>
          <Box flex='1'>
            {(!alreadyShownLevel || alreadyShownLevel !== level) && text('body.sm', getProficiencyLevelStatement(level), 0)}
          </Box>
      </Flex>
    );
  }

  const renderJobExpectation = () => {
    if (!showMatch) return null;

    return renderProficiencyLevel('Job Requires', competencyMatch.expectation);    
  };

  const renderJobSeekerRating = (alreadyShownLevel?: ProficiencyLevel) => {
    if (!showRating) return null;

    const label = isJobSeeker ? 'Your Proficiency' : 'Candidate Proficiency';

    return renderProficiencyLevel(label, competencyRating.rating, alreadyShownLevel);
  }

  
  const renderRating = () => {
    if (!showRating) return null;

    const alreadyShownLevel = showMatch ? competencyMatch.expectation : undefined;

    return (
      <Box mt={2} {...borders.card}>
        {renderMatchIndicator()}
        <VStack align="left" justify="start">
          {renderJobExpectation()}
          {renderJobSeekerRating(alreadyShownLevel)}
        </VStack>
      </Box>
    );
  };

  const renderMatchIndicator = () => {
    if (!showMatch) return null;

    return <MatchIndicator matchOutcome={competencyMatch.matchOutcome} isJobSeeker={isJobSeeker} />;
  };

  const renderLabelAndValue = (label: string, value: string) => {
    return (
      <HStack>
        {text('label.regular', `${label}:`, 0)}
        {text('body.regular', value, 0)}
      </HStack>
    )
  }

  const renderUpskillingResourceGuideModal = () => {
    if (!showRating) return null;
    if (!showMatch) return null;
    if (!resourceForGuide) return null;
    
    const resourceProficiencyLevel = ProficiencyLevel[resourceForGuide!.proficiencyLevel as keyof typeof ProficiencyLevel];
    return (
      <Modal isOpen={isResourceGuideOpen} onClose={onResourceGuideClose}>
        <ModalOverlay />
        <ModalContent maxW="65rem">
          {/* <ModalHeader>{text('section.title', `Competency: ${competency.title}`, 0)}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            {text('body.caption', 'Learning Resource Guide')}
            {text('section.title', `${resourceForGuide!.name}`)}
            <HStack spacing={5} mb={5}>            
              {renderLabelAndValue('Provider', resourceForGuide!.provider)}
              {renderLabelAndValue('Instruction Type', resourceForGuide!.instructionType)}
              {renderLabelAndValue('Duration', resourceForGuide!.duration)}
              <HStack>
                {text('label.regular', 'Proficiency Objective', 0)}
                {text('body.regular', 'Aquire', 0)}
                {renderProficiencyTag(resourceProficiencyLevel)}
              </HStack>              
            </HStack>
            <Box mb={3}>
              {text('label.lg', 'Proficiency Level Summary')}
              <VStack>
                {text('body.sm', `This learning resource has been selected to help you gain the necessary upskilling you need to gain the ${resourceForGuide!.proficiencyLevel} level proficiecy. To attain the ${resourceForGuide!.proficiencyLevel} in this competency means you are able to:`, 0)}
                <Text fontSize="regular" fontWeight="semibold" bgColor="gray.200" style={{padding: '4px'}}>{getProficiencyLevelStatement(resourceProficiencyLevel)}</Text>
              </VStack>              
            </Box>
            <Box mb={3}>
              {text('label.lg', 'Overview')}
              {text('body.sm', resourceForGuide!.description)}
            </Box>
            <Box mb={3}>
              {text('label.lg', 'Learning Outcomes')}
              {text('body.sm', resourceForGuide!.learningOutcomes)}
            </Box>
            <Box mb={3}>
              {text('label.lg', 'Enrollment')}
              {text('body.sm', resourceForGuide!.enrollmentInstructions)}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button leftIcon={<BiLinkExternal />} as={Link} href={resourceForGuide!.url} isExternal colorScheme="orange" mr={5}>
              Get Started
            </Button>
            <Button colorScheme="gray" onClick={onResourceGuideClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const renderUpskillingResourceCard = (upskillingResource: IUpskillingResource, isRecommended: boolean) => {
    const openGuideModal = () => {
      setResourceForGuide(upskillingResource);
      onResourceGuideOpen();
    }

    return (
      <Box>
        <Box backgroundColor="white" {...borders.cardRaised}>          
          <Box>
            {text('card.title', upskillingResource.name, 2)}
          </Box>  
          {isRecommended && (
            <Tag size='sm' colorScheme='teal' borderRadius='full' mb={2}>
              <TagLeftIcon boxSize='12px' as={CheckIcon} />
              <TagLabel>Recommended</TagLabel>
            </Tag>
          )}
          {renderLabelAndValue('Provider', upskillingResource.provider)}
          {renderLabelAndValue('Instruction Type', upskillingResource.instructionType)}
          {renderLabelAndValue('Duration', upskillingResource.duration)}
          {renderLabelAndValue('Proficiency Objective', upskillingResource.proficiencyLevel)}
          <Box mt={3}>
            <Button colorScheme="orange" variant="outline" size="sm" onClick={openGuideModal}>View Resource Guide</Button>
          </Box>
        </Box>
      </Box>        
    )
  }

  const renderUpskillingResourcesModal = () => {
    if (!showRating) return null;
    if (!showMatch) return null;

    if (competency.upskillingResources === undefined ||
        competency.upskillingResources.length === 0) {
      return (
        <Box mt='2'>
          {text('body.sm', 'Learning Resources Not Available')}
        </Box>
      )
    }    

    var ordering: any = {}, // map for efficient lookup of sortIndex
    sortOrder = ['Knowledge','Skill','Ability'];
    for (var i=0; i<sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }

    competency.upskillingResources.sort( function(a: IUpskillingResource, b: IUpskillingResource) {
        return (ordering[a.proficiencyLevel] - ordering[b.proficiencyLevel]) || a.name.localeCompare(b.name);
    });

    return (
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent maxW="75rem">
          {/* <ModalHeader>{text('section.title', `Competency: ${competency.title}`, 0)}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            {text("body.caption", "Skill", 0)}
            {text('section.title', competency.title, 3)}
            <Box mb={3}>
              {renderRating()}
            </Box>            
            {text('section.subtitle', `Available Learning Resources: ${competency.upskillingResources.length}`, 3)}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6} alignItems="stretch">
              {competency.upskillingResources.map((upskillingResource: IUpskillingResource) => {                
                const isRecommended = upskillingResource.proficiencyLevel === ProficiencyLevel[competencyMatch.expectation].toString();
                // console.log(upskillingResource.proficiencyLevel, ProficiencyLevel[competencyMatch.expectation].toString(), upskillingResource.proficiencyLevel === ProficiencyLevel[competencyMatch.expectation].toString(), isRecommended);
                return renderUpskillingResourceCard(upskillingResource, isRecommended);
              })}
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              {isJobSeeker ? 'Back to Job Match' : 'Back to Candidate Match'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const renderUpskillingResources = () => {
    if (!showMatch) return null;

    if (competency.upskillingResources === undefined ||
        competency.upskillingResources.length === 0) {
      return (
        <Box mt='2'>
          {text('body.sm', 'Learning Resources Not Available')}
        </Box>
      )
    }

    // isJobSeeker

    return (
      <Box mt="2">
        {renderUpskillingResourceGuideModal()}
        {!inlineUpskillingMode && renderUpskillingResourcesModal()}
        {inlineUpskillingMode ? (
          <Box>
            {text('bold.xl', `Available Learning Resources: ${competency.upskillingResources.length}`, 3)}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              {competency.upskillingResources.map((upskillingResource: IUpskillingResource) => {                
                const isRecommended = upskillingResource.proficiencyLevel === ProficiencyLevel[competencyMatch!.expectation].toString();
                // console.log(upskillingResource.proficiencyLevel, ProficiencyLevel[competencyMatch.expectation].toString(), upskillingResource.proficiencyLevel === ProficiencyLevel[competencyMatch.expectation].toString(), isRecommended);
                return renderUpskillingResourceCard(upskillingResource, isRecommended);
              })}
            </SimpleGrid>
          </Box>
        ) : (
          <Button leftIcon={<MdListAlt />} size="sm" onClick={onOpen}>
            {`View Learning Resources (${competency.upskillingResources.length})`}
          </Button>
        )}        
      </Box>
    )
  }

  const title = get(
    competency,
    "title",
    get(competency, "competency.title", "")
  );

  const description = get(
    competency,
    "description",
    get(competency, "competency.description", "")
  );  

  return (
    <Box backgroundColor="white" {...borders.cardRaised} className="m-sixth-step">
      {text("body.caption", "Skill", 0)}
      {text("card.title", title)}
      {!hideDescription && text("body.regular", description)}
      {renderRating()}
      {renderUpskillingResources()}
    </Box>
  );
};

export default Competency;
