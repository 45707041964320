import { createContext, useContext, useState } from "react";
import { api } from "../api";
import { IEvidence } from "../types/evidence";
import { UserContextValue, useUser } from "./UserStore";

export type EvidenceContextValue = {
  evidences: IEvidence[];
  deleteEvidence: (evidenceId: string) => void;
  addEvidence: (formValues: any, jobRatingId: string) => void;
  isFetchingEvidences: boolean;
  fetchEvidences: (userId: string) => void;
  updateEvidence: (evidence: IEvidence, jobRatingId: string) => void;
};

const EvidenceContext =
  createContext<EvidenceContextValue | undefined>(undefined);

export const useEvidence = () => useContext(EvidenceContext);

const EvidenceProvider = ({ children }: any) => {
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isFetchingEvidences, setIsFetchingEvidences] =
    useState<boolean>(false);
  const { currentUser } = useUser() as UserContextValue;

  const fetchEvidences = async (userId: string) => {
    setIsFetchingEvidences(true);
    try {
      const evidencesResponse = await api.get(`/items/evidences?fields=*,fileUpload.*&filter[userId][_eq]=${userId}`);
      setEvidences(evidencesResponse.data?.data);
      setIsFetchingEvidences(false);
    } catch (error) {
      setEvidences([]);
      setIsFetchingEvidences(false);
      console.error('fetchEvidences', error);
    }
  };

  const deleteEvidence = async (evidenceId: string) => {
    try {
      setIsFetchingEvidences(true);
      await api.delete(`/items/evidences/${evidenceId}`);
      setIsFetchingEvidences(false);
    } catch (error) {
      setIsFetchingEvidences(false);
      console.error('deleteEvidence', error);
    }
  };

  const addEvidence = async (formValues: any, jobRatingId: string) => {
    try {
      formValues.userId = currentUser?.id;
      formValues.jobRatingId = jobRatingId;
      await api.post(`/items/evidences/`, formValues);
      setIsFetchingEvidences(false);
    } catch (error) {
      setIsFetchingEvidences(false);
      console.error('addEvidence', error);
    }
  };

  const updateEvidence = async (formValues: any, jobRatingId: string) => {
    try {
      formValues.userId = currentUser?.id;
      formValues.jobRatingId = jobRatingId;
      await api.patch(`/items/evidences/${formValues.id}`, formValues);
      setIsFetchingEvidences(false);
    } catch (error) {
      setIsFetchingEvidences(false);
      console.error('updateEvidence', error);
    }
  };

  return (
    <EvidenceContext.Provider
      value={{
        evidences,
        deleteEvidence,
        addEvidence,
        isFetchingEvidences,
        fetchEvidences,
        updateEvidence,
      }}
    >
      {children}
    </EvidenceContext.Provider>
  );
};

export default EvidenceProvider;
