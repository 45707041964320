import { createContext, useContext, useState } from "react";

import { IJobMatch } from "../types/match";
import { IFilter, ISearchValue } from "../types/ui/search";
import { api } from "../api";

export const formatFilterValues = (searchValue: ISearchValue | undefined) => {
  const searchValueArray = searchValue?.filterValues?.map((fv) => {
    return { [fv.slug]: fv.selectedValue };
  });

  const obj: { [k: string]: any } = {};
  searchValueArray?.forEach((item) => {
    const propName = Object.keys(item)[0];
    let value = Object.values(item)[0];

    if (propName === "location") {
      const locationArray = Array.from(Object.values(item)[0]);
      const convertedLocationArray: string[] = [];

      locationArray.forEach((location) => {
        convertedLocationArray.push(location.replace("-", ", "));
      });
      value = convertedLocationArray;
    }
    obj[propName] = value;
  });

  if (!obj.page) {
    obj.page = "1";
  }

  obj.searchTerm = searchValue?.searchTerm || "";

  return obj;
};

export type MatchContextValue = {
  summaryMatches: any;
  summaryMatchesTotal: number;
  isFetchingSummaryMatches: boolean;
  matchFilter: IFilter[] | undefined;
  matches: IJobMatch[];
  matchesTotal: number;
  isFetchingMatches: boolean;
  fetchMatchFilter: (userId: string) => void;
  fetchMatches: (searchFilterValue?: ISearchValue, pageNumber?: number) => void;
  fetchSummaryMatches: () => void;
  page: number;
};

const MatchContext = createContext<MatchContextValue | undefined>(undefined);

export const useMatch = () => useContext(MatchContext);

const MatchProvider = ({ children }: any) => {
  const [page, setPage] = useState<number>(1);
  // const [selectedUserId, setSelectedUserId] = useState<string>("");
  // const [selectedRecruiterId, setSelectedRecruiterId] = useState<string>("");
  // const [selectedSearchValue, setSelectedValueSearch] = useState<ISearchValue>();

  const [summaryMatches, setSummaryMatches] = useState<any>();
  const [summaryMatchesTotal, setSummaryMatchesTotal] = useState<number>(0);
  const [isFetchingSummaryMatches, setIsFetchingSummaryMatches] = useState<boolean>(false);

  const [matchFilter, setMatchFilter] = useState<IFilter[]>();  
  const [matches, setMatches] = useState<IJobMatch[]>([]);
  const [matchesTotal, setMatchesTotal] = useState<number>(0);
  const [isFetchingMatches, setIsFetchingMatches] = useState<boolean>(false);

  const fetchMatchFilter = async (userId: string) => {    
    if (matchFilter === undefined) {
      const res = await api.get('/custom/matches/filter');
      const matchFilterRes = res.data;
      setMatchFilter(matchFilterRes);
    }    
  }

  const fetchMatches = async (searchFilterValue: ISearchValue = { searchTerm: '', filterValues: []}, pageNumber: number = 1) => {
    setIsFetchingMatches(true);
    let formattedSearchFilterValue = formatFilterValues(searchFilterValue);
    const res = await api.post(`/custom/matches/search/?page=${pageNumber}`, formattedSearchFilterValue);
    const { result, total, nextPage } = res.data;
    setMatches(result);
    setMatchesTotal(total);
    setIsFetchingMatches(false);
    setPage(pageNumber);
  }

  const fetchSummaryMatches = async() => {
    setIsFetchingSummaryMatches(true);
    const res = await api.get(`/custom/matches/summary`, {});
    const { total } = res.data;
    setSummaryMatches(res.data);
    setSummaryMatchesTotal(total);
    setIsFetchingSummaryMatches(false);
  }

  return (
    <MatchContext.Provider
      value={{
        // refetch,

        matchFilter,
        matches,
        matchesTotal,
        summaryMatches,
        summaryMatchesTotal,
        isFetchingMatches,
        isFetchingSummaryMatches,
        fetchMatchFilter,
        fetchMatches,
        fetchSummaryMatches,
        page,
      }}
    >
      {children}
    </MatchContext.Provider>
  );
};

export default MatchProvider;
