import { DownloadIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Accordion,
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Image,
} from "@chakra-ui/react";
import { startCase } from "lodash";
import { ReactNode, useState } from "react";
import { IJobRating } from "../../../types/rating";
import { IUser } from "../../../types/user";
import { getFullName, getUserJobRoles } from "../../../utils/users";
import { locationTag, salaryTag, workTypeTag } from "../../util/tags";
import { text } from "../../util/texts";
import Category from "../../shared/Category";
import { HiPlusCircle, HiPencil } from "react-icons/hi";
import { } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";
import { getCategoryRating } from "../../../utils/categories";
import ShowEvidences from "../../common/ShowEvidences";
import { IEvidence } from "../../../types/evidence";
import { ApiInstance } from "../../../api/ApiInstance";

type responsiveStackProp = {
  children?: ReactNode;
};
interface UserJobProfileProps {
  user: IUser;
  jobRatings: IJobRating[];
  selectedJobRating?: IJobRating;
  onSelectJobRating: (jobRatingId: string) => void;
  onEditJobRating: (jobRatingId: string) => void;
  evidences: IEvidence[];
  onEvidenceDelete: (evidienceId: string) => void;
  onEvidenceAddOrUpdate: (formVaules: any, file?: File) => void;
}

let dollarUSLocale = Intl.NumberFormat("en-US");

const UserJobProfile = ({
  user,
  jobRatings,
  selectedJobRating,
  onSelectJobRating,
  onEditJobRating,
  evidences,
  onEvidenceDelete,
  onEvidenceAddOrUpdate,
}: UserJobProfileProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const screenSize = useBreakpointValue({
    base: "small",
    md: "medium",
    lg: "large",
  });

  var isResume = user.resume !== '' && user.resume !== null;

  // const jobRoles = getUserJobRoles(jobRatings);
  // const [jobRoleSlug, setJobRoleSlug] = useState(
  //   jobRoles[0]?.slug || undefined
  // );
  // const jobRating = jobRatings.find((jr) => jr.job?.slug === jobRoleSlug);

  const ResponsiveStack = (props: responsiveStackProp) => {
    const { children } = props;
    if (screenSize === "small" || screenSize === "medium") {
      return (
        <VStack align="start" gap={3}>
          {children}
        </VStack>
      );
    } else {
      return <HStack gap={3}>{children}</HStack>;
    }
  };

  const renderJobRatingSelector = () => {
    if (!selectedJobRating) {
      return text('body.caption', 'No Jobs in Your Profile');
    }

    return (        
      <Box mb={7}>
        {text("label.regular", "Select Job Title")}
        <RadioGroup onChange={onSelectJobRating} value={selectedJobRating.id}>
          <Stack>
            {jobRatings.map((jr, idx) => (
              <Radio key={idx} size="lg" colorScheme="green" value={jr.id}>
                {jr.job.title}
                {selectedJobRating.id === jr.id && (
                  <Button
                    ml={2}
                    variant="link"
                    leftIcon={<HiPencil />}
                    style={{ color: 'blue' }}
                    onClick={() => onEditJobRating(jr.id)}
                  >
                    Edit
                  </Button>
                )}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Box>
    )
  }

  const renderEvidences = () => {
    if (!selectedJobRating) return null;

    const jobRatingEvidences = evidences.filter(e => e.jobRatingId === selectedJobRating.id);

    return (
      <Box mb={"4"}>
        <ShowEvidences
          evidences={jobRatingEvidences}
          onEvidenceDelete={onEvidenceDelete}
          onEvidenceAddOrUpdate={onEvidenceAddOrUpdate}
        />
      </Box>
    )
  }
  const renderCompetencies = () => {
    if (!selectedJobRating) return null;

    return (      
      <>
        {text("section.title", "Skills", 5)}
        <Accordion pb={20} allowMultiple allowToggle>
          {selectedJobRating.job.categories.map((category, idx) => (
            <Category
              key={idx}
              category={category}
              categoryRating={getCategoryRating(category, selectedJobRating)}
            />
          ))}
        </Accordion>
      </>
    )
  }

    const renderResumeFiles = () => {
      if (!user.resume) {
        return (
          null
        )
      }

      return (
        <Link href={`${ApiInstance.assetsUrl}/${user?.resume.id}?access_token=${localStorage.getItem("authorizationToken")}&download`} target="_blank" download={user.resume.filename_download} cursor="pointer" style={{ color: 'blue' }} fontSize={{ base: 'md' }} color='blue.600'>
          <DownloadIcon /> Download Resume
        </Link>
      )
    }

  return (
    <Box>
      <Box mb={5}>
        {text("page.title", getFullName(user))}
        {/* <HStack mb={3}> */}
        {text("page.subtitle", user.headline || '')}
        {text("page.instruction", user.location)}
        {/* {pageSubHeading('|', 0)} */}
        {/* </HStack>       */}
        <HStack mb={4}>
          {/* {salaryTag(dollarUSLocale.format(user?.salary), {
            size: "lg",
          })} */}
          {workTypeTag(startCase(user?.workType), { size: "lg" })}
          {locationTag(startCase(user?.locationType), {
            size: "lg",
          })}
        </HStack>
        <ResponsiveStack>
          <Button
            variant="link"
            leftIcon={<HiPlusCircle />}
            style={{ color: 'green' }}
            as={RouterLink}
            to="/jobProfile/selectJob"
          >
            Assert New Job Skills
          </Button>
          <Button
            variant="link"
            leftIcon={<HiPencil />}
            style={{ color: 'green' }}
            as={RouterLink}
            to="/profile"
          >
            Edit My Profile
          </Button>
          <Button
            variant="link"
            leftIcon={<ExternalLinkIcon />}
            style={{ color: 'blue' }}
            onClick={onOpen}
          >
            Share Profile
          </Button>
          {renderResumeFiles()}
       
        </ResponsiveStack>
      </Box>
      <Divider mb={5} />
      <Box mb={7}>
        {text("section.title", "Profile Summary")}
        {text("section.instruction", user.summary)}
      </Box>
      
      {text("section.title", "My Job Skillsets", 5)}
      {renderJobRatingSelector()}

      {renderEvidences()}
      {renderCompetencies()}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Your Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {text(
              "body.lg",
              "Share your profile with others or include on your resume or LinkedIn.",
              3
            )}
            {text(
              "body.regular",
              "Sharable Link: https://app.kibo.com/profile/12397135"
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                const url = window.location.href;
                navigator.clipboard.writeText(url);
              }}
            >
              Copy Sharable Link
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserJobProfile;
