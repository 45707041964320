import { Box } from "@chakra-ui/react";
import React from "react";
import { RECRUITER_JOB_MATCH_STATUSES } from "../types/match";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { get, startCase } from "lodash";
import { text } from "../components/util/texts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const statusMatches = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
      display: false
    },
  },
};

export const jobSummaryMatches = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
      display: false
    },
  },
};

export const percentageMatches = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
      display: false
    },
  },
};

interface RecruiterReportsPageProps {
  summary: any | undefined;
}

const RecruiterReportsPage = ({ summary }: RecruiterReportsPageProps) => {

  const graphColors = ['#003049', '#d62828', '#f77f00', '#fcbf49', '#eae2b7'];

  const labels = RECRUITER_JOB_MATCH_STATUSES;
  const labelsDisplay = RECRUITER_JOB_MATCH_STATUSES.map(status => startCase(status))

  const statusData = {
    labels: labelsDisplay,
    datasets: [
      {
        label: 'Status',
        data: labels.map((label: string) => get(summary, `statusSummary.${label}.matchesCount`, 0)),
        backgroundColor: graphColors,
      }
    ],
  };

  const jobSumLabels = Object.keys(summary.jobSummary);
  const jobSumLabelsDisplay = jobSumLabels;

  const jobSummaryData = {
    labels: jobSumLabelsDisplay,
    datasets: [
      {
        label: 'Job',
        data: jobSumLabels?.map((label: any) => get(summary, `jobSummary.${label}.matchesCount`, 0)),
        backgroundColor: graphColors
      }
    ],
  };

  const enum JobMatchPercentage {
    Excellent = 'Excellent',
    Good = 'Good',
    Fair = 'Fair',
    Poor = 'Poor',
  }

  const JOB_SEEKER_JOB_MATCH_PERCENTAGE: JobMatchPercentage[] = [
    JobMatchPercentage.Excellent,
    JobMatchPercentage.Good,
    JobMatchPercentage.Fair,
    JobMatchPercentage.Poor,
  ]

  const percentageLabels = JOB_SEEKER_JOB_MATCH_PERCENTAGE;
  const percentageLabelsDisplay = JOB_SEEKER_JOB_MATCH_PERCENTAGE.map(percentage => startCase(percentage));

  const matchPercentageSummaryData = {
    labels: percentageLabelsDisplay,
    datasets: [
      {
        label: 'Percentages',
        data: percentageLabels.map((label: string) => get(summary, `matchPercentageSummary.${label}.matchesCount`, 0)),
        backgroundColor: ['#386641', '#003049', '#f77f00', '#d62828'],
      }
    ],
  };
  return (
    <Box mx={{ base: '8px', md: 20 }} my={{ base: 2, md: 15 }}>
      {text('page.title', 'Recruiter Reports', 4)}
      <Box height={{ base: '500px', md: '750px' }} width={{ base: '100%', md: '50%' }} mb={{ base: '12', md: '100px'}}>
        {text('section.title', 'Matches by Status', 2)}
        <Bar options={statusMatches} data={statusData} />
      </Box>
      <Box height={{ base: '500px', md: '750px' }} width={{ base: '100%', md: '50%' }} mb={{ base: '12', md: '100px'}}>
        {text('section.title', 'Matches by Job', 2)}
        <Bar options={jobSummaryMatches} data={jobSummaryData} />
      </Box>      
      <Box height={{ base: '500px', md: '750px' }} width={{ base: '100%', md: '50%' }} mb={{ md: '100px'}}>
        {text('section.title', 'Matches by Percentages', 2)}
        <Bar options={percentageMatches} data={matchPercentageSummaryData} />
      </Box>
    </Box>
  );
};

export default RecruiterReportsPage;
