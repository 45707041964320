import React, { createContext, useContext, useState } from "react";

import { api } from "../api";
import { CompetencyRatingMap } from "../components/shared/CategoryRater";
import { IJobRating } from "../types/rating";

export type RatingContextValue = {
  rating: IJobRating | undefined;
  ratings: IJobRating[] | undefined;
  isFetchingRatings: boolean;
  fetchUserJobRatings: () => void;
  fetchUserJobRating: (jobRatingId: string) => void;
  createUserJobRating: (
    jobId: string,
    competencyRatings: CompetencyRatingMap
  ) => Promise<void>;
  updateUserJobRating: (
    jobRatingId: string,
    competencyRatings: CompetencyRatingMap
  ) => Promise<void>;
};

const RatingContext = createContext<RatingContextValue | undefined>(undefined);

export const useRating = () => useContext(RatingContext);

// eslint-disable-next-line react/function-component-definition
const RatingProvider = ({ children }: any) => {
  const [isFetchingRatings, setIsFetchingRatings] = useState(true);
  const [rating, setRating] = useState();
  const [ratings, setRatings] = useState();

  /* eslint-disable consistent-return */
  const fetchUserJobRatings = async () => {
    try {
      setIsFetchingRatings(true);

      const res = await api.get(`/custom/ratings`);
      setRatings(res.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
      console.error('fetchUserJobRatings', error);
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable consistent-return */
  const fetchUserJobRating = async (jobRatingId: string) => {
    try {
      setIsFetchingRatings(true);

      const res = await api.get(`/custom/ratings/${jobRatingId}`);
      setRating(res.data?.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
      console.error('fetchUserJobRating', error);
    }
  };

  const createUserJobRating = async (
    jobId: string,
    competencyRatings: CompetencyRatingMap
  ): Promise<void> => {
    try {
      setIsFetchingRatings(true);

      const createdRating = (await api.post("/custom/ratings", { jobId, competencyRatings })).data;
      setRating(createdRating);
      const res = await api.get(`/custom/ratings`);      
      setRatings(res.data?.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
      console.error('createUserJobRating', error);
    }
  };

  const updateUserJobRating = async (
    jobId: string,
    competencyRatings: CompetencyRatingMap
  ): Promise<void> => {
    try {
      setIsFetchingRatings(true);

      await api.patch(`/custom/ratings/${jobId}`, { jobId, competencyRatings });
      const res = await api.get(`/custom/ratings`);
      setRatings(res.data);
      setIsFetchingRatings(false);
    } catch (error) {
      setIsFetchingRatings(false);
      console.error('updateUserJobRating', error);
    }
  };
  /* eslint-enable consistent-return */

  return (
    <RatingContext.Provider
      value={{
        rating,
        ratings,
        isFetchingRatings,
        fetchUserJobRatings,
        fetchUserJobRating,
        createUserJobRating,
        updateUserJobRating,
      }}
    >
      {children}
    </RatingContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default RatingProvider;
