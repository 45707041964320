import { Avatar, Box, Button, HStack } from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../types/match";
import { ReactNode } from "react";
import { locationTag, salaryTag, workTypeTag } from "./tags";
import { text } from "./texts";
import { getFullName, getUserJobRoles, getAvatarUrl } from "../../utils/users";

// == Match Header Rendering

let dollarUSLocale = Intl.NumberFormat("en-US");

export const renderMatchHeaderForJobSeeker = (match: IJobMatch):ReactNode => {
  const jobPost = match.jobPost;  

  return (
    <Box>
      <Box gap={3} mb={1}>
        {text("page.title", jobPost.companyJobTitle, 1)}
        {text("page.subtitle", match.company.name, 1)}
        {text("page.instruction", match.jobPost.location)}        
      </Box>
      <HStack mb={3}>
        {salaryTag(
          dollarUSLocale.format(Number(`${jobPost.salary || 0} `))
        )}
        {workTypeTag(`${jobPost.workType}`)}

        {locationTag(`${jobPost.locationType}`)}        
      </HStack>
    </Box>
  );
}

export const renderMatchHeaderForRecruiter = (match: IJobMatch ):ReactNode => {
  const jobSeeker = match.user;
  const fullName = getFullName(jobSeeker);

  return (
    <Box>
      <HStack gap={3} mb={1}>
        {text("page.title", fullName, 0)}
        <Avatar
          name={fullName}
          src={getAvatarUrl(jobSeeker)}
        />
        
      </HStack>
      {jobSeeker.headline != null && text("page.subtitle", jobSeeker.headline)}
      {text(
        "page.subtitle",
        getUserJobRoles(match.user.ratings)
          .map((j) => j.title)
          .join(", "),
        1
      )}
      {text("page.instruction", match.user.location)}
      
      <HStack mb={3}>
        {/* {salaryTag(
          dollarUSLocale.format(Number(`${jobSeeker.salary || 0} `))
        )} */}
        {workTypeTag(`${jobSeeker.workType}`)}

        {locationTag(`${jobSeeker.locationType}`)}
      </HStack>
    </Box>
  );
}

// == Match Action Rendering

const JOB_SEEKER_STATUS_TO_ACTION_MAPPING = [                        
  {
    toStatus: JobMatchStatus.CandidateApplied,
    fromStatus: [JobMatchStatus.NeedsReview, JobMatchStatus.Viewed, JobMatchStatus.Saved, JobMatchStatus.Passed],        
    actionDisplay: 'Apply',
    colorScheme: 'green',
    variant: 'solid',
  },
  {
    toStatus: JobMatchStatus.Saved,
    fromStatus: [JobMatchStatus.NeedsReview, JobMatchStatus.Viewed, JobMatchStatus.Passed],
    actionDisplay: 'Save',
    colorScheme: 'blue',
    variant: 'solid',
  },
  {
    toStatus: JobMatchStatus.Passed,
    fromStatus: [JobMatchStatus.NeedsReview, JobMatchStatus.Viewed, JobMatchStatus.Saved],
    actionDisplay: 'Pass',
    colorScheme: 'orange',
    variant: 'outline',
  },
  {
    toStatus: JobMatchStatus.Viewed,
    fromStatus: [JobMatchStatus.NeedsReview],
    actionDisplay: 'Mark Viewed',
    colorScheme: 'blue',
    variant: 'ghost',
  },
];

const RECRUITER_STATUS_TO_ACTION_MAPPING = [                        
  {
    toStatus: JobMatchStatus.RecruiterInvited,
    fromStatus: [JobMatchStatus.NeedsReview, JobMatchStatus.Viewed, JobMatchStatus.Saved, JobMatchStatus.Passed],        
    actionDisplay: 'Invite to Apply',
    excludeOnCandidateApply: true,
    colorScheme: 'green',
    variant: 'solid',
  },
  {
    toStatus: JobMatchStatus.Saved,
    fromStatus: [JobMatchStatus.NeedsReview, JobMatchStatus.Viewed, JobMatchStatus.Passed],
    actionDisplay: 'Save',
    excludeOnCandidateApply: false,
    colorScheme: 'blue',
    variant: 'solid',
  },
  {
    toStatus: JobMatchStatus.Passed,
    fromStatus: [JobMatchStatus.NeedsReview, JobMatchStatus.Viewed, JobMatchStatus.Saved, JobMatchStatus.RecruiterInvited],
    actionDisplay: 'Pass',
    excludeOnCandidateApply: false,
    colorScheme: 'orange',
    variant: 'outline',
  },
  {
    toStatus: JobMatchStatus.Viewed,
    fromStatus: [JobMatchStatus.NeedsReview],
    actionDisplay: 'Mark Viewed',
    excludeOnCandidateApply: false,
    colorScheme: 'blue',
    variant: 'ghost',
  },
];

export const renderMatchActionsForJobSeeker = (
  jobSeekerStatus: JobMatchStatus | undefined,
  onMatchAction: (toStatus: JobMatchStatus) => void,
): ReactNode => {
  return (
    <HStack>
      {JOB_SEEKER_STATUS_TO_ACTION_MAPPING.map((actionMap, idx) => {
        if (!jobSeekerStatus || actionMap.fromStatus.includes(jobSeekerStatus)) {
          return (
            <Button
              key={idx}
              colorScheme={actionMap.colorScheme}
              onClick={() => onMatchAction(actionMap.toStatus)}
              variant={actionMap.variant}
            >
              {actionMap.actionDisplay}
            </Button>
          )
        }

        return null;
      })}
    </HStack>
  )
}

export const renderMatchActionsForRecruiter = (
  recruiterStatus: JobMatchStatus | undefined,
  jobSeekerStatus: JobMatchStatus | undefined,
  onMatchAction: (toStatus: JobMatchStatus) => void,
): ReactNode => {
  return (
    <HStack>
      {RECRUITER_STATUS_TO_ACTION_MAPPING.map((actionMap, idx) => {
        if (actionMap.excludeOnCandidateApply && jobSeekerStatus === JobMatchStatus.CandidateApplied) {
          return null;
        }

        if (!recruiterStatus || actionMap.fromStatus.includes(recruiterStatus)) {
          return (
            <Button
              key={idx}
              colorScheme={actionMap.colorScheme}
              onClick={() => onMatchAction(actionMap.toStatus)}
              variant={actionMap.variant}
            >
              {actionMap.actionDisplay}
            </Button>
          )
        }

        return null;
      })}
    </HStack>
  )
};