import { Box, Progress, Text } from "@chakra-ui/react";

interface MatchPercentageProps {
  percentage: number;
  color: string;
  size?: "sm" | "md";
  label?: string;
}

const MatchPercentage = ({
  percentage,
  color,
  size = "md",
  label = "Match",
}: MatchPercentageProps) => {
  const px = size === "sm" ? [1, 2] : [3, 4];
  const py = size === "sm" ? [0.5, 1] : [2, 3];
  const fontSize =
    size === "sm" ? { base: "sm", md: "md" } : { base: "lg", md: "2xl" };

  return (
    <Box
      borderRadius={6}
      px={px}
      py={py}
      display="inline-block"
      // backgroundColor={`${color}.400`} color="white"
      textAlign="center"
    >
      <Text fontSize={fontSize} fontWeight="bold">
        {percentage}%
      </Text>
      {size !== "sm" && (
        <Text fontSize={{ base: "xs", md: "sm" }}>{label}</Text>
      )}
      <Progress
        colorScheme={color}
        size="sm"
        value={percentage}
        aria-label="job rating"
        aria-labelledby="job rating"
      />
    </Box>
  );
};

export default MatchPercentage;
