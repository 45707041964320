import { createContext, useContext, useState } from "react";
import { api } from "../api";
import { UserContextValue, useUser } from "./UserStore";
import { IJobPostStatus } from "../types/jobPostStatus";

export type JobPostStatusContextValue = {
  jobPostStatuses: IJobPostStatus[] | undefined;
  fetchJobPostStatus: () => void;
  saveJobStatus: (jobId: string, status: string) => void;
  isFetching: boolean;
};

const JobPostStatusContext = createContext<JobPostStatusContextValue | undefined>(undefined);

export const useJobPostStatus = () => useContext(JobPostStatusContext);

const JobPostStatusProvider = ({ children }: any) => {
  const [jobPostStatuses, setJobPostStatus] =
    useState<IJobPostStatus[] | undefined>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { currentUser } = useUser() as UserContextValue;

  const fetchJobPostStatus = async () => {
    try {
      setIsFetching(true);
      const result = await api.get(`/items/jobPostApplication`);
      setJobPostStatus(result.data.data);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const saveJobStatus = async (jobId: string, status: string) => {

    try {
      setIsFetching(true);
      await api.post(`/items/jobPostApplication`, {
        userId: currentUser?.id,
        jobId,
        status,
        createdAt: new Date().toISOString(),
      });
  
      fetchJobPostStatus();
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('saveJobStatus', err);
    }
  };

  return (
    <JobPostStatusContext.Provider
      value={{
        jobPostStatuses,
        saveJobStatus,
        fetchJobPostStatus,
        isFetching,
      }}
    >
      {children}
    </JobPostStatusContext.Provider>
  );
};

export default JobPostStatusProvider;
