/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export enum ApiMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const getBody = (method: ApiMethod, body?: object) => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && method === ApiMethod.DELETE) {
    return { data: body };
  }

  return body;
};

export class ApiInstance {
  api: AxiosInstance;
  static assetsUrl: string;

  constructor(baseUrl: string) {
    this.api = axios.create();
    this.api.defaults.baseURL = baseUrl;
    ApiInstance.assetsUrl = `${baseUrl}/assets`;
  }

  async _request(method: ApiMethod, path: string, body?: object, header?: object): Promise<any> {
    try {
      //Exclude token in request for the following paths
      const excludedPath = ['/auth/login', '/auth/refresh']
      const token = localStorage.getItem("authorizationToken");
      let authHeaders = excludedPath.includes(path) ? header : token ? {
        Authorization: `Bearer ${token}`,
      } : {}
      const config: AxiosRequestConfig = {
        url: path,
        method,
        data: getBody(method, body),
        headers: { ...authHeaders, ...header },
        withCredentials: true, // include existing cookies as part of request header
      };

      const response = await this.api.request(config);      
      const { status, data, headers } = response;
      // console.log('raw response', data);            

      return { status, data, headers };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          const refresh_token = localStorage.getItem("refreshToken")
          this.refreshToken(refresh_token, "json")
        }
        if (error.response?.status === 500) {
          console.log(error)
        }
      }
    }
  }

  async refreshToken(refresh_token: string | null, mode: string): Promise<any> {
    try {
      const res = await this.api.post(`/auth/refresh`, { refresh_token, mode });
      //If response is 200 (Successfull) then, set token and fetch current loggedin user 
      localStorage.setItem("authorizationToken", res.data.data.access_token);
      localStorage.setItem("refreshToken", res.data.data.refresh_token);
    } catch (error: any) {
      localStorage.removeItem("authorizationToken");
      localStorage.removeItem("refreshToken");
    }
  }

  async get(path: string, header?: object): Promise<any> {
    return this._request(ApiMethod.GET, path, {}, header);
  }

  async post(path: string, body: object, header?: object): Promise<any> {
    return this._request(ApiMethod.POST, path, body, header);
  }

  async put(path: string, body: object): Promise<any> {
    return this._request(ApiMethod.PUT, path, body);
  }

  async patch(path: string, body: object): Promise<any> {
    return this._request(ApiMethod.PATCH, path, body);
  }

  async delete(path: string): Promise<any> {
    return this._request(ApiMethod.DELETE, path);
  }

  setUrl(path: string) {
    this.api.defaults.baseURL = path;
  }

  getCurrentUrl(): string {
    return this.api.defaults.baseURL || '';
  }
}
