import React, { useState } from "react";
import { 
  Box,
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalHeader,
  ButtonGroup,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import { groupBy, includes, isEmpty } from "lodash";
import { IJob, ICategory, ICompetency } from "../../types/job";
import { text } from "../util/texts";
import JobItem from "./JobItem";
import { UserContextValue, useUser } from "../../stores/UserStore";

interface JobSelectorProps {
  jobs: IJob[];
  existingJobIds: string[];
  onSelect: (jobId: string) => void;
}

const JobSelector = ({ jobs, onSelect, existingJobIds }: JobSelectorProps) => {

  const [selectedJob, setSelectedJob] = useState<IJob | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currentUser } = useUser() as UserContextValue;
  const availableJobs = jobs.filter((j) => {
    if(currentUser?.userRole === 'user') {
      return !includes(existingJobIds, j.id)
    }
    return true;
  });
  const jobsByFamily = groupBy(availableJobs, "jobFamily");
  
  const renderSkillsSummary = () => {
    if (!selectedJob) {
      return null;
    }

    return (
      <SimpleGrid columns={{ base: 1, md: 3 }} spacingX={3} spacingY={5} mb={8}>
      {selectedJob.categories.map((category: ICategory) => {            
          return (
            <Box>
              {text('bold.lg', category.title)}
              <UnorderedList>
                  {category.competencies.map((competency: ICompetency) => (
                    <ListItem>{competency.title}</ListItem>
                  ))}
              </UnorderedList>
            </Box>
          )
      })}
      </SimpleGrid>
    )
  }

  const onTakeQuestionnaire = () => {
    if (selectedJob) {
      onSelect(selectedJob.id);
      onClose();
    }    
  }
  const renderModal = () => {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="65rem">
          <ModalHeader>
            {text('body.caption', 'Job Title', 0)}
            {text('section.title', selectedJob?.title || '')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {!isEmpty(selectedJob?.description) && text('label.lg', 'Job Overview / Description')}
              {!isEmpty(selectedJob?.description) && text('body.regular', selectedJob?.description || '', 6)}
              
              {text('label.lg', 'Requires Job Skills')}
              {text('body.regular', `Aspire Ability has worked with industry experts to identify the required Job Skills to be a successful '${selectedJob?.title}'. The following Job Skills are grouped into categories based on area of focus. Take a look at what Job Skills are required for this role, and see if you have the skill set to be a '${selectedJob?.title}'. If you feel like you might be a good fit, take the questionnaire to see exactly where your current skills stand relative to this role.`, 6)}

              {renderSkillsSummary()}

              {text('label.lg', 'About The Proficiency Questionnaire')}
              {text('body.regular', 'The following questionnaire takes the skills you just reviewed one level further. For each skill, there are three levels of proficiency. Those levels are Knowledge, Skill, and Ability. Someone with Knowledge is able to discuss the subject matter, and perhaps even lend constructive input. Someone with Skill is a day-to-day practitioner of the subject, and someone with Ability is a mentor and innovator in the space.', 2)}
              {text('body.regular', 'As you go through the questionnaire, you will select the proficiency statements that best represent your current capabilities. It is very important that you be honest in the proficiency you assert for each skill, so that we can match you with the jobs you are most qualified for. The purpose of this questionnaire is to figure out what jobs are a fit for you, not to present yourself as someone who is an expert in every subject matter.', 2)}
              {text('body.regular', 'When you are ready, proceed to the questionnaire.')}
            </Box>            
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose}>
                Return to Jobs
              </Button>
              <Button onClick={onTakeQuestionnaire} colorScheme="orange">
                Take the Questionnaire
              </Button>
            </ButtonGroup>            
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Box>
      {Object.keys(jobsByFamily).map((jobFamily, idx) => {
        const jobs = jobsByFamily[jobFamily];
        return (
          <Box key={idx} mb={10}>
            {text("section.title", jobFamily)}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3}>
              {jobs.map((job, idx) => {
                const onClick = () => {
                  setSelectedJob(job);
                  onOpen();
                };

                return (
                  <Box key={idx} minH="100%">
                    <JobItem job={job} onClick={onClick} />
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
        );
      })}
      {renderModal()}
    </Box>
  );
};

export default JobSelector;
