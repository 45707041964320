import { Box, Button, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { IJob } from "../../../types/job";
import {  IJobRating } from "../../../types/rating";
import { text } from "../../util/texts";
import JobRater from "../../shared/JobRater";
import { IJobPost } from "../../../types/job-post";
import { useEffect, useState } from "react";
import { renderInput, renderRadios } from "../../util/forms";
import { UserRole } from "../../../types/user";
import { CompetencyRatingMap } from "../../shared/CategoryRater";
import { UserContextValue, useUser } from "../../../stores/UserStore";
import { api } from "../../../api";

interface RecruiterJobProfileProps {
  job: IJob | undefined;
  jobPost?: IJobPost;
  jobRating?: IJobRating;
  onSubmit: (jobPostDetails: any, updatedValues: CompetencyRatingMap) => void;
}

const required = { isRequired: true };
//const notRequired = { isRequired: false };

const JobPost = ({ job, jobPost, onSubmit }: RecruiterJobProfileProps) => {
  const [detailsValues, setDetailsValues] = useState<any>(jobPost || {});
  const [step, setStep] = useState<number>(1);
  const { currentUser } = useUser() as UserContextValue;
  const [jobCompetencies, setJobCompetencies] = useState();

  useEffect(() => {
    if (jobPost) {
      setDetailsValues(jobPost);
      const fetchUserJobPostCompetency = async () => {
        try {
          const res = await api.get(`/items/job_post_competencies?[filter][jobPostId][_eq]=${jobPost.id}`);
          setJobCompetencies(res.data.data);
        } catch (error) {
          console.log('error fetching job competency', error);
        }
      };

      fetchUserJobPostCompetency().catch((error) => { console.log('error fetching job competency', error); });
    }
  }, [jobPost, job]);

  const renderDetailsStep = () => {
    return (
      <>
        {text("section.title", "Job Details")}
        <Formik
          initialValues={{
            companyJobTitle: detailsValues?.companyJobTitle || "",
            companyJobDescription: detailsValues?.companyJobDescription || "",
            applyLink: detailsValues?.applyLink || '',
            location: detailsValues?.location || "",
            locationType: detailsValues?.locationType || "",
            workType: detailsValues?.workType || "",
            salary: detailsValues?.salary || "",
            strongMatchThreshold: detailsValues?.strongMatchThreshold || 85,
            weakMatchThreshold: detailsValues?.weakMatchThreshold || 60,
            postedDate: detailsValues?.postedDate || new Date().toISOString(),
            recruiterId: currentUser?.id,
            companyId: (currentUser as any)?.company.id,
            categories: detailsValues?.categories || [],
            createdAt: detailsValues?.createdAt || new Date().toISOString(),
          }}
          onSubmit={(values) => {
            setDetailsValues(values);
            setStep(2);
          }}
          enableReinitialize
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                {renderInput(
                  "text",
                  "companyJobTitle",
                  "Company Job Title",
                  required,
                  errors,
                  touched
                )}
                {renderInput(
                  "text",
                  "companyJobDescription",
                  "Summary (Upto 500 characters)",
                  required,
                  errors,
                  touched
                )}
                {renderInput(
                  "text",
                  "applyLink",
                  "Job Application Link",
                  required,
                  errors,
                  touched
                )}
                {renderInput(
                  "text",
                  "location",
                  "Location (e.g. City, State)",
                  required,
                  errors,
                  touched
                )}
                {renderRadios(
                  "locationType",
                  "Location Type",
                  [
                    { label: "Remote", slug: "remote" },
                    { label: "Office", slug: "office" },
                    { label: "Hybrid", slug: "hybrid" },
                  ],
                  required,
                  errors,
                  touched
                )}
                {renderRadios(
                  "workType",
                  "Work Type",
                  [
                    { label: "Contract", slug: "contract" },
                    { label: "Full-Time", slug: "full-time" },
                    { label: "Part-Time", slug: "part-time" },
                  ],
                  required,
                  errors,
                  touched
                )}
                {renderInput(
                  "text",
                  "salary",
                  "Salary ($)",
                  required,
                  errors,
                  touched
                )}
                {/* {renderInput(
                  "text",
                  "strongMatchThreshold",
                  "Match Threshold - Strong Match (%)",
                  required,
                  errors,
                  touched
                )} */}
                {/* {renderInput(
                  "text",
                  "weakMatchThreshold",
                  "Match Threshold - Minimum Match (%)",
                  required,
                  errors,
                  touched
                )} */}
                <Button type="submit" colorScheme={"blue"}>
                  Next
                </Button>
              </VStack>
            </form>
          )}
        </Formik>
      </>
    );
  };

  const handleRatingsSubmission = (updatedValues: CompetencyRatingMap) => {
    onSubmit(detailsValues, updatedValues);
  };

  /*
  const ratings = job?.categories.map(
    (item) => item.competencies as any as IGenericCompetencyRating
  );
  */

  const renderRatingsStep = () => {
    return (
      <>
        {text("section.title", "Job Skills")}
        {text(
          "section.instruction",
          "Please select the rubric statement that closely matches each competency for the job you are posting."
        )}
        {job && (
          <JobRater
            job={job}
            jobRating={{
              jobId: job.id,
              competencyRatings: jobCompetencies || []
            }}
            onSubmit={handleRatingsSubmission}
            ratingRole={UserRole.Recruiter}
          />
        )}
      </>
    );
  };
  return (
    <Box>
      {text("page.title", `Job Post: ${jobPost?.companyJobTitle || job?.title}`)}
      {text(
        "page.instruction",
        "Please fill out the details needed for your job post below."
      )}
      {step === 1 ? renderDetailsStep() : renderRatingsStep()}
    </Box>
  );
};

export default JobPost;
