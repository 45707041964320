import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Center, Progress, Text } from "@chakra-ui/react";
import { intersection } from "lodash";

import { IJob } from "../../types/job";
import { IGenericCompetencyRating } from "../../types/rating";
import { OnboardingStatus, UserRole } from "../../types/user";
import CategoryRater, { CompetencyRatingMap } from "./CategoryRater";
import { UserContextValue, useUser } from "../../stores/UserStore";

interface JobRaterProps {
  job: IJob;
  jobRating?: {
    jobId: string;
    competencyRatings: IGenericCompetencyRating[];
  }; // IJobRating | IJobPost;
  onSubmit: (updatedValues: CompetencyRatingMap) => void;
  ratingRole: UserRole;
}

const JobRater = ({ job, jobRating, onSubmit, ratingRole }: JobRaterProps) => {
  const initValues: CompetencyRatingMap = {};
  
  if (jobRating) {
    for (const competencyRating of jobRating.competencyRatings) {
      initValues[competencyRating.competencyId] = competencyRating.rating;
    }
  }

  const [values, setValues] = useState<CompetencyRatingMap>(initValues);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0);

  const onPrev = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
      window.scrollTo({ top: 0 });
    }
  };

  const onNext = async () => {
    if (currentCategoryIndex === job.categories.length - 1) {      
      onSubmit(values);
    } else {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
      window.scrollTo({ top: 0 });
    }
  };

  const progressPercentage = Math.round(
    ((currentCategoryIndex + 1) / job.categories.length) * 100
  );

  const currentCategory = job.categories[currentCategoryIndex];

  const isNextDisabled = () => {
    if (!values) {
      return true;
    }

    const expectedCategoryCompetencyIds = currentCategory.competencies.map(
      (c) => c.id
    );
    const allCompetencyValueIds = Object.keys(values);
    const comparison = intersection(
      allCompetencyValueIds,
      expectedCategoryCompetencyIds
    );
    return comparison.length !== expectedCategoryCompetencyIds.length;

    // const categoryValues = values[currentCategory.id];
    // if (!categoryValues) {
    //   return true;
    // }

    // const currentCategoryCompetencyRatings = Object.keys(categoryValues as CompetencyRatingContainer);
    // const expectedCompetenciesWithRating = currentCategory.competencies.map(c => c.id);
    // const comparison = intersection(currentCategoryCompetencyRatings, expectedCompetenciesWithRating);
    // return  comparison.length !== expectedCompetenciesWithRating.length;
  };

  const handleCategoryChange = (competencyRatings: CompetencyRatingMap) => {
    const updatedValues = {
      ...values,
      ...competencyRatings,
    };
    setValues(updatedValues);
  };

  return (
    <Box>
      <Progress value={progressPercentage} />
      <Center mb={5}>
        <Text>
          {currentCategoryIndex + 1} of {job.categories.length} Competency Group
        </Text>
      </Center>
      <Center>
        <CategoryRater
          category={currentCategory}
          ratingsValues={values}
          onChange={handleCategoryChange}
          ratingRole={ratingRole}
        />
      </Center>
      <Center>
        <Button onClick={onPrev} isDisabled={currentCategoryIndex === 0}>
          Prev
        </Button>
        <Button
          ml={3}
          onClick={onNext}
          isDisabled={isNextDisabled()}
          colorScheme="blue"
        >
          {currentCategoryIndex === job.categories.length - 1
            ? "Submit"
            : "Next"}
        </Button>
      </Center>
    </Box>
  );
};

export default JobRater;
