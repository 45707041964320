import { Box, Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { JobContextValue, useJob } from "../../stores/JobProvider";
import { text } from "../../components/util/texts";
import JobSelector from "../../components/shared/JobSelector";
import { JobPostContextValue, useJobPost } from "../../stores/JobPostProvider";

const RecruiterJobSelectContainer = () => {
  const navigate = useNavigate();

  const { currentUser } = useUser() as UserContextValue;
  const { jobs, isFetchingJobs, fetchJobs } = useJob() as JobContextValue;
  const { jobPosts, isFetchingJobPosts, fetchJobPosts } = useJobPost() as JobPostContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJobs();
      fetchJobPosts();
    }
  }, []);

  if (isFetchingJobs || isFetchingJobPosts) {
    <Center height="90vh">
      <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
    </Center>
  }

  if (!currentUser || !jobs || !jobPosts) {
    return null;
  }

  const onSelect = (jobId: string) => {
    navigate(`/jobPost/create/${jobId}`);
  };
  
  const existingJobIds = jobPosts.map((jp) => {
    const obj = jp as any;
    return obj.jobId;
  });

  return (
    <Box px={20} py={15}>
      {text("page.title", "New Job Post")}
      {text(
        "page.instruction",
        "Select a job title to start posting a job.",
        5
      )}
      <JobSelector
        jobs={jobs}
        existingJobIds={existingJobIds}
        onSelect={onSelect}
      />
    </Box>
  );
};

export default RecruiterJobSelectContainer;
