import {
  Accordion,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Link,
  Text,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalHeader,
} from "@chakra-ui/react";
import {
  IJobMatch,
  JobMatchStatus,
} from "../../types/match";
import Category from "../shared/Category";
import MatchPercentage from "../shared/MatchPercentage";
import { text } from "../util/texts";
import { statusTag } from "../util/tags";
import { Link as RouterLink } from "react-router-dom";
import {
  getCategoryMatches,
  getCategoryRating,
} from "../../utils/categories";
import { UserRole } from "../../types/user";
import Expander from "../recruiter/match/Expander";
import { ReactNode, useState } from "react";
import { renderMatchActionsForJobSeeker, renderMatchHeaderForJobSeeker, renderMatchHeaderForRecruiter, renderMatchActionsForRecruiter } from "../util/match";
import ShowEvidences from "../common/ShowEvidences";
import { DownloadIcon, ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { ApiInstance } from "../../api/ApiInstance";

interface JobMatchProps {
  userRole: UserRole;
  match: IJobMatch;
  isFetching: boolean;
  isProductTour: boolean;
  onMatchAction: (toStatus: JobMatchStatus) => void;
}

const MatchDetail = ({ userRole, match, isFetching, isProductTour, onMatchAction }: JobMatchProps) => {

  const color = match.matchPercentage > 80 ? "green" : "orange";
  const [viewMode, setViewMode] = useState<'match' | 'upskilling'>('match');
  const [index, setIndex] = useState<number[]>(isProductTour ? [0] : [])
  const { isOpen: isApplyModalOpen, onOpen: onApplyModalOpen, onClose: onApplyModalClose } = useDisclosure();
  const { isOpen: isLearnModalOpen, onOpen: onLearnModalOpen, onClose: onLearnModalClose } = useDisclosure();
  const { isOpen: isViewModeModalOpen, onOpen: onViewModeModalOpen, onClose: onViewModeModalClose } = useDisclosure();

  const renderStatus = () => {
    const renderRecruiterStatus = (): ReactNode => {
      if (!match.recruiterStatus) {
        return null;
      }

      if (userRole === UserRole.Recruiter ||
          (match.recruiterStatus.status as JobMatchStatus) === JobMatchStatus.RecruiterInvited) {
        return statusTag(match.recruiterStatus.status, {colorScheme: "teal", size: "md" })
      }   
    }

    const renderJobSeekerStatus = () => {
      if (!match.userStatus) {
        return null;
      }

      if (userRole === UserRole.User || userRole === UserRole.Navigator || 
          (match.userStatus.status as JobMatchStatus) === JobMatchStatus.CandidateApplied) {
        return statusTag(match.userStatus.status, {colorScheme: "purple", size: "md" })
      }   
    }

    if (userRole === UserRole.User || userRole === UserRole.Navigator) {
      return (
        <HStack mb={2} className="m-match-status-step">
          {renderJobSeekerStatus()}
          {renderRecruiterStatus()}
        </HStack>
      )
    }
    else {
      return (
        <HStack mb={2} className="m-match-status-step">
          {renderRecruiterStatus()}
          {renderJobSeekerStatus()}          
        </HStack>
      )
    }
  };

  const renderActions = () => {
    const jobSeekerStatus = match.userStatus?.status || undefined;
    const recruiterStatus = match.recruiterStatus?.status || undefined;

    const handleOnMatchAction = (toStatus: JobMatchStatus) => {
      if (toStatus === JobMatchStatus.CandidateApplied) {
        onApplyModalOpen();        
      }
      else {
        onMatchAction(toStatus);
      }
    }

    if (userRole === UserRole.User) {
      return renderMatchActionsForJobSeeker(jobSeekerStatus, handleOnMatchAction);
    }
    else if (userRole === UserRole.Recruiter) {
      return renderMatchActionsForRecruiter(recruiterStatus, jobSeekerStatus, handleOnMatchAction)
    }
    else {
      return null;
    }
  };

  const renderApplyModal = () => {
    if (userRole === UserRole.User) {
      const onConfirmApply = () => {
        onMatchAction(JobMatchStatus.CandidateApplied);
        onApplyModalClose();
      }

      return (
        <Modal isOpen={isApplyModalOpen} onClose={onApplyModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Apply</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {text(
                "body.lg",
                'You will be taken to an external link to apply to the job.',
                3
              )}              
            </ModalBody>
  
            <ModalFooter>
              <Button
                colorScheme="blue"
                as={Link}
                mr={3}
                isExternal={true}
                href={match.jobPost.applyLink}
                onClick={onConfirmApply}
              >
                Continue to Apply
              </Button>
              <Button variant="ghost" onClick={onApplyModalClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )
    }    
  }

  const renderLearnModal = () => {
    return (
      <Modal isOpen={isLearnModalOpen} onClose={onLearnModalClose}>
        <ModalOverlay />
        <ModalContent maxWidth="56rem">
          <ModalHeader>Proficiency Levels, Skills Matching, and Upskilling</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ fontWeight: 'bold'}}>
              Proficiency Levels
            </div>
            <p>
            Each skill is broken down into three levels of proficiency. The three levels of proficiency are Knowledge, Skill, and Ability. Those with the Knowledge level proficiency posses a general understanding of the topic and its implications. Those with the Skill level proficiency are implementors and practitioners of the topic or area of focus. Those with the Ability level proficiency are innovators and leaders with regard to the topic or area of focus. Different employers will require different levels of proficiency for each skill.
            </p>
            <br></br>
            <div style={{ fontWeight: 'bold'}}>
              Skill Matching
            </div>
            <p>
            Job matches are calculated through a process called “Skills Matching.” Your asserted proficiency level for each skill is compared to the required proficiency levels set by the employer. For each skill you are told if you are currently at, below, or above the expected proficiency level. These standings combine to provide you with a percentage map at the Skill Group level, as well as the overarching job level.
            </p>
            <br></br>
            <div style={{ fontWeight: 'bold'}}>
              Upskilling
            </div>
            <p>
            Available learning resources are provided to help you upskill with regard to each job skill. For each resource, you can see what level of proficiency you will obtain upon successful completion of the learning activity. Upskilling will help you increase your overall alignment with a job. 
            </p>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onLearnModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const renderViewModeModal = () => {
    return (
      <Modal isOpen={isViewModeModalOpen} onClose={onViewModeModalClose}>
        <ModalOverlay />
        <ModalContent maxWidth="56rem">
          <ModalHeader>Match Summary and Upskilling & Development Plan Views</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ fontWeight: 'bold'}}>
              Match Summary View
            </div>
            <p>
            The Match Summary view shows how each Skill is grouped into a Skills Group, and shows your percentage of alignment with that Skills Group. Additionally, each skill within the group is displayed, along with the proficiency level required by the job and your own self-asserted proficiency level. Lastly, the number of available learning resources for each skill is shown.
            </p>
            <br></br>
            <div style={{ fontWeight: 'bold'}}>
              Upskilling Plan View
            </div>
            <p>
            The Upskilling Plan View focuses on the available upskilling and learning resources attached to each Skill. Each resource is displayed, showing information such as duration, the provider, and the proficiency-level outcome of course completion.
            </p>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onViewModeModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const expandAll = () => {
    const indexes: number[] = []
    match.job.categories.forEach((item, i) => indexes.push(i))

    setIndex(indexes)
  }

  const renderCompetencyCategories = () => {    
    const toggleAccordionItem = (i: number) => {
      if (index.includes(i - 1)) {
        setIndex((prev: any) => prev.filter((item: number) => item !== i - 1))
      } else {
        setIndex((prev: any) => [...prev, i - 1])
      }
    }

    const isJobSeeker = userRole === UserRole.User;

    const competencySectionTitle = isJobSeeker ? 'Job Skills' : 'Job Seeker Skills';

    const isMatchViewMode = viewMode === 'match';
    const isUpskillingViewMode = viewMode === 'upskilling';
    
    const onUpskillingView = () => {
      setViewMode('upskilling')
      expandAll();
    }

    return (
      <>      
        <Box mb="3">
        <Button leftIcon={<InfoIcon />} size="sm" colorScheme="green" onClick={onLearnModalOpen}>Learn about Proficiency Levels, Skills Matching, and Upskilling</Button>
        </Box>

        <Box className="m-fifth-step">
        {text("section.title", competencySectionTitle)}          
        </Box>
        <HStack alignItems="flex-start">
          <ButtonGroup isAttached mb={3}>
            <Button onClick={() => setViewMode('match')} size="sm" colorScheme={isMatchViewMode ? 'blue' : 'gray'} variant={isMatchViewMode ? 'solid' : 'outline'}>Match Summary View</Button>
            <Button onClick={onUpskillingView} size="sm" colorScheme={isUpskillingViewMode ? 'blue' : 'gray'} variant={isUpskillingViewMode ? 'solid' : 'outline'}>Upskilling Plan View</Button>
          </ButtonGroup>
          <IconButton onClick={onViewModeModalOpen} aria-label='Info about match and upskilling view modes' size="sm" variant='ghost' icon={<InfoIcon />} />
        </HStack>
        <Expander onColapseAll={() => setIndex([])} onExpandAll={expandAll} />
        <Accordion pb={20} index={index} allowToggle allowMultiple>
          {match.job.categories.map((category, i) => (
            <Category
              key={i}
              index={i + 1}
              toggle={toggleAccordionItem}
              category={category}
              categoryRating={getCategoryRating(category, match.jobRating)}
              categoryCompetencyMatches={getCategoryMatches(category, match)}
              isJobSeeker={isJobSeeker}
              inlineUpskillingMode={isUpskillingViewMode}
            />
          ))}
        </Accordion>      
      </>
    )
  }

  const renderMatchHeader = () => {
    if (userRole === UserRole.User || userRole === UserRole.Navigator) {
      return renderMatchHeaderForJobSeeker(match);
    }
    else {
      return renderMatchHeaderForRecruiter(match);
    }
  }

  if (isFetching) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    );
  };

  const renderMatchedJobPost = () => {
    // only relevant for a recruiter
    if (userRole !== UserRole.Recruiter) {
      return null
    }

    return (
      <Text fontSize="2xl" mb={3}>
        Job Post:{" "}
        <Link
          textDecoration="underline"
          as={RouterLink}
          to={`/jobPost/${match.jobPost.id}`}
        >
          {match.jobPost.companyJobTitle}
        </Link>
      </Text>
    )
  }

  const renderEvidences = () => {
    if (userRole === UserRole.Recruiter && match.evidences !== undefined) {
      return (
        <ShowEvidences evidences={match.evidences} />
      )
    }
  }

  const renderResumeFiles = () => {
    if (!match.user.resumeFile) {
      return (
        null
      )
    }

    return (
      <Link href={`${ApiInstance.assetsUrl}/${match.user.resumeFile.id}?access_token=${localStorage.getItem("authorizationToken")}&download`} target="_blank" download={match.user.resumeFile.filename_download} cursor="pointer" style={{ color: 'blue' }} fontSize={{ base: 'md' }} color='blue.600'>
        <DownloadIcon /> Download Resume
      </Link>
    )
  }

  const renderViewLinkedIn = () => {
    if (!match.user.linkedIn) {
      return (
        null
      )
    }

    return (
      <HStack>
            <Box>
              <Link href={match.user.linkedIn} cursor="pointer" style={{ color: 'blue' }} fontSize={{ base: 'md' }} color='blue.600' isExternal>
              <ExternalLinkIcon /> View LinkedIn Profile
            </Link>
            </Box>                  
      </HStack>
    )
  }

  const renderSummary = () => {
    if (userRole === UserRole.User || userRole === UserRole.Navigator) {
      return (
        <Box mb={7}>
          {text("section.title", "Job Description")}
          {text("section.instruction", match.jobPost.companyJobDescription)}
        </Box>
      );
    }
    else {
      return (
        <Box mb={7}>
          {text("section.title", "Job Seeker Summary")}
          {text("section.instruction", match.user.summary)}
         <HStack>
          {renderViewLinkedIn()}
          {renderResumeFiles()}
          </HStack>
        </Box>
      )
    }
  }  

  return (
    <Box px={2} className="m-third-step">
      <Box py={2} mb={5}>
        <Grid gridTemplateColumns="6fr 1fr">
          <GridItem>
            {renderStatus()}
            {renderMatchHeader()}
            <Box className="m-fourth-step">
            {renderActions()}
            </Box>
          </GridItem>
          <GridItem>
            <Box className="m-match-percentage-step">
              <MatchPercentage
                percentage={match.matchPercentage}
                color={color}
                label=""
              />
            </Box>            
          </GridItem>
        </Grid>
      </Box>
      <Divider mb={5} />
      {renderMatchedJobPost()}
      {renderSummary()}
      {renderEvidences()}
      {renderCompetencyCategories()}
      {renderApplyModal()} 
      {renderLearnModal()}
      {renderViewModeModal()}    
    </Box>
  );
};

export default MatchDetail;
