export const borders = {
  card: { border: "1px", borderColor: "gray.200", borderRadius: 6, p: 3 },
  cardRaised: { boxShadow: "md", border: "1px", borderColor: "gray.200", borderRadius: 6, p: 3 },
  cardRaisedBold: { boxShadow: "md", border: "2px", borderColor: "gray.300", borderRadius: 6, p: 3 },
  noTop: { borderBottom: "1px", borderRight: "1px", borderLeft: "1px", borderColor: "gray.200", borderBottomLeftRadius: 6, borderBottomRightRadius: 6 },
  left: { borderLeft: "1px", borderColor: "gray.200" },
}

export const scrolls = {
  yOverflowSubtle: { overflow: "auto", css: { '&::-webkit-scrollbar': { width: '1px' }} }
}