import { Box, Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { RatingContextValue, useRating } from '../../stores/RatingProvider';
import UserJobProfileManage from '../../components/user/profile/UserJobProfileManage';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';
import { text } from '../../components/util/texts';
import ShowEvidences from '../../components/common/ShowEvidences';
import { EvidenceContextValue, useEvidence } from '../../stores/EvidenceStore';

const UserJobRatingContainer = () => {
  const { jobRatingId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: isEvidenceModalOpen, onOpen: onEvidenceModalOpen, onClose: onEvidenceModalClose } = useDisclosure();

  const { currentUser, uploadUserFile } = useUser() as UserContextValue;

  const { rating, isFetchingRatings, fetchUserJobRating, updateUserJobRating } = useRating() as RatingContextValue;
  const {
    evidences,
    isFetchingEvidences,
    deleteEvidence,
    addEvidence,
    updateEvidence,
    fetchEvidences,
  } = useEvidence() as EvidenceContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchUserJobRating(jobRatingId as string)
      fetchEvidences(currentUser.id)
    }
  }, []);

  if (isFetchingRatings) {
    <Center height="90vh">
      <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
    </Center>
  }

  if (!currentUser || !rating) {
    return null;
  }

  if (isFetchingEvidences) {
    return (
      text("body.regular" ,"Loading ...")
    )
  }

  if (evidences === undefined) {
    text("body.regular" ,"Error fetching evidences")
  }
  
  const onEvidenceDelete = (evidenceId: string) => {
    deleteEvidence(evidenceId);
    setTimeout(() => fetchEvidences(currentUser.id), 500);
  };

  const onEvidenceAddOrUpdate = async (formVaules: any, file?: File) => {
    if (jobRatingId) {
      if (file !== undefined) {
        const fileId = await uploadUserFile(file);
        console.log('fileId', fileId);
        formVaules.fileUpload = fileId;
      }

      if (formVaules.id) {
        updateEvidence(formVaules, jobRatingId)
      }
      else {
        addEvidence(formVaules, jobRatingId);
      }      
      setTimeout(() => fetchEvidences(currentUser.id), 750);
    }    
  };

  const renderEvidences = () => {
    if (!jobRatingId) return null;

    const jobRatingEvidences = evidences?.filter(e => e.jobRatingId === jobRatingId) || [];

    return (
      <Box mb={"4"}>
        <ShowEvidences 
        evidences={jobRatingEvidences}
        onEvidenceDelete={onEvidenceDelete}
        onEvidenceAddOrUpdate={onEvidenceAddOrUpdate}
        canHide={false}
        />
      </Box>
    )
  }

  const renderEvidenceModal = () => {
    return (
      <Modal isOpen={isEvidenceModalOpen} onClose={onEvidenceModalClose}>
        <ModalOverlay />
        <ModalContent maxWidth="56rem">
          <ModalHeader>
            {text('section.title', 'Would you like to add evidence?')}            
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {text(
              'body.regular',
              `Add any evidence items that you think demonstrate skills for this job '${rating.job.title}'. Evidence can be added using links that contain examples of your work.`,
              4
            )}
            {text(
              'body.regular',
              `When you are done adding evidence for this job, or if you want to do it later, click on 'Go to See Job Matches' button below to see how you fit job openings for '${rating.job.title}'`,
              4
            )}
            {renderEvidences()}
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => {
              onEvidenceModalClose()
              navigate(`/jobMatches?job-rating=${rating?.id}`);
            }}
              colorScheme='blue'
            >
              Go to see Job Matches
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const onSubmit = (updatedValues: CompetencyRatingMap) => {
    updateUserJobRating(rating.job.id, updatedValues).then(() => {
      toast({
        title: 'Job skillset updated!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onEvidenceModalOpen()
    });
  }

  return (
    <Box>
    <Box px={20} py={15}>
      <UserJobProfileManage job={rating.job} jobRating={rating} onSubmit={onSubmit} />
    </Box>
    {renderEvidenceModal()}
    </Box>
  )
};

export default UserJobRatingContainer;