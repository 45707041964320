import {
  Box,
  Button,
  Collapse,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IEvidence } from "../../types/evidence";
import { text } from "../util/texts";
import AddOrUpdateEvidence from "./AddOrUpdateEvidence";
import Evidence from "./Evidence";
import { AddIcon } from '@chakra-ui/icons';

interface ShowEvidencesProps {
  evidences: IEvidence[];
  onEvidenceDelete?: (evidienceId: string) => void;
  onEvidenceAddOrUpdate?: (newEvent: any, file?: File) => void;
  canHide?: boolean,
}

const ShowEvidences = ({
  evidences,
  onEvidenceDelete,
  onEvidenceAddOrUpdate,
  canHide = true,
}: ShowEvidencesProps) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const [selectedEvidence, setSelectedEvidence] = useState<IEvidence | undefined>(undefined);
  const [formMode, setFormMode] = useState<"Create" | "Update">("Create");
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [isAddOrUpdateEvidenceModalOpen, setIsAddOrUpdateEvidenceModalOpen] = useState(false);
  const collapseTitle = isOpen ? "Hide" : "Show";
  const renderAddOrUpdateEvidenceModal = () => {
    if (!onEvidenceAddOrUpdate) {
      return null;
    }

    return (
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isAddOrUpdateEvidenceModalOpen}
        onClose={() => {
          setIsAddOrUpdateEvidenceModalOpen(false);
          setSelectedEvidence(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Your New Evidence</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <AddOrUpdateEvidence
              evidence={selectedEvidence}
              onSubmit={onEvidenceAddOrUpdate}
              onCancel={() => {
                setIsAddOrUpdateEvidenceModalOpen(false);
                setSelectedEvidence(undefined);
              }}
              formMode={formMode}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const openEvidenceModal = (evidence?: IEvidence) => {
    if (evidence) {
      setFormMode("Update");
      setSelectedEvidence(evidence);
    }
    setIsAddOrUpdateEvidenceModalOpen(true);
  };
  return (
    <Box mb={4}>
      {renderAddOrUpdateEvidenceModal()}

      {text("section.title", "Evidences")}      
      <HStack>
        { onEvidenceAddOrUpdate && (
          <Button
            onClick={() => {
              openEvidenceModal();
              setFormMode("Create");
            }}
            colorScheme="blue"
            size="sm"
            leftIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
        { canHide && <Button onClick={onToggle} variant="link">{collapseTitle}</Button> }
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Box>          
          {evidences?.length > 0 ?
            (
              <OrderedList>
                {evidences.map((evidence) => {
                  return (
                    <ListItem key={evidence.id}>
                      <Evidence
                        openEvidence={onEvidenceAddOrUpdate ? openEvidenceModal : undefined}
                        evidences={[evidence]}
                        onEvidenceDelete={onEvidenceDelete}
                      />
                    </ListItem>
                  );
                })}
              </OrderedList>
            )
            :
            (
              <Box mt={2}>
                {text('body.regular', 'No Evidence Added')}
              </Box>
            )            
          }
        </Box>
      </Collapse>
    </Box>
  );
};

export default ShowEvidences;
