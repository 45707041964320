import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { MatchContextValue, useMatch } from "../stores/MatchProvider";
import { UserContextValue, useUser } from "../stores/UserStore";
import { IJobMatch } from "../types/match";
import { IRecruiter, UserRole } from "../types/user";
import { Box, Center, Spinner, useToast } from "@chakra-ui/react";
import MatchDetail from "../components/match/MatchDetail";
import { text } from "../components/util/texts";
import { capitalize } from "lodash";

const SingleMatchContainer = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const userId = searchParams.get("userId")
    const jobPostId = searchParams.get("jobPostId")
    const { currentUser, saveMatchActivity } = useUser() as UserContextValue;    
    const { matches, isFetchingMatches, fetchMatches } = useMatch() as MatchContextValue;

    const toast = useToast();

    useEffect(() => {
      if (currentUser) {
        fetchMatches({ searchTerm: '', filterValues: [
          { slug: 'job-post', selectedValue: jobPostId as string },
          { slug: 'user', selectedValue: userId as string }
        ]})
      }      
    }, []);
    
    if (!currentUser || matches === undefined || matches.length === 0) {
      return null;
    }

    if (isFetchingMatches) {
      return (
        <Center height="90vh">
          <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Center>
      );
    }    

    const singleMatch = matches?.find((match: IJobMatch) => match.jobPostId === jobPostId && match.userId === userId) as IJobMatch
    if (!singleMatch) {
      return text('body.lg', 'Match Not Found')
    }

    const onMatchAction = async (action: string) => {
      const activity = {
        userId: singleMatch.userId,
        jobId: singleMatch.jobId,
        jobRatingId: singleMatch.jobRatingId,
        jobPostId: singleMatch.jobPostId,
        matchPercentageSnapshot: singleMatch.matchPercentage,
        status: action,
        activityByRole: currentUser.userRole,
        activityById: currentUser.id,
        activityDate: new Date().toISOString()
      }
  
      const result = await saveMatchActivity(activity);
      if(result === 'success') {
        toast({
          position: "top",
          title: `Match Action: ${capitalize(action)}`,
          description: `Successfully submitted!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  
        fetchMatches()
      }
    };

    return(
      <Box px={{ base: 15, md: 45 }} py={{ base: 15, md: 15 }}>
        <MatchDetail
          match={singleMatch}
          userRole={currentUser.userRole as UserRole}
          onMatchAction={onMatchAction}
          isFetching={isFetchingMatches}
          isProductTour={false}
        />
      </Box>      
    )
  };
  
  export default SingleMatchContainer;