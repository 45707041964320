import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { startCase } from "lodash";
import { string } from "prop-types";
import { IconType } from "react-icons";
import { HiOutlineCurrencyDollar, HiBriefcase, HiOutlineLocationMarker, HiOutlineTag } from 'react-icons/hi';

interface TagOptions {
  variant?: string,
  colorScheme?: string,
  size?: string,
  borderRadius?: string,
}

const sizeToIconBoxSize = {
  'md': '14px',
  'lg': '18px',
}

const defaultOptions: TagOptions = {
  variant: 'subtle',
  colorScheme: 'gray',
  size: 'md',
  borderRadius: '25px',
}

export const tagWithIcon = (icon: IconType, label: string, options: TagOptions = {}) => {
  const derivedOptions = { ...defaultOptions, ...options };
  return (
    <Tag variant={derivedOptions.variant} size={derivedOptions.size} borderRadius={derivedOptions.borderRadius} colorScheme={derivedOptions.colorScheme}>
      <TagLeftIcon boxSize={sizeToIconBoxSize} as={icon} />
      <TagLabel>{startCase(label)}</TagLabel>
    </Tag>
  );
};

export const salaryTag = (label: string, options?: TagOptions) => {
  return tagWithIcon(HiOutlineCurrencyDollar, label, options);
}

export const workTypeTag = (label: string, options?: TagOptions) => {
  return tagWithIcon(HiBriefcase, label, options);
}

export const locationTag = (label: string, options?: TagOptions) => {
  return tagWithIcon(HiOutlineLocationMarker, label, options);
}

export const statusTag = (label: string, options?: TagOptions) => {
  return tagWithIcon(HiOutlineTag, label, { borderRadius: '6', ...options})
}