import { createContext, useContext, useState } from "react";
import { api } from "../api";
import { IJobPostSave } from "../types/jobPostSave";
import { UserContextValue, useUser } from "./UserStore";

export type JobPostSaveContextValue = {
  savedJobPosts: IJobPostSave[] | undefined;
  fetchSavedJobPosts: () => void;
  saveJobPost: (formValues: any) => void;
  isFetching: boolean;
};

const JobPostSaveContext =
  createContext<JobPostSaveContextValue | undefined>(undefined);

export const useJobPostSave = () => useContext(JobPostSaveContext);

const JobPostSaveProvider = ({ children }: any) => {
  const [savedJobPosts, setSavedJobPost] = useState<IJobPostSave[] | undefined>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { currentUser } = useUser() as UserContextValue;

  const fetchSavedJobPosts = async () => {    
    try {
      setIsFetching(true);
      const result = await api.get(`/items/jobPostSave`);
      setSavedJobPost(result.data.data);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };
  const saveJobPost = async (body: any) => {
    setIsFetching(true);
    const response = await api.post(`/items/jobPostSave`, body);
    //TODO - error handling for the above request
    fetchSavedJobPosts();
    setIsFetching(false);
    if (currentUser) {
      setIsFetching(false);
    }
  };

  return (
    <JobPostSaveContext.Provider
      value={{
        savedJobPosts,
        saveJobPost,
        fetchSavedJobPosts,
        isFetching,
      }}
    >
      {children}
    </JobPostSaveContext.Provider>
  );
};

export default JobPostSaveProvider;
