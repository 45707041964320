import ResetPassword from '../components/auth/ResetPassword';
import { UserContextValue, useUser } from '../stores/UserStore';
import RecruiterReportsContainer from './recruiter/RecruiterReportsContainer';
import JobSeekerReportsContainer from './user/JobSeekerReportsContainer';
const ReportsContainer = () => {

  const {
    currentUser,
  } = useUser() as UserContextValue;

  if (!currentUser) {
    return null;
  }

  if (currentUser.userRole === 'user') {
    if (process.env.REACT_APP_MODE === 'demo' && currentUser.status === 'unverified') {
      return <ResetPassword />
    }
    return <JobSeekerReportsContainer />
  }
  else if (currentUser.userRole === 'recruiter') {
    return <RecruiterReportsContainer />
  }
  else {
    return null;
  }

}

export default ReportsContainer;