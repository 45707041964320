import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserJobProfile from "../../components/user/profile/UserJobProfile";
import { useEvidence, EvidenceContextValue } from "../../stores/EvidenceStore";
import { RatingContextValue, useRating } from "../../stores/RatingProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { IJobRating } from "../../types/rating";

const UserJobProfileContainer = () => {
  const navigate = useNavigate();

  const { currentUser, uploadUserFile } = useUser() as UserContextValue;

  const [selectedJobRating, setSelectedJobRating] = useState<IJobRating | undefined>();

  const { ratings, isFetchingRatings, fetchUserJobRatings } =
    useRating() as RatingContextValue;

  const {
    evidences,
    isFetchingEvidences,
    deleteEvidence,
    addEvidence,
    updateEvidence,
    fetchEvidences,
  } = useEvidence() as EvidenceContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchUserJobRatings();
      fetchEvidences(currentUser.id);
    }
  }, []);

  useEffect(() => {
    if (ratings && ratings.length > 0) {
      setSelectedJobRating(ratings[0]);
    }     
  }, [ratings])  

  if (isFetchingRatings) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }  

  if (!currentUser || !ratings) {
    return null;
  }

  const onSelectJobRating = (jobRatingId: string) => {
    setSelectedJobRating(ratings.find(jr => jr.id === jobRatingId));
  }

  const onEditJobRating = (jobRatingId: string) => {
    navigate(`/jobRating/${jobRatingId}`);
  };

  const onEvidenceDelete = (evidenceId: string) => {
    deleteEvidence(evidenceId);
    setTimeout(() => fetchEvidences(currentUser.id), 500);
  };

  const onEvidenceAddOrUpdate = async (formVaules: any, file?: File) => {
    if (selectedJobRating) {
      if (file !== undefined) {
        const fileId = await uploadUserFile(file);
        console.log('fileId', fileId);
        formVaules.fileUpload = fileId;
      }

      if (formVaules.id) {
        updateEvidence(formVaules, selectedJobRating.id)
      }
      else {
        addEvidence(formVaules, selectedJobRating.id);
      }      
      setTimeout(() => fetchEvidences(currentUser.id), 750);
    }    
  };

  if (isFetchingEvidences) {
    return (
      <Text>Loading ...</Text>
    )
  }

  if (evidences === undefined) {
    return <Text>Error fetching evidences</Text>;
  }

  return (
    <Box px={20} py={15}>
      <UserJobProfile
        user={currentUser}
        jobRatings={ratings}
        selectedJobRating={selectedJobRating}
        onSelectJobRating={onSelectJobRating}
        onEditJobRating={onEditJobRating}
        evidences={evidences}
        onEvidenceDelete={onEvidenceDelete}
        onEvidenceAddOrUpdate={onEvidenceAddOrUpdate}
      />
    </Box>
  );
};

export default UserJobProfileContainer;
