import '@fontsource/inter/400.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/700.css'
import '@fontsource/tangerine/400.css'
import '@fontsource/tangerine/700.css'

import { useEffect } from 'react';
import { Spinner, Center } from '@chakra-ui/react';
import { Routes, Route } from "react-router-dom";
import { OnboardingStatus } from "../types/user";
import { UserContextValue, useUser } from '../stores/UserStore';
import routes from '../containers/routes'
import ProtectedRoutes from '../containers/ProtectedRoutes'

const App = () => {
  const {
    currentUser,
    isFetchingCurrentUser,
    fetchCurrentUser,
    updateOnboardingStatus,
    logoutUser,
  } = useUser() as UserContextValue;

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (isFetchingCurrentUser) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    );
  }

  const onProductTourEnable = () => {
    updateOnboardingStatus(OnboardingStatus.ProductTour);    
  }

  return (
    <Routes>
      {routes.map((route, idx) =>
        route.isProtected
          ? <Route key={idx} path={route.routeObj.path} element={
            <ProtectedRoutes 
                isAuthenticated={currentUser !== undefined} 
                user={currentUser}
                onProductTourEnable={onProductTourEnable}
                onLogout={async () => await logoutUser()}>
                {route.routeObj.element}
            </ProtectedRoutes>} />
          : <Route key={idx} path={route.routeObj.path} element={route.routeObj.element} />)
      }
    </Routes>
  );
};

export default App;