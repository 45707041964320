import { FormControl, FormErrorMessage, FormLabel, HStack, Input, Radio, RadioGroup } from "@chakra-ui/react"
import { Field } from "formik";
import { get, set } from "lodash";

export interface IFormInputTextValidation {
  isRequired: boolean;
}

export const renderInput = (type: string, slug: string, label: string, validationOptions?: IFormInputTextValidation, errors?: any, touched?: any) => {
  const fieldProps = {};
  if (validationOptions) {
    set(fieldProps, 'validate', getTextInputValidator(validationOptions));
  }

  // console.log('errors', errors);
  return (
    <FormControl isInvalid={!!get(errors, slug) && get(touched, slug)}>
      <FormLabel htmlFor={slug}>{label}</FormLabel>
      <Field
        as={Input}
        id={slug}
        name={slug}
        type={type}
        variant="filled"
        {...fieldProps}
      />
      <FormErrorMessage>{get(errors, slug)}</FormErrorMessage>
    </FormControl>
  )
}

const getTextInputValidator = (options: IFormInputTextValidation) => (value: any) => {
  let error;

  // console.log('checking', value);
  if (options.isRequired && (!value || value === '')) {
    error = "Value is required.";
  }

  return error;
}

export const renderRadios = (slug: string, label: string, options: { slug: string, label: string }[], validationOptions?: IFormInputTextValidation, errors?: any, touched?: any) => {
  // isInvalid={!!form.errors[name] && !!form.touched[name]}
  const fieldProps = {};
  if (validationOptions) {
    set(fieldProps, 'validate', getTextInputValidator(validationOptions));
  }

  return (
    <Field name={slug} {...fieldProps}>
      {({ field, form }: any) => {
        const { onChange, ...rest } = field;
        return (
          <FormControl
            id={slug}
            isInvalid={!!get(errors, slug) && get(touched, slug)}
          >
            <FormLabel htmlFor={slug}>{label}</FormLabel>
            <RadioGroup {...rest} id={slug}>
              <HStack spacing={3}>
                {options.map((option, idx) => (
                  <Radio key={idx} onChange={onChange} value={option.slug}>{option.label}</Radio>
                ))}
              </HStack>
            </RadioGroup>
            <FormErrorMessage>{get(errors, slug)}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};