import { Box, Button, useToast, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { renderInput } from "../../util/forms";
import { ICompany } from "../../../types/company";
import { text } from "../../util/texts";

interface UserProfileProps {
  company?: ICompany;
  onSubmit: (values: any) => void;
}

const required = { isRequired: true };
// const notRequired = { isRequired: false };

const CompanyProfile = ({
  company,
  onSubmit,
}: UserProfileProps) => {
  const toast = useToast();
  
  return (
    <Box>
      <Formik
          initialValues={{
            name: company ? company.name : '',
            location: company ? company.location : '',
            website: company ? company.website: '',
            summary: company? company.summary: '',
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                {text('section.title', 'Company Information')}
                {renderInput('text', 'name', 'Name', required, errors, touched)}
                {renderInput('text', 'location', 'Location (e.g. City, State)', required, errors, touched)}
                {renderInput('text', 'website', 'Website', required, errors, touched)}                
                {renderInput('text', 'summary', 'Brief Intro', required, errors, touched)}
                <Button onClick={() =>
                    toast({
                    title: 'Company Profile is Updated.',
                    description: "Your company profile was updated.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    })}
                 type="submit" colorScheme={"blue"}>Submit</Button>
              </VStack>
            </form>
          )}
        </Formik>
    </Box>
  )
}

export default CompanyProfile;