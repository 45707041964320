export const RecruiterMatchSteps = [
  {
    target: ".m-zero-step",
    content: "Welcome to the Match Page!",
    title: "Match Page",
    disableBeacon: true,
    disableScrolling: true,
},
  {
    target: ".m-first-step",
    content: "You will see your job seeker matches here based on the applied filters.",
    title: "Your Matches",
    placement: "right-start",
    disableScrolling: true
  },
  {
    target: ".m-second-step",
    content: "You can filter your matches using these filter categories.",
    title: "Match Filter",
    placement: "left-start",
    disableScrolling: true
  },
  {
    target: ".m-third-step",
    content: "Details of the selected match are shown here.",
    title: "Match Detail",
    placement: "top-end",
    disableScrolling: true
  },
  {
    target: ".m-match-status-step",
    content: "Each match has a status.",
    title: "Match Status",
    placement: "top-end",
    disableScrolling: true
  },
  {
    target: ".m-fourth-step",
    content: "The available actions for each match depend on the current status of the match.",
    title: "Action Buttons",
    disableScrolling: true
  },
  {
    target: ".m-match-percentage-step",
    content: "The match percentage shows the overall strength of the match as a rollup of individual competency / skills matches.",
    title: "Match percentage",
    placement: "left",
    disableScrolling: true
  },
  {
    target: ".m-fifth-step",
    content: "These skills groups each contain one or more Skills.",
    title: "Competency Group",
    placement: "right",
    disableScrolling: true
  },
  {
    target: ".m-sixth-step",
    content: "A Competency card shows the level of your skill for each competency (Knowledge, Skill, Ability) compared to the job expectations at this company.",
    title: "Competency",
    placement: "right",
    spotlightClicks: true,
  }
]

export const RecruiterHomeSteps = [
  {
    target: ".h-zero-step",
    content: "Welcome to the Talent Marketplace!",
    title: "Home Page",
    disableBeacon: true,
    placement: "bottom",
    disableScrolling: true
  },
  {
    target: ".h-first-step",
    content: "The dashboard provides quick summary around your job seeker matches and direct links to your top matches.",
    title: "Quick Summary",
    placement: "right-end",
  },
  {
    target: ".h-second-step",
    content: "Job matches that need your review are highlighted in this section.",
    title: "Needs Review",
  },
  {
    target: ".h-third-step",
    content: "The header provides links you can use to navigate this app. Clicking on your avatar to get additional menu items. Next, click on the 'Matches' link to explore that page.",
    title: "The Header",
    placement: "right-end",
  },
]