import { Box, Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { JobContextValue, useJob } from "../../stores/JobProvider";
import { text } from "../../components/util/texts";
import JobSelector from "../../components/shared/JobSelector";
import { RatingContextValue, useRating } from "../../stores/RatingProvider";
import { OnboardingStatus } from "../../types/user";

const UserJobSelectContainer = () => {
  const navigate = useNavigate();

  const { currentUser } = useUser() as UserContextValue;

  const { jobs, isFetchingJobs, fetchJobs } = useJob() as JobContextValue;
  const { ratings, isFetchingRatings, fetchUserJobRatings } =
    useRating() as RatingContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJobs();
      fetchUserJobRatings();
    }
  }, []);

  if (isFetchingJobs || isFetchingRatings) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }

  if (!currentUser || !jobs || !ratings) {
    return null;
  }

  const onSelect = (jobId: string) => {
    navigate(`/jobProfile/create/${jobId}`);
  };

  const existingJobIds = ratings.map((r) => r.jobId);

  const title = currentUser?.onboardingStage === OnboardingStatus.JobSetup ? "Add First Job Skills to Profile" : "Add Job Skills to Profile";

  return (
    <Box px={20} py={15}>
      {text("page.title", title)}
      {text(
        "page.instruction",
        "Below is a library of real-world jobs. Each job contains a collection of Skills relevant to that job. These collections have been curated and vailidated with the help of industry experts to ensure alignment with employer expectations. The listed jobs have been grouped into industry categories for searchability.",
        5
      )}
      {text(
        "page.instruction",
        "Select a job that looks like the right fit for you and start adding new Job Skills to your profile.",
        50
      )}
      <JobSelector
        jobs={jobs}
        existingJobIds={existingJobIds}
        onSelect={onSelect}
      />
    </Box>
  );
};

export default UserJobSelectContainer;
