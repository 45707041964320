import {
  Button,
  Container,
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { Icon } from "@chakra-ui/react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { borders } from "../../styles/common";
import { RegularExpressions } from "../util/validation";
import { UserContextValue, useUser } from "../../stores/UserStore";

import "./Login.css";
import { text } from "../util/texts";

const emailRegExp: RegExp = RegularExpressions.email;


const Login = () => {
  const navigate = useNavigate()
  const { loginUser, loginStatus, currentUser } = useUser() as UserContextValue;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const screenSize = useBreakpointValue({
    base: "small",
    md: "medium",
    lg: "large",
  });

  useEffect(() => {
    if (currentUser !== undefined){
      navigate("/");
    }
      
  }, [currentUser, navigate])

  const validateEmail = (email: string) => {
    // if email is empty
    if (!email) {
      return "Email is required";
    }
    const isEmailValid = emailRegExp.test(email);

    // if email is invalid
    if (!isEmailValid) {
      return "Invalid email";
    }
  };

  const validatePassword = (password: string) => {
    // if password is empty
    if (!password) {
      return "Password is reqired";
    }
  };

  return (
    <Container className={`${screenSize === "large" ? "desktop-login-container" : ""}`} mt={100} {...borders.cardRaised}>
      <Flex justifyContent="stretch">
        {screenSize === "large" && (
          <Container color={"white"} fontSize="36px" pl="20%" pt="10%" w="50%" h="300px" ml="0"          >
            {text("header.icon", "connect")}
          </Container>
        )}
        <Container
          style={{
            margin: "auto auto",
            width: screenSize !== "large" ? "100%" : "40%",
          }}
        >
          {/* <Container w="50%" h="300px" p="auto auto" border="1px dashed red"> */}
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values) => {
              await loginUser(values.email, values.password);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }: { field: any; form: any }) => (
                    <>
                      <FormControl
                        isRequired={true}
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel>Email</FormLabel>
                        <Input {...field} placeholder="Email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                </Field>
                <Field name="password" validate={validatePassword}>
                  {({ field }: { field: any; form: any }) => (
                    <React.Fragment>
                      <FormControl isRequired={true}>
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            {...field}
                            placeholder="Password"
                          />
                          <InputRightElement width="4.5rem">
                            {showPassword
                              ? <Icon as={AiFillEyeInvisible} onClick={() => setShowPassword(false)}
                              />
                              : <Icon as={AiFillEye} onClick={() => setShowPassword(true)} />
                            }
                          </InputRightElement>
                        </InputGroup>
                        {/* <FormErrorMessage>
                          <Flex flexDirection={"column"}>
                            {form.errors.password && (
                              <Heading as="h3" size={"sm"}>
                                {form.errors.password} Your password must be,{" "}
                              </Heading>
                            )}
                            <PasswordError password={field.value} />
                          </Flex>
                        </FormErrorMessage> */}
                      </FormControl>
                    </React.Fragment>
                  )}
                </Field>
                {(loginStatus !== "200" && loginStatus !== "") &&
                  <Text fontSize="sm" color="tomato">Invalid email or password</Text>
                }
                <Flex justifyContent="center">
                  <Button mt={4} colorScheme="teal" isLoading={isSubmitting} type="submit"                  >
                    Login
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Container>
      </Flex>
    </Container>
  );
};

export default Login;
