import { Box, Button, HStack, SimpleGrid } from "@chakra-ui/react";
import { IJobPostWithStats } from "../../../types/job-post";
import { text } from "../../util/texts";
import JobPostItem from "./JobPostItem";
import { HiPencil, HiPlusCircle } from "react-icons/hi";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface JobPostsPageProps {
  jobPosts: IJobPostWithStats[];
}

const JobPostsPage = ({ jobPosts }: JobPostsPageProps) => {
  const activeJobPosts = (jobPosts as any)?.filter(
    (item: any) => item.status === "active"
  );
  const unPublishedJobPosts = (jobPosts as any)?.filter(
    (item: any) => item.status === "unpublished"
  );
  const closedJobPosts = (jobPosts as any)?.filter(
    (item: any) => item.status === "closed"
  );

  const navigate = useNavigate();
  const onEdit = (jobPostId: string) => {
    navigate(`/jobPost/${jobPostId}`);
  };
  const renderSection = (title: string, jobPosts: IJobPostWithStats[]) => {
    return (
      <Box mb={7}>
        {text("section.title", title)}
        <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} gap={3}>
          {jobPosts.length === 0 && text("body.regular", "None")}
          {jobPosts.map((jobPost, idx) => {
            const onClose = () =>
              console.log("post clicked", jobPost.jobPost.id);
            return (
               <JobPostItem key={idx} jobPostWithStats={jobPost} onEdit={onEdit} onClose={onClose}/>
            );
          })}
        </SimpleGrid>
      </Box>
    );
  };

  return (
    <Box px={20} py={15}>
      {text("page.title", `Job Posts`, 2)}
      {/* {text('page.instruction', 'Your active, unpublished, and archived job posts.', 3)} */}
      <HStack gap={3} mb={5}>
        <Button
          variant="link"
          leftIcon={<HiPlusCircle />}
          colorScheme="green"
          as={RouterLink}
          to={"/jobPost/selectJob"}
        >
          Post A New Job
        </Button>
        <Button
          variant="link"
          leftIcon={<HiPencil />}
          colorScheme="blue"
          as={RouterLink}
          to={"/companyProfile"}
        >
          Company Profile
        </Button>
        <Button
        variant="link"
        leftIcon={<HiPencil />}
        colorScheme="yellow"
        as={RouterLink}
        to={"/profile"}
        >
          Edit Personal Profile
        </Button>
      </HStack>
      {renderSection("Active", activeJobPosts)}
      {renderSection("Unpublished", unPublishedJobPosts)}
      {renderSection("Closed", closedJobPosts)}
    </Box>
  );
};

export default JobPostsPage;
