import { createContext, useContext, useState } from "react";
// import _ from "lodash";
import { api } from "../api";
import { IRecruiter, IUser, OnboardingStatus } from "../types/user";
import { AxiosError } from "axios";
import { ApiInstance } from "../api/ApiInstance";
import { result } from "lodash";

export interface IUpdateProfile {
  first_name: string;
	last_name: string;
  headline: string;
	email: string;
  location: string;
  summary: string;
  linkedIn: string;
  resume: string;
  locationType: string;
  workType: string;
  salary: number;
	onboardingStage: OnboardingStatus;
};

export type UserContextValue = {
  demoUsers: any;
  jobSeekersForNavigator: any;
  currentUser: IUser | IRecruiter | undefined;
  isFetchingCurrentUser: boolean;
  isFetchingDemoUsers: boolean;
  isFetchingJobSeekersForNavigator: boolean;
  loginStatus: string;
  passwordResetStatus: string;
  profileUpdateStatus: string;
  fetchJobSeekersForNavigator: () => Promise<any>;
  fetchDemoUsers: () => Promise<any>;
  fetchCurrentUser: () => Promise<void>;
  loginUser: (email: string, password?: string) => void;
  logoutUser: () => Promise<boolean>;
  resetPassword: (
    oldPassword: string,
    newpassword: string,
    newPasswordConfirmation: string
  ) => Promise<string>;
  updateOnboardingStatus: (statusToUpdate: string) => Promise<string>;
  updateProfile:(values: IUpdateProfile) => Promise<string>;
  updateCompany:(values: IUpdateProfile) => Promise<string>;
  saveMatchActivity:(values: any) => Promise<string>;
  uploadUserFile:(files: any) => Promise<any>;
};

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const useUser = () => useContext(UserContext);

// eslint-disable-next-line react/function-component-definition
const UserProvider = ({ children }: any) => {
  const [jobSeekersForNavigator, setJobSeekersForNavigator] = useState(undefined);
  const [demoUsers, setDemoUsers] = useState(undefined);
  const [currentUser, setCurrentUser] = useState<any>();
  const [isFetchingDemoUsers, setIsFetchingDemoUsers] = useState(true);
  const [isFetchingJobSeekersForNavigator, setIsFetchingJobSeekersForNavigator] = useState(true);
  const [isFetchingCurrentUser, setIsFetchingUser] = useState(true);
  const [loginStatus, setLoginStatus] = useState("");
  const [passwordResetStatus, setPasswordResetStatus] = useState("");
  const [profileUpdateStatus, setProfileUpdateStatus] = useState("");

  /* eslint-disable consistent-return */
  const fetchDemoUsers = async (): Promise<any> => {
    console.log("fetchDemoUsers", demoUsers);

    if (demoUsers !== undefined) {
      return;
    }

    setIsFetchingDemoUsers(true);

    try {
      const res = await api.get("/demoUsers");
      console.log("fetchDemoUsers", res.data);
      setDemoUsers(res.data);
      setIsFetchingDemoUsers(false);
    } catch (error) {
      setIsFetchingDemoUsers(false);
    }
  };

  /* eslint-disable consistent-return */
  const fetchJobSeekersForNavigator = async (): Promise<any> => {
    if (jobSeekersForNavigator !== undefined) {
      return;
    }

    setIsFetchingJobSeekersForNavigator(true);

    try {
      const res = await api.get("/custom/users/jobSeekersForNavigator");
      setJobSeekersForNavigator(res.data);
      setIsFetchingJobSeekersForNavigator(false);
    } catch (error) {
      setIsFetchingJobSeekersForNavigator(false);
    }
  };

  const fetchCurrentUser = async (): Promise<void> => {
    try {
      setIsFetchingUser(true);

      const res = await api.get("/custom/users/me");
      const data = res.data;
      if (data) {
        const userData = {
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          headline: data.headline,
          email: data.email,
          avatar: data.avatar,
          avatarUrl: data.avatarUrl,
          location: data.location,
          summary: data.summary,
          linkedIn: data.linkedIn,
          resume: data.resume,
          company: data.company,
          userRole: data.userRole,
          status: data.status,
          locationType: data.locationType,
          workType: data.workType,
          salary: data.salary,
          customStatus: data.customStatus,
          onboardingStage: data.onboardingStage
        };
        setCurrentUser(userData);
      }
      setIsFetchingUser(false);
    } catch (error) {
      //If user is unauthenticated, check if refresh token is available
      //const refresh_token = localStorage.getItem("refreshToken");
      //if (refresh_token) await refreshToken(refresh_token, "json")
      //else {
      setCurrentUser(undefined)
      //}
      setIsFetchingUser(false);
    }
  };

  const loginUser = async (email: string, password: string = "password") => {
    try {
      setIsFetchingUser(true);
      const res = await api.post(`/auth/login`, { email, password });
      //If response is 200 (Successfull) then, set token and fetch current loggedin user 
      localStorage.setItem("authorizationToken", res.data.data.access_token);
      localStorage.setItem("refreshToken", res.data.data.refresh_token);
      fetchCurrentUser();
      //setIsFetchingUser(false);
      //Find a better implementation
      setLoginStatus("200");
    } catch (error: any) {
      setIsFetchingUser(false);
      const axiosError: AxiosError = error;
      if (axiosError.code) {
        setLoginStatus(axiosError.code);
      }
    }
  };

  const resetPassword = async (
    oldPassword: string,
    password: string,
    passwordConfirm: string
  ):Promise<string> => {
    try {
      const response = await api.patch(`/custom/users/password/`, {
        oldPassword,
        password,
        passwordConfirm,
      });
     
      if(!response) {
        setPasswordResetStatus("ValidationError: oldPassword incorrect");
        return 'failure';
      }

      if(!response.data) {
        const message = response?.errors[0]?.message || 'Password reset failed';
        setPasswordResetStatus(message);
        return 'failure';
      }

      const resetStatus = response.data?.data === true ? 'success' : 'failure';
      setPasswordResetStatus(resetStatus);
      return resetStatus;
    } catch (error: any) {
     
      const axiosError: AxiosError = error;
      const status: { message: string } = JSON.parse(
        axiosError.request?.response
      );
      console.log('resetPassword error', error);
      if (axiosError.code) {
        setPasswordResetStatus(status.message);
      }
      return 'failure';
    }
  };

  const logoutUser = async (): Promise<boolean> => {
    const refresh_token = localStorage.getItem("refreshToken")

    if (refresh_token) {
      try {
        setIsFetchingUser(true);
        await api.post(`/auth/logout`, {
          refresh_token,
        });
        setCurrentUser(undefined);
        setIsFetchingUser(false);

        localStorage.removeItem("authorizationToken");
        localStorage.removeItem("refreshToken");

        return true
      } catch (error) {
        localStorage.removeItem("authorizationToken");
        localStorage.removeItem("refreshToken");
        setIsFetchingUser(false);
        setCurrentUser(undefined);

        return false;
      }
    };
    return false;
  }

  const updateOnboardingStatus = async (statusToUpdate:string):Promise<string> => {
    try {
      const payload:any = {}

      switch (statusToUpdate) {
        case 'active':
          payload.customStatus = 'active';
          break;
        case OnboardingStatus.JobSetup:
          payload.onboardingStage = OnboardingStatus.JobSetup;
          break;
        case OnboardingStatus.ProductTour:
          payload.onboardingStage = OnboardingStatus.ProductTour;
          break;  
        case OnboardingStatus.Complete:
          payload.onboardingStage = OnboardingStatus.Complete;
          break;
      }

      await api.patch('/custom/users/onboarding', payload);

      await fetchCurrentUser();
      return 'success';
    } catch (err: any) {
      console.log('updateOnboardingStatus err', err);
      const axiosError: AxiosError = err;
      const message = axiosError.message ?? axiosError.code
      return message || 'error';
    }
  }

  const updateProfile = async (formValues: IUpdateProfile):Promise<string> => {
    try {
      const response = await api.patch(`/custom/users/me/`, formValues);
      
      if(!response) {
        setProfileUpdateStatus("Profile update failed");
        return 'failure';
      }

      if(!response.data) {
        const message = response?.errors[0]?.message || 'Profile update failed';
        setProfileUpdateStatus(message);
        return 'failure';
      }

      const resetStatus = response.data?.data === true ? 'success' : 'failure';
      setProfileUpdateStatus(resetStatus);
      return resetStatus;
    } catch (error:any){
      const axiosError: AxiosError = error;
      const status: { message: string } = JSON.parse(
        axiosError.request?.response
      );
      if (axiosError.code) {
        setProfileUpdateStatus(status.message);
      }
      return 'failure';
    }
  };

  const updateCompany = async (formValues: any):Promise<string> => {
    formValues.userId = currentUser?.id;
    const user = currentUser as any;

    try{
      const response = await api.patch(`/items/companies/${user.company.id}`, formValues);
      if(response?.data?.data) {
        setProfileUpdateStatus('success');
        return 'success';
      }
      setProfileUpdateStatus("Profile update failed");
      return 'failure';
    } catch (error){
      console.log('update company error', error);
      return 'failure';
    }
  };

  const saveMatchActivity = async(matchActivity: any): Promise<string> => {

    try {
      const result = await api.post("items/match_activities", matchActivity);
      if (result.status === 200) {
        return 'success';
      } else {
        return 'failure';
      }
    } catch (err) {
      return 'failure';
    }
  }

  const uploadUserFile = async (files: any) => {
      if (files) {
        console.log("Uploading file...");
    
        const formData = new FormData();
        formData.append("files", files);

        const result = await api.post("files/", formData);
        const fileId = result.data.data.id
        return fileId;        
      }
  };



  /* eslint-enable consistent-return */

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <UserContext.Provider
      value={{
        demoUsers,
        jobSeekersForNavigator,
        currentUser,
        isFetchingCurrentUser,
        isFetchingDemoUsers,
        isFetchingJobSeekersForNavigator,
        loginStatus,
        passwordResetStatus,
        profileUpdateStatus,
        fetchDemoUsers,
        fetchJobSeekersForNavigator,
        fetchCurrentUser,
        loginUser,
        resetPassword,
        logoutUser,
        updateOnboardingStatus,
        updateProfile,
        updateCompany,
        saveMatchActivity,
        uploadUserFile
      }}
    >
      {children}
    </UserContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default UserProvider;
