import { Box, Center, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import RecruiterReportsPage from "../../reports/RecruiterReportsPage";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";

const RecruiterReportsContainer = () => {
  const { currentUser } = useUser() as UserContextValue;
  const { summaryMatches, isFetchingSummaryMatches, fetchSummaryMatches} = useMatch() as MatchContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchSummaryMatches();
    }
  }, []);

  if (isFetchingSummaryMatches) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }

  if (!currentUser || !summaryMatches) {
    return null;
  }

  console.log(summaryMatches)

  return (
    <Box>
      <RecruiterReportsPage summary={summaryMatches} />
    </Box>
  );
};

export default RecruiterReportsContainer;
