// import config from '../config';
import { ApiInstance } from './ApiInstance';
//import wrapWithMock from './mockApi';

let host = window.location.origin;
host = host.replace(':3000', ':8055'); // Local dev
const backendUrl = `${host}`;
//const backendUrl = '';

export const api: ApiInstance = new ApiInstance(backendUrl);

//wrapWithMock(api.api);
