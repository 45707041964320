import { ICompany } from "./company";
import { IEvidence } from "./evidence";
import { ICompetency, IJob } from "./job";
import { IJobPost } from "./job-post";
import { MatchOutcome, ProficiencyLevel } from "./proficiency-level";
import { IJobRating } from "./rating";
import { IUser, UserRole } from "./user";

// === Generated by matching engine on demand ===

export interface IJobMatchCompetency {
  competencyId: string;

  rating: ProficiencyLevel;
  expectation: ProficiencyLevel;
  matchOutcome: MatchOutcome;

  // Joins
  competency: ICompetency;
}

export interface IJobMatch {
  id?: string;

  userId: string;  
  jobId: string;
  jobRatingId: string;

  companyId: string;
  jobPostId: string;

  // categories: ICategoryMatch[];
  competencies: IJobMatchCompetency[];
  matchPercentage: number;

  userStatus?: IJobMatchActivity;
  recruiterStatus?: IJobMatchActivity;

  // Joins
  user: IUser;
  company: ICompany;
  job: IJob;
  evidences: IEvidence[];
  jobRating: IJobRating;
  jobPost: IJobPost;
}

// === Persisted activity / status records

export enum JobMatchStatus {
  NeedsReview = 'needs-review',
  Viewed = 'viewed',
  Saved = 'saved',
  Passed = 'passed',
  CandidateApplied = 'applied',
  RecruiterInvited = 'invited',  
}

export const JOB_SEEKER_JOB_MATCH_STATUSES: JobMatchStatus[] = [
  JobMatchStatus.NeedsReview,
  JobMatchStatus.Viewed,
  JobMatchStatus.Saved,
  JobMatchStatus.Passed,
  JobMatchStatus.CandidateApplied,
];

export const RECRUITER_JOB_MATCH_STATUSES: JobMatchStatus[] = [
  JobMatchStatus.NeedsReview,
  JobMatchStatus.Viewed,
  JobMatchStatus.Saved,
  JobMatchStatus.Passed,
  JobMatchStatus.RecruiterInvited,
]

export interface IJobMatchActivity {
  userId: string;
  jobId: string;
  jobRatingId: string;
  jobPostId: string;
  matchPercentageSnapshot: number;
  status: JobMatchStatus;

  activityByRole: UserRole;
  activityById: string;  
  activityDate: string;
}