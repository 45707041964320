import { Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import RecruiterHomePage from "../../components/recruiter/RecruiterHomePage";
import { JobPostContextValue, useJobPost } from "../../stores/JobPostProvider";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { IRecruiter, OnboardingStatus } from "../../types/user";
import { RecruiterHomeSteps } from "../match/RecruiterOnboardingSteps";

const RecruiterHomeContainer = () => {
  const { currentUser } = useUser() as UserContextValue;

  const { summaryMatches, isFetchingSummaryMatches, fetchSummaryMatches} = useMatch() as MatchContextValue;
  const { jobPosts, isFetchingJobPosts, fetchJobPosts } = useJobPost() as JobPostContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchSummaryMatches();
      fetchJobPosts();
    }
  }, []);

  if (isFetchingSummaryMatches || isFetchingJobPosts) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }

  if (!currentUser || !summaryMatches) {
    return null;
  }

  const onboardingSteps = RecruiterHomeSteps;
  const showOnboarding = currentUser.onboardingStage === OnboardingStatus.ProductTour;

  return (
    <RecruiterHomePage
      user={currentUser as IRecruiter}
      summary={summaryMatches}
      onboardingSteps={onboardingSteps}
      showOnboarding={showOnboarding}
    />
  );
};

export default RecruiterHomeContainer;
