import {
  VStack,
  HStack,
  Text,
  IconButton,
  Spacer,
  Box,
  Link,
  useToast,
  ListIcon,
  List,
  Popover,
  PopoverTrigger,
  Button,
  PopoverBody,
  PopoverArrow,
  PopoverContent,
  PopoverCloseButton,
  Portal,
  PopoverHeader,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { MdDescription } from "react-icons/md";
import { BiLinkExternal } from "react-icons/bi";
import { IEvidence } from "../../types/evidence";
import { EditIcon, DownloadIcon } from "@chakra-ui/icons";
import { ApiInstance } from "../../api/ApiInstance";

interface EvidenceProps {
  evidences: IEvidence[];
  openEvidence?: (evidence?: IEvidence) => void;  
  onEvidenceDelete?: (evidienceId: string) => void;
}

const Evidence = ({
  evidences,
  onEvidenceDelete,
  openEvidence,
}: EvidenceProps) => {
  const toast = useToast();

  if (evidences && evidences.length === 0) {
    return <h2>No Evidences</h2>;
  }

  return (
    <Box>
      <VStack
        borderColor="gray.100"
        p="2"
        borderRadius="lg"
        w="100"
        alignItems="stretch"
        maxW={{ base: "90w", sm: "80vw", lg: "35vw", xl: "40vw" }}
      >
        {evidences.map((evidence) => (
          <Box key={`list-${evidence.id}`}>
            <HStack>
              <List>
                <HStack>
                <Box>
                  { evidence.fileUpload ? (
                    <HStack>
                      <ListIcon as={DownloadIcon} />
                      <Link
                        href={`${ApiInstance.assetsUrl}/${evidence.fileUpload.id || evidence.fileUpload}?access_token=${localStorage.getItem("authorizationToken")}&download`} target="_blank" download={evidence.fileUpload.filename_download || ''}
                        colorScheme="blue">
                        <Text fontSize="lg">{evidence.title}</Text>
                      </Link>
                    </HStack>
                  ) : (
                    <HStack>
                      <ListIcon as={BiLinkExternal} />
                      <Link colorScheme="blue" href={evidence.link} isExternal>
                        <Text fontSize="lg">{evidence.title}</Text>
                      </Link>
                    </HStack>
                  )}
                </Box>
                </HStack>
              </List>
              <Spacer />
              <Box>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        icon={<MdDescription color="green" />}
                        variant="ghost"
                       aria-label={""} />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                      <PopoverHeader>Evidence Description</PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          {evidence.description}
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  </Box>
              {openEvidence && (
                <IconButton
                  icon={<EditIcon />}
                  colorScheme="blue"
                  aria-label={"edit evidence"}
                  variant="ghost"
                  onClick={() => openEvidence(evidence)}
                />
              )}              
              {onEvidenceDelete && (
                <IconButton
                  icon={<FaTrash color="red" />}
                  variant="ghost"
                  onClick={() => {
                    onEvidenceDelete(evidence.id);
                    toast({
                      title: "Evidence Deleted.",
                      description:
                        "This Evidence has been deleted from your Evidences.",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                  aria-label={""}
                />               
              )}
            </HStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Evidence;
