import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { borders } from "../../../styles/common";
import { IJobPostWithStats } from "../../../types/job-post";
import { text } from "../../util/texts";
import { locationTag, salaryTag, workTypeTag } from "../../util/tags";
import Stat from "../../common/Stat";
import { HiPencil } from "react-icons/hi";
import { FaTimesCircle } from "react-icons/fa";
import { MdUnpublished } from "react-icons/md"
import moment from "moment";
import React from "react";
import {
  JobPostContextValue,
  useJobPost,
} from "../../../stores/JobPostProvider";
import { JobContextValue, useJob } from "../../../stores/JobProvider";

interface JobPostItemProps {
  jobPostWithStats: IJobPostWithStats;
  onEdit: (jobPostId: string) => void;
  onClose: () => void;
}

let dollarUSLocale = Intl.NumberFormat("en-US");

const JobPostItem = ({
  jobPostWithStats,
  onEdit,
  onClose,
}: JobPostItemProps) => {
  const { closeJobPost, unpublishedJobPost, activeJobPost } = useJobPost() as JobPostContextValue;
  const { fetchJob } = useJob() as JobContextValue;
  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();
  const cancelRef = React.useRef(null);
  const { stats } = jobPostWithStats;
  const jobPost = jobPostWithStats as any;

  const closeJob = () => {
    if (jobPost.status === 'closed') {
      activeJobPost(jobPost.id)
    } else {
      closeJobPost(jobPost.id);
    }
    closeModal();
  };
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeModal}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Close Job Post
            </AlertDialogHeader>

            <AlertDialogBody>
              {jobPost.status === 'active' ? "Are you sure you want to close this job post?" : "Are you sure you want to open this job post?"}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeModal}>
                Cancel
              </Button>
              <Button colorScheme={`${jobPost.status === 'closed' ? 'teal' : 'red'}`} onClick={closeJob} ml={3}>
              {`${jobPost.status === 'closed' ? 'Open' : 'Close'}`}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box key={jobPost.id} {...borders.cardRaised} px={5} py={5}>
        {text(
          "body.caption",
          `Posted ${moment(jobPost?.postedDate).fromNow()}`,
          0
        )}
        {text("card.title", jobPost?.companyJobTitle)}
        {text("body.regular", jobPost?.location, 2)}
        {/* {text('body.regular', jobPost?.companyJobDescription, 3)} */}
        <HStack gap={2} mb={4}>
          {salaryTag(dollarUSLocale.format(jobPost?.salary))}
          {workTypeTag(jobPost?.workType)}
          {locationTag(jobPost?.locationType)}
        </HStack>
        <Flex gap={7} mb={3} {...borders.card}>
          <Stat
            name="Matches"
            value={stats?.matches?.toString()}
            link="/"
            linkLabel="View"
            size="sm"
          />
          <Stat
            name="Applications"
            value={stats?.applied.toString()}
            link="/"
            linkLabel="View"
            size="sm"
          />
          <Stat
            name="Invites"
            value={stats?.invited.toString()}
            link="/"
            linkLabel="View"
            size="sm"
          />
        </Flex>
        <HStack gap={3}>
          <Button
            variant="link"
            leftIcon={<HiPencil />}
            colorScheme="blue"
            onClick={() => {
              fetchJob(`${jobPost.id}`);
              onEdit(`${jobPost.id}`);
            }}
          >
            Edit Job Post
          </Button>
          {jobPost.status !== "closed" && <Button
          variant="link"
          leftIcon={<MdUnpublished />}
          colorScheme="yellow"
          onClick={() => {
            if (jobPost.status === "active") {
              unpublishedJobPost(jobPost.id)
            } else {
              activeJobPost(jobPost.id)
            }
          }}
          >
            {jobPost.status === "active" ? "Unpublish" : jobPost.status === "unpublished" ? "Publish" : "Publish"}
          </Button>}
          <Button
            variant="link"
            leftIcon={<FaTimesCircle />}
            colorScheme={`${jobPost.status === 'closed' ? "teal" : "orange"}`}
            onClick={onOpen}
          >
            {jobPost.status === "closed" ? "Open Job Post" : "Close Job Post"}
          </Button>
        </HStack>
      </Box>
    </>
  );
};

export default JobPostItem;
