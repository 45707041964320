import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CompanyProfile from "../components/recruiter/profile/CompanyProfile";
import { text } from "../components/util/texts";
import { UserContextValue, useUser } from "../stores/UserStore";
import { IRecruiter, OnboardingStatus } from "../types/user";

const CompanyProfileContainer = () => {
  const { currentUser, fetchCurrentUser, updateOnboardingStatus, updateCompany } = useUser() as UserContextValue;
  const navigate = useNavigate();
  
  if (!currentUser) {
    return null;
  }

  const onSubmit = async (values: any) => {
    if (!currentUser) {
      return null;
    }

    const updateResult =  await updateCompany(values);
    if (updateResult === 'success'){
      await fetchCurrentUser();
    }

    if(currentUser?.onboardingStage === OnboardingStatus.ProfileSetup || 
      currentUser?.onboardingStage === OnboardingStatus.JobSetup){
      const result = await updateOnboardingStatus(OnboardingStatus.JobSetup);
      if(result === 'success'){
        return navigate('/');
      }
      return navigate('/companyProfile');
    }

    navigate('/');
  };

  switch (currentUser?.userRole) {
    case 'recruiter': {
      const recruiter = currentUser as IRecruiter;
      return (
        <Box px={20} py={15}>
          {text("page.title", "Company Profile")}
          <CompanyProfile company={recruiter.company} onSubmit={onSubmit} />
        </Box>
      )
    }
    default:
      return null;
    }
   
};

export default CompanyProfileContainer;