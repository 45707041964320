import { Box } from "@chakra-ui/react";
import { IJob } from "../../../types/job";
import { IJobRating } from "../../../types/rating";
import { text } from "../../util/texts";
import JobRater from "../../shared/JobRater";
import { UserRole } from "../../../types/user";
import { CompetencyRatingMap } from "../../shared/CategoryRater";

interface UserJobProfileProps {
  job: IJob;
  jobRating?: IJobRating;
  onSubmit: (updatedValues: CompetencyRatingMap) => void;
}

const UserJobProfileManage = ({
  job,
  jobRating,
  onSubmit,
}: UserJobProfileProps) => {
  return (
    <Box>
      {text("page.title", job?.title)}
      {text(
        "page.instruction",
        "Choose the most accurate description for yourself for each competency.",
        5
      )}
      <JobRater
        job={job}
        jobRating={jobRating}
        onSubmit={onSubmit}
        ratingRole={UserRole.User}
      />
    </Box>
  );
};

export default UserJobProfileManage;
