export const JobSeekerMatchSteps = [
  {
    target: ".m-zero-step",
    content: "Welcome to the Match Page! This is where you can view available job listings and see how strong of a match you are.",
    title: "Match Page",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".m-first-step",
    content: "Here you will see your matches from job posts at various companies based on the applied filters.",
    title: "Your Matches",
    placement: "right-start",
    disableScrolling: true
  },
  {
    target: ".m-second-step",
    content: "You can filter your matches using these filter categories.",
    title: "Match Filter",
    placement: "left-start",
    disableScrolling: true
  },
  {
    target: ".m-third-step",
    content: "Details of the selected match are shown here.",
    title: "Match Detail",
    placement: "top-end",
    disableScrolling: true
  },
  {
    target: ".m-match-status-step",
    content: "Each match has a status.",
    title: "Match Status",
    placement: "bottom",
    disableScrolling: true
  },  
  {
    target: ".m-fourth-step",
    content: "The available actions for each match depend on the current status of the match.",
    title: "Action Buttons",
    disableScrolling: true
  },
  {
    target: ".m-match-percentage-step",
    content: "The match percentage shows the overall strength of the match based on the skills you asserted and the requirements set by the employer.",
    title: "Match Percentage",
    placement: "left",
    disableScrolling: true
  },
  {
    target: ".m-fifth-step",
    content: "As with the questionnaire, skills are grouped into categories.",
    title: "Competency Group",
    placement: "right",
    disableScrolling: true
  },
  {
    target: ".m-sixth-step",
    content: "A Competency card shows your proficiency level for each skill (Knowledge, Skill, Ability) compared to the job expectations set by the employer at this company.",
    title: "Competency",
    placement: "right",
    spotlightClicks: true,
  }
]

export const JobSeekerHomeSteps = [
  {
    target: ".h-zero-step",
    content: "Welcome to Connect!",
    title: "Home Page",
    disableBeacon: true,
    placement: "bottom",
    disableScrolling: true
  },
  {
    target: ".h-first-step",
    content: "The dashboard provides quick summary of your latest job matches, with direct links to each job listing.",
    title: "Quick Summary",
    placement: "right-end",
  },
  {
    target: ".h-second-step",
    content: "Newly identified job matches that need your review are highlighted in this section.",
    title: "Needs Review",
  },
  {
    target: ".h-third-step",
    content: "The header provides links you can use to navigate this app. You can click on your avatar to get additional menu items. To get started, click on the 'My Job Skills' link to explore that page.",
    title: "The Header",
    placement: "right-end",
  },       
]