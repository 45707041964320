import {
  Box,
  Center,
  Divider,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  CloseButton,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
  SimpleGrid,
  Button,
  Flex,
  ButtonGroup,
  Spacer,
} from "@chakra-ui/react";
import { IJobMatch } from "../../types/match";
import { useCallback, ChangeEvent, useState, useRef, useEffect, ReactNode } from "react";
import { scrolls } from "../../styles/common";
import { text } from "../util/texts";
import { debounce, matches } from "lodash";
import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useInView } from "framer-motion";

interface MatchItemsListProps {
  jobMatches: IJobMatch[];
  totalMatches: number;
  selectedMatch?: IJobMatch;
  isFetchingMatches: boolean;
  onRenderMatchItem: (jobMatch: IJobMatch, onSelect: () => void) => ReactNode,
  onRenderMatchDetail: (jobMatch: IJobMatch) => ReactNode;  
  onSelectJobMatch: (userId: string, jobPostId: string) => void;
  onSearch: (searchTerm: string) => void;
  onSearchClear: () => void;
  onFetchMore: () => void;
  page: number;
  onMovePage: (pageNumber: number) => void; 
}

const MatchItemsList = ({
  jobMatches,
  totalMatches,
  selectedMatch,
  isFetchingMatches,
  onSelectJobMatch,
  onSearch,
  onSearchClear,
  onFetchMore,
  onRenderMatchDetail,
  onRenderMatchItem,
  page,
  onMovePage
}: MatchItemsListProps) => {
  
  const screenSize = useBreakpointValue({
    base: "small",
    md: "medium",
    lg: "large",
  });

  // Search

  const searchInputRef:any = useRef(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  // const[selectedProfileMatch, setSelectedProfileMatch] = useState<IJobMatch>();

  const handleDebounceFn = (searchKey: string) => {
    console.log('search term', searchKey);
    setSearchTerm(searchKey);
    onSearch(searchKey);
  }
  
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);    

  const onUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value !== searchTerm) {        
      // Added debounce to limit api call on every key stroke
      debounceFn(e.currentTarget.value)
    }
  };

    const onClearSearchTerm = () => {
      if (searchInputRef.current !== null) {
        searchInputRef.current.value = '';
      }
      setSearchTerm('');
      onSearchClear();
    }

    // Fetching
    const toast = useToast();

    const bottomOfListRef = useRef(null);
    const isInView = useInView(bottomOfListRef);
    useEffect(() => {
      if (isInView) {
        // toast({
        //   title: 'Loading more',
        //   position: 'bottom-left',
        //   variant: 'subtle',
        //   duration: 700,
        // })
        onFetchMore();
      }
    }, [isInView]);

    // Render matches

    const renderPages = () => {
      if (jobMatches === undefined) {
        return (
          <Box>
            <Text fontSize="sm">Loading pages ...</Text>
          </Box>
        );
      }

      const pageSize = 10;
      const isMultiplePages = totalMatches > pageSize;

      const isFirstPage = page === 1;
      const isLastPage = page === Math.ceil(totalMatches / pageSize);      

      const startCount = isFirstPage ? 1 : ((page-1) * pageSize) + 1;
      const endCount = isLastPage ? totalMatches : ((page-1) * pageSize) + jobMatches.length;
    

      return (
        <Box>
            <SimpleGrid columns={{ base: 1, md: 1}}>
              {totalMatches === 0 ? (
                
                text("body.regular", `No Matches`)
              ) : (
                <Flex minWidth='max-content' alignItems='center' gap='2'>
                <Box>
                {text("body.regular", `Results ${startCount}-${endCount} of ${totalMatches}`)}
                </Box>
                <Spacer />
                {isMultiplePages && (
                  <ButtonGroup>
                    <Button size={{ base: 'xs', md: 'sm' }} onClick={() => onMovePage(page - 1)} isDisabled={isFirstPage} colorScheme="blue" mr={1}>Prev</Button>
                    <Button size={{ base: 'xs', md: 'sm' }} onClick={() => onMovePage(page + 1)} isDisabled={isLastPage} colorScheme="blue">Next</Button>
                  </ButtonGroup>
                )}                
              </Flex>
              

              )}                                          
            </SimpleGrid>
        </Box>
        )
      }

    return (
      <Box h="100%" py={5} backgroundColor={"#F1F7FE"}>
        <Box px={4} mb={3}>
          <Box mb={2} className="m-first-step">
            {text("section.title", `Job Matches`, 0)}       
          </Box>
          <InputGroup mb={2}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.500" />}
            />
            <Input type="text" placeholder="Search" backgroundColor="white" onChange={onUpdate} ref={searchInputRef} />
            <InputRightElement>
              <CloseButton onClick={onClearSearchTerm}/>
            </InputRightElement>
          </InputGroup>
          {/* {!isFetchingMatches && text("body.regular", `Showing ${jobMatches.length} of ${totalMatches} matches`)} */}
          {!isFetchingMatches && renderPages()}
        </Box>
        <Box {...scrolls.yOverflowSubtle}>
          {isFetchingMatches && (
            <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          )}
          {!isFetchingMatches && jobMatches.map((jobMatch, idx) => {
            const onSelect = () => {
              // setSelectedProfileMatch(jobMatch);
              onSelectJobMatch(jobMatch.userId, jobMatch.jobPostId);
            };
            const isActive =
              selectedMatch?.userId === jobMatch.userId &&
              selectedMatch?.jobPostId === jobMatch.jobPostId;

            return (
              <Box
                key={idx}
                backgroundColor={isActive ? "#DBE5F5" : "#F1F7FE"}
                _hover={{
                  backgroundColor: "#DBE5F5",
                }}
                borderBottom={"1px"}
                borderColor={"gray.300"}
              >
                {onRenderMatchItem(jobMatch, onSelect)}
                {isActive && (screenSize === "small" || screenSize === "medium") && (
                  <Box backgroundColor="white">
                    {onRenderMatchDetail(jobMatch)}
                  </Box>                  
                )}
                <Divider />
              </Box>
            );
          })}
        </Box>
        <br />
        <br />
        <Box pb={6} ref={bottomOfListRef}></Box>
      </Box>
    );
}

export default MatchItemsList;