import {
  Avatar,
  Box,
  Grid,
  GridItem,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../../types/match";
import MatchPercentage from "../../shared/MatchPercentage";
import { StarIcon } from "@chakra-ui/icons";
import { text } from "../../util/texts";
import {
  locationTag,
  statusTag,
  workTypeTag,
} from "../../util/tags";
import { getFullName, getAvatarUrl } from "../../../utils/users";

interface ProfileMatchItemProps {
  jobMatch: IJobMatch;
  onClick: () => void;
  isActive?: boolean;
  tagVariant?: string;
}

let dollarUSLocale = Intl.NumberFormat("en-US");

const ProfileMatchItem = ({
  jobMatch,
  onClick,
  isActive,
  tagVariant = "subtle",
}: ProfileMatchItemProps) => {
  const tagOptions = { size: "sm", variant: tagVariant };
  const renderStatus = () => {
    return (
      <HStack mb={2}>
        {jobMatch.recruiterStatus &&
          statusTag(jobMatch.recruiterStatus.status, {
            colorScheme: "teal",
            ...tagOptions,
          })}
        {jobMatch.userStatus &&
          (jobMatch.userStatus.status as JobMatchStatus) ===
          JobMatchStatus.CandidateApplied &&
          statusTag(jobMatch.userStatus.status, {
            colorScheme: "purple",
            ...tagOptions,
          })}
      </HStack>
    );
  };

  const color = jobMatch.matchPercentage > 80 ? "green" : "orange";
  const starIconSize = { base: 4, md: 5 };

  const user = jobMatch.user;
  const jobSeeker = jobMatch.user as any;

  const fullName = getFullName(user);

  return (
    <Box px={5} py={5} onClick={onClick} cursor="pointer">
      <Grid templateColumns="repeat(7, 1fr)" gap={2}>
        {/* <GridItem colSpan={1}>
          { isActive && <DotIcon color="blue.500" boxSize={3} /> }
        </GridItem> */}
        <GridItem colSpan={1} justifyContent="center">
          <Avatar
            name={fullName}
            src={getAvatarUrl(user)}
          />
        </GridItem>
        <GridItem colSpan={5}>
          {text("card.title", fullName, 1)}
          {text("body.regular", jobMatch.jobPost.companyJobTitle, 0)}
          {text("body.sm", user?.location, 2)}
          {renderStatus()}
          <HStack>
            {/* {salaryTag(
              dollarUSLocale.format(
               Number(`${jobMatch.user?.salary || 0}`)
              ), {
              size: "sm",
            })} */}
            {workTypeTag(`${jobMatch.user?.workType}`, { size: "sm" })}
            {locationTag(`${jobMatch.user?.locationType}`, { size: "sm" })}
          </HStack>
        </GridItem>
        <GridItem>
          <VStack>
            <MatchPercentage
              color={color}
              percentage={jobMatch.matchPercentage}
              size="sm"
            />
            <StarIcon
              color="gray.400"
              _hover={{
                color: "blue.400",
              }}
              w={starIconSize}
              h={starIconSize}
            />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ProfileMatchItem;
