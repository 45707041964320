import { Box, Button, Flex, HStack, Link, SimpleGrid } from "@chakra-ui/react";
import { IJobMatch, JobMatchStatus } from "../../types/match";
import { IUser } from "../../types/user";
import Stat from "../common/Stat";
import { text } from "../util/texts";
import JobMatchItem from "./match/JobMatchItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { HiEye, HiPlusCircle } from "react-icons/hi";
import { borders } from "../../styles/common";
import { IJobPost } from "../../types/job-post";
import Joyride from 'react-joyride';

interface UserHomePageProps {
  user: IUser;
  summary: any;
  onboardingSteps?: any;
  showOnboarding?: boolean;  
}

const UserHomePage = ({ user, summary, onboardingSteps, showOnboarding }: UserHomePageProps) => {
  const navigate = useNavigate();

  const renderSection = (display: string, summaryKey: string, link: string, className?: string) => {
    const statusSummaryByKey = get(summary, `statusSummary.${summaryKey}`);
    const topMatches = statusSummaryByKey ? statusSummaryByKey.topMatches : []

    return (
      <Box mb={14} className={className}  {...borders.cardRaisedBold} p={5}>
        <HStack gap={3}>
          {text("section.title", display)}
          <Link as={RouterLink} to={link} textDecoration="underline">
            See All
          </Link>
        </HStack>
        {(topMatches === undefined || topMatches.length === 0) && text("body.regular", "None Available")}
        {topMatches.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={3}>
            {topMatches.map((match: any, idx: number) => {
              const onClick = () => navigate(`/match?userId=${user.id}&jobPostId=${match.jobPostId}`);
              return (
                <Box
                  key={idx}
                  shadow="xl"
                  borderRadius={6}
                  border="1px"
                  borderColor="gray.100"
                >
                  <JobMatchItem jobMatch={match} onClick={onClick} />
                </Box>
              );
            })}
          </SimpleGrid>
        )}
      </Box>
    );
  };
  
  const renderStat = (display: string, summaryKey: string, link: string) => {
    const statusSummaryByKey = get(summary, `statusSummary.${summaryKey}`);
    const value = statusSummaryByKey ? statusSummaryByKey.matchesCount : '0';
    return (
      <Stat name={display} value={value} link={link} linkLabel="View" />
    )
  }

  return (
    <Box px={20} py={15} className="h-zero-step">
      <Joyride
          continuous
          callback={() => {}}
          run={showOnboarding}
          steps={onboardingSteps}
          hideCloseButton
          scrollToFirstStep
          showProgress
          locale={{last: 'Done'}}
          styles={{
            options: {
              arrowColor: 'white',
              backgroundColor: 'white',
              textColor: "black",
              primaryColor: "green", 
            }
          }}
          />
      {text("page.title", `Welcome back, ${user.firstName}!`, 2)}
      <HStack mb={7} gap={3}>
        <Button
          variant="link"
          leftIcon={<HiEye />}
          style={{ color: 'blue' }}
          as={RouterLink}
          to="/jobProfile"
        >
          {text("body.lg", "View Skills Profile", 0)}
        </Button>
        <Button
          variant="link"
          leftIcon={<HiPlusCircle />}
          style={{ color: 'green' }}
          as={RouterLink}
          to="/jobProfile/selectJob"
        >
          {text("body.lg", "Assert New Job Skills", 0)}
        </Button>
      </HStack>
      <Box className="h-first-step">
      {text("section.title", "Your quick summary")}
      </Box>
      <Flex gap={20} mb={14} {...borders.cardRaisedBold} p={8}>
        {renderStat('Total Job Matches', 'topMatches', '/jobMatches')}
        {renderStat('New Job Matches', 'needs-review', '/jobMatches?status=needs-review')}
        {/* {renderStat('Invited', 'invited', '/jobMatches?status=invited')} */}
        {renderStat('Jobs Applied For', 'applied', '/jobMatches?status=applied')}
        {renderStat('Saved Jobs', 'saved', '/jobMatches?status=saved')}
      </Flex>
      <Box>
        {renderSection('Top Job Matches', 'topMatches', '/jobMatches')}
        {renderSection('New Matches to Review', 'needs-review', '/jobMatches?status=needs-review', "h-second-step" )}
        {/* {renderSection('Invited by Company to Apply', 'invited', '/jobMatches?status=invited')} */}
        {renderSection('Jobs Applied For', 'applied', '/jobMatches?status=applied')}
        {renderSection('Saved Jobs', 'saved', '/jobMatches?status=saved')}
      </Box>
    </Box>
  );
};

export default UserHomePage;
