import { capitalize } from "lodash";
import { useToast } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { IFilterValue, ISearchValue } from "../../types/ui/search";
import { OnboardingStatus, UserRole } from "../../types/user";
import MatchPage from "../../components/match/MatchPage";
import { getDefaultFilterValues } from "../../utils/filter";
import { useSearchParams } from "react-router-dom";
import { JobSeekerMatchSteps } from "./JobseekerOnboardingSteps";
import { RecruiterMatchSteps } from "./RecruiterOnboardingSteps";

interface MatchContainerProps {
  userRole: UserRole;
  selectedJobSeekerId?: string; // user id
}

const MatchContainer = ({ userRole, selectedJobSeekerId }: MatchContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const statusQuery = searchParams.get("status")
  const jobRatingQuery = searchParams.get("job-rating");
  const jobPostQuery = searchParams.get("job-post");

  const { currentUser, saveMatchActivity, updateOnboardingStatus } = useUser() as UserContextValue;
  const toast = useToast();

  const {
    matchFilter,
    matches,
    matchesTotal,
    isFetchingMatches,
    fetchMatchFilter,
    fetchMatches,
    page
  } = useMatch() as MatchContextValue;

  const [initialFilterValues, setInitialFilterValues] = useState<IFilterValue[]>();
  const [filterValues, setFilterValues] = useState<IFilterValue[]>();

  useEffect(() => {
    // load the matches first
    if (currentUser) {
      const matchFilterUserId = selectedJobSeekerId || currentUser.id;
      fetchMatchFilter(matchFilterUserId);
    }
  }, []);

  useEffect(() => {
    // initial load based on default filters
    if (currentUser && matchFilter) {
      let resetInitialFilterValues = getDefaultFilterValues(matchFilter, statusQuery, jobRatingQuery, jobPostQuery);
      if (userRole === UserRole.Navigator && selectedJobSeekerId !== undefined) {
        resetInitialFilterValues = [ ...resetInitialFilterValues, { slug: 'user', selectedValue: selectedJobSeekerId }];
      }      

      console.log('resetInitialFilterValues', resetInitialFilterValues);
      setInitialFilterValues(resetInitialFilterValues)
      setFilterValues(resetInitialFilterValues)
      fetchMatches({ searchTerm: '', filterValues: resetInitialFilterValues });
    }    
  }, [matchFilter, selectedJobSeekerId])

  if (!currentUser || !matchFilter || !filterValues) {
    return null;
  }

  const showToast = (
    title: string,
    description: string,
    status: "info" | "warning" | "success" | "error" | "loading"
  ) => {
    toast({
      position: "top",
      title,
      description,
      status,
      duration: 3000,
      isClosable: true,
    });
  };

  const onSearch = (searchValue: ISearchValue) => {
    setFilterValues(searchValue.filterValues as IFilterValue[]);
    fetchMatches(searchValue);
  };

  const onMovePage = (pageNumber: number) => {
    fetchMatches({ searchTerm: '', filterValues: initialFilterValues }, pageNumber)
  }

  const onReset = () => {
    setFilterValues(initialFilterValues);
    fetchMatches({ searchTerm: '', filterValues: initialFilterValues });
  };

  const onMatchAction = async (userMatch: any, action: string) => {
    const activity = {
      userId: userMatch.userId,
      jobId: userMatch.jobId,
      jobRatingId: userMatch.jobRatingId,
      jobPostId: userMatch.jobPostId,
      matchPercentageSnapshot: userMatch.matchPercentage,
      status: action,
      activityByRole: userRole.toString(),
      activityById: currentUser.id,
      activityDate: new Date().toISOString()
    }

    const result = await saveMatchActivity(activity);
    if(result === 'success') {
      showToast(
        `Match Action: ${capitalize(action)}`,
        `Successfully submitted!`,
        "success"
      );

      fetchMatches({ searchTerm: '', filterValues })
    }
  };

  const onFetchMore = () => {
    // console.log('onFetchMore')
  }

  const onboardingSteps = userRole === UserRole.User ? JobSeekerMatchSteps : RecruiterMatchSteps;
  const showOnboarding = currentUser.onboardingStage === OnboardingStatus.ProductTour;

  const onProductTourComplete = () => {
    console.log('onProductTourComplete');
    updateOnboardingStatus(OnboardingStatus.Complete)    
  }

  return (
    <MatchPage
      userRole={userRole}
      jobMatches={matches}
      totalMatches={matchesTotal}
      filters={matchFilter}
      filterValues={filterValues}
      isFetchingMatches={isFetchingMatches}
      onSearchFilter={onSearch}
      onMatchAction={onMatchAction}
      onResetFilter={onReset}
      onFetchMore={onFetchMore}
      onboardingSteps={onboardingSteps}
      showOnboarding={showOnboarding}
      onProductTourComplete={onProductTourComplete}
      onMovePage={onMovePage} 
      page={page}
      />
  );
};

export default MatchContainer;