import { ChakraProvider, } from "@chakra-ui/react"
import { BrowserRouter as Router } from "react-router-dom";
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import App from "./App"
//import StoreProvider from "../stores/StoresProvider"
import UserProvider from "../stores/UserStore"
import theme from "../styles/theme"

export const Root = () => (
  <ChakraProvider theme={theme}>
    <UserProvider>
      {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
      <Router>
        <App />
      </Router>
    </UserProvider>
  </ChakraProvider>
)
