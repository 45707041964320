import {
  Box,
  Center,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { IJobMatch, JobMatchStatus } from "../../types/match";
import { IFilter, IFilterValue, ISearchValue } from "../../types/ui/search";
import MatchDetail from "./MatchDetail";
import { borders, scrolls } from "../../styles/common";
import FilterDetailGroup from "../common/FilterDetailGroup";
import { text } from "../util/texts";
import MatchItemsList from "./MatchItemsList";
import { UserRole } from "../../types/user";
import ProfileMatchItem from "../recruiter/match/ProfileMatchItem";
import JobMatchItem from "../user/match/JobMatchItem";
import Joyride from "react-joyride";

interface MatchPageProps {
  userRole: UserRole;
  jobMatches: IJobMatch[];
  totalMatches: number;
  filters: IFilter[];
  filterValues: IFilterValue[];
  isFetchingMatches: boolean,
  onSearchFilter: (searchValue: ISearchValue) => void;
  onResetFilter: () => void; 
  onFetchMore: () => void;
  onMatchAction: (userMatch: any, status: JobMatchStatus) => void;
  onboardingSteps?: any;
  showOnboarding?: boolean;  
  onProductTourComplete: () => void;
  onMovePage: (pageNumber: number) => void;
  page: number;
}

// TODO no more to fetch indicator

const MatchPage = ({
  userRole,
  jobMatches,
  totalMatches,
  filters,
  filterValues,
  isFetchingMatches,
  onSearchFilter,
  onResetFilter,
  onFetchMore,
  onMatchAction,
  onboardingSteps,
  showOnboarding,
  onProductTourComplete,
  onMovePage,
  page
}: MatchPageProps) => {

  const screenSize = useBreakpointValue({
    base: "small",
    md: "medium",
    lg: "large",
  });

  const [selectedMatch, setSelectedMatch] = useState<IJobMatch>();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleJobMatchSelection = (userId: string, jobPostId: string) => {
    const jobMatch = jobMatches.find(
      (m) => m.userId === userId && m.jobPostId === jobPostId
    );
    if (jobMatch) {
      setSelectedMatch(jobMatch);
    }
  };

  const setFirstMatch = () => {
    if (jobMatches.length === 0) {
      setSelectedMatch(undefined);
    }
    else {
      setSelectedMatch(jobMatches[0]);
    }
  }

  useEffect(() => {
    setFirstMatch();
  }, [])

  useEffect(() => {
    setFirstMatch();
  }, [jobMatches])

  const renderFilter = () => {
    const onUpdate = (updatedFilterValues: IFilterValue[]) => {
      onSearchFilter({
        searchTerm,
        filterValues: updatedFilterValues,
      });
    };

    return (
      <FilterDetailGroup
        filters={filters}
        filterValues={filterValues}
        onUpdate={onUpdate}
        onReset={onResetFilter}
        direction="row"
      />
    );
  };

  const renderDetail = () => {
    if (!jobMatches || jobMatches.length === 0) {
      return (
        <Box>
          {text('section.title', 'No Matches Found')}
          {text('body.regular', 'Update or reset your filter to view other matches')}
        </Box>
      )
    }

    if (!selectedMatch) {
      return (
        <Center height="90vh">
          <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Center>
      )
    }

    const handleOnMatchAction = (status: JobMatchStatus) => {
      if (!selectedMatch) {
       return;
      }     

      const userMatch = selectedMatch;
      onMatchAction(userMatch, status); 
    }
    
    return (
      <MatchDetail 
        userRole={userRole}
        match={selectedMatch}
        isFetching={isFetchingMatches}
        isProductTour={showOnboarding || false}
        onMatchAction={handleOnMatchAction}        
      />
    );
  };

  const onSelectJobMatch = (userId: string, jobPostId: string) => {
    handleJobMatchSelection(userId, jobPostId);
  }

  const onSearch = (searchTerm: string) => {
    onSearchFilter({ searchTerm, filterValues })
  }

  const onSearchClear = () => {
    onSearchFilter({ searchTerm: '', filterValues })
  }

  const onRenderMatchItem = (match: IJobMatch, onSelect: () => void) => {
    if (userRole === UserRole.User || userRole === UserRole.Navigator) {
      return (
        <JobMatchItem jobMatch={match} onClick={onSelect} />
      )
    }
    else {
      return (
        <ProfileMatchItem jobMatch={match} onClick={onSelect} />
      );      
    }
  }

  const onRenderMatchDetail = (jobMatch: IJobMatch) => {
    return renderDetail();
  }

  const handleJoyRideCallBack = (data: any) => {
    if (data.lifecycle === 'complete' && data.status === 'finished') {
      onProductTourComplete();
    }
  }

  return (
    <Box h="100%">
      <Joyride
          continuous
          callback={handleJoyRideCallBack}
          run={showOnboarding}
          steps={onboardingSteps}
          hideCloseButton
          scrollToFirstStep
          showProgress
          locale={{last: 'Done'}}
          styles={{
            options: {
              arrowColor: 'white',
              backgroundColor: 'white',
              textColor: "black",
              primaryColor: "green", 
            }
          }}
          />
      <Box className="m-zero-step">
        <Box
          pos="fixed"
          h="100%"
          w={{ base: "100%", md: "80%", lg: "25%" }}
          {...scrolls.yOverflowSubtle}
        >
          <MatchItemsList
            jobMatches={jobMatches}
            totalMatches={totalMatches}
            selectedMatch={selectedMatch}
            isFetchingMatches={isFetchingMatches}
            onSelectJobMatch={onSelectJobMatch}
            onSearch={onSearch}
            onSearchClear={onSearchClear}
            onFetchMore={onFetchMore}
            onRenderMatchItem={onRenderMatchItem}
            onRenderMatchDetail={onRenderMatchDetail} 
            page={page}
            onMovePage={onMovePage}
            />
        </Box>
        {screenSize === "large" && (
          <Box
            pos="fixed"
            h="100%"
            w={userRole === UserRole.Navigator ? { base: "0%", md: "0%", lg: "75%" } : { base: "0%", md: "0%", lg: "60%" }}
            ml="25%"
            px={7}
            py={3}
            {...scrolls.yOverflowSubtle}
          >
            {renderDetail()}
          </Box>
        )}
        {userRole !== UserRole.Navigator && (screenSize === "large" || screenSize === "medium") && (
          <Box
            pos="fixed"
            h="100%"
            w={{ base: "0%", md: "25%", lg: "15%" }}
            ml={{ base: "0%", md: "80%", lg: "85%" }}
            px={7}
            py={3}
            {...scrolls.yOverflowSubtle}
            {...borders.left}
          >
            {renderFilter()}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MatchPage;
