import { IJob } from "../types/job";
import { IJobRating } from "../types/rating";
import { IUser, UserRole } from "../types/user";
import { ApiInstance } from "../api/ApiInstance";

export const getFullName = (user: IUser): string => {
  if (user.firstName && user.lastName){
    return `${user.firstName} ${user.lastName}`
  }
  return `${user?.first_name} ${user?.last_name}`;
}

export const isRecruiter = (user?:  IUser) => {
  return user && user.userRole === UserRole.Recruiter;
}

export const getUserJobRoles = (ratings?: IJobRating[]): IJob[] => {
  if (!ratings || ratings.length === 0) {
    return [];
  }

  const jobRoleTitles = ratings.map(r => r.job);
  jobRoleTitles.sort();

  return jobRoleTitles;
}

export const getAvatarUrl = (user: IUser | undefined): string => {  
  if (!user) {
    return '';
  }

  let url;
  if (user.avatar) {
    url = `${ApiInstance.assetsUrl}/${user.avatar}?access_token=${localStorage.getItem("authorizationToken")}`
  }
  else {
    url = user.avatarUrl || '';
  }
  
  // console.log('getAvatarUrl', url);
  return url;
}