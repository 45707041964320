import { ICompany } from "./company";
import { IJobPost } from "./job-post";
import { IJobRating } from "./rating";

export enum UserRole {
  User = 'user',
  Recruiter = 'recruiter',
  Navigator = 'navigator',
}

export interface IUser {
  id: string;
  username: string;
  userRole: string; // UserRole
  firstName: string;
  lastName: string;
  first_name?: string;
  last_name?: string;
  headline?: string;
  email: string;
  avatar?: string;
  avatarUrl?: string;
  location: string;
  summary: string;
  linkedIn?: string;
  resume?: any,
  resumeFile?: any, // for match relationship
  status?: string;
  
  customStatus?: string;
  onboardingStage?: string;
  locationType: string;
  workType: string;
  salary: number;  

  // Joins
  ratings?: Omit<IJobRating, "user">[];
}

export interface IRecruiter extends IUser {
  companyId: string;

  // Joins
  company: ICompany;
  jobPosts?: Omit<IJobPost, "recruiter">[];
}

export enum OnboardingStatus {
  ProfileSetup = 'profile_setup',
  JobSetup = 'job_profile',
  ProductTour = 'product_tour',
  Complete = 'complete',
}