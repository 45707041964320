import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import { FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa'

function Expander({onColapseAll, onExpandAll} : {onColapseAll: () => void, onExpandAll: () => void}) {
  return (
    <Box mb={7}>
        <Button leftIcon={<FaRegMinusSquare />} variant="link"  onClick={onColapseAll}>
            Collapse All
        </Button>
        <Button ml={3} leftIcon={<FaRegPlusSquare />} variant="link" onClick={onExpandAll}>
            Expand All
        </Button>
    </Box>
  )
}

export default Expander