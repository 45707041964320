/* eslint-disable no-restricted-globals */
import { Avatar, Center, Flex, IconButton, Link, Menu, MenuButton, MenuGroup, MenuItem, MenuList, useBreakpointValue } from "@chakra-ui/react";
import { IRecruiter, IUser, UserRole } from "../../types/user";
import { FiMenu } from 'react-icons/fi'
import { useNavigate } from "react-router-dom";
import { text } from "../util/texts";
import { getFullName, isRecruiter, getAvatarUrl } from "../../utils/users";
import { QuestionIcon, SettingsIcon, UnlockIcon } from "@chakra-ui/icons";
import { FaRegComments } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { AiOutlineProfile, AiOutlineUser } from "react-icons/ai";
import { OnboardingStatus } from "../../types/user";

interface HeaderProps {
  user?: IUser | IRecruiter,
  onHomeNav: () => void,  
  onLogout: () => void,
  onProductTourEnable: () => void,
}

const menuItemsByRole = {
  'user': [
    { label: 'Overview', path: '/' },
    { label: 'Job Matches', path: '/jobMatches' },
    // { label: 'Invites', path: '/jobMatches?searchTerm=&status=invited' },
    { label: 'My Job Skills', path: '/jobProfile' },
    // { label: 'Reports', path: '/reports' },
  ],
  'recruiter': [
    { label: 'Overview', path: '/' },
    { label: 'Candidate Matches', path: '/profileMatches' },
    { label: 'My Job Listings', path: '/jobPosts' },
    // { label: 'Reports', path: '/reports' },
  ],
  'navigator': [
    { label: 'Candidates', path: '/' },
  ]
}

const Header = ({ user, onHomeNav, onLogout, onProductTourEnable }: HeaderProps) => {

  const navigate = useNavigate();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const isAuthenticated = user !== undefined;  

  const renderDesktopNavMenu = () => {
    if (!isAuthenticated) {
      return null;
    }

    let menuItems;
    if (user.userRole === 'navigator') {
      menuItems = menuItemsByRole.navigator;
    }
    else if (user.userRole === 'recruiter') {
      menuItems = menuItemsByRole.recruiter;
    }
    else {
      menuItems = menuItemsByRole.user;
    }

    return (
      <Center gap={5} className="h-third-step">
        {menuItems.map((mi, idx) => <Link key={idx} as={RouterLink} to={mi.path}>{text('header.menu', mi.label, 0)}</Link>)}
      </Center>
    );
  }

  const renderProductTourMenuItem = () => {
    if(user && (OnboardingStatus.Complete === user.onboardingStage)) {
      return (
        <MenuItem icon={<FaRegComments />} onClick={() => onProductTourEnable()}>Turn on Product Tour</MenuItem>
      )
    }
  }

  const renderDesktopProfileMenu = () => {
    if (!isAuthenticated) {
      return (
        <Flex flex="1" justify="flex-end" align="center" gap={3}>
          {/* <Button variant="outline">Sign Up</Button>
        <Button variant="outline">Sign In</Button> */}
        </Flex>
      )
    }

    
    return (
      <Flex flex="1" justify="flex-end" align="center" gap={3}>
        <QuestionIcon />
        <SettingsIcon />
        <Menu closeOnSelect={true}>
          <MenuButton as={Avatar} name={getFullName(user)} src={getAvatarUrl(user)} size="md" cursor="pointer" />
          <MenuList minW='240px' color="black">
            <MenuGroup title='Profile'>
              <MenuItem icon={<AiOutlineUser />} onClick={() => navigate('/profile')}>My Profile</MenuItem>
              {isRecruiter(user) &&
              <MenuItem icon={<AiOutlineProfile />} onClick={() => navigate('/companyProfile')} >Company Profile</MenuItem>
              }
              {renderProductTourMenuItem()}
              <MenuItem icon={<UnlockIcon />} onClick={() => navigate('/resetpassword')}>Reset Password</MenuItem>
              <MenuItem icon={<FaSignOutAlt />} onClick={() => onLogout()}>Sign Out</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
    );
  }

  const renderMobileMenu = () => {
    return (
      <Flex flex="1" justify="flex-end" align="center">
        <IconButton
          variant="ghost"
          icon={<FiMenu fontSize="1.25rem" />}
          aria-label="Open Menu"
        />
      </Flex>
    )
  }

  return (
    <Flex px={5} py={5} gap={6} backgroundColor="#162040" color="white">
      <Center mr={7}>
        {text('header.icon', 'connect', 0)}
      </Center>
      {isDesktop ? (
        <>
          {renderDesktopNavMenu()}
          {renderDesktopProfileMenu()}
        </>
      ) : (
        <>
          {renderMobileMenu()}
        </>
      )}
    </Flex>
  )
};

export default Header;