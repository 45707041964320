import { Box, Button, Flex, HStack, VStack } from "@chakra-ui/react";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IFilter, IFilterValue } from "../../types/ui/search";
import { text } from "../util/texts";
import FilterDetail from "./FilterDetail";

interface FilterDetailGroupProps {
  filters: IFilter[],
  filterValues?: IFilterValue[],
  onUpdate: (updatedFilterValues: IFilterValue[]) => void,
  direction: 'row' | 'column',
  onReset?: () => void
}

const FilterDetailGroup = ({
  filters,
  filterValues,
  onUpdate,
  direction,
  onReset,
}: FilterDetailGroupProps) => {
  // const [currentFilterValues, setCurrentFilterValues] = useState<IFilterValue[]>(filterValues || []);

  const getSelectHandler = (slug: string) => (value: string | string[]) => {
    const updatedFilterValues: IFilterValue[] = filterValues || [];
    const existingFilterValue: IFilterValue | undefined = updatedFilterValues.find(fv => fv.slug === slug);
    if (!existingFilterValue) {
      updatedFilterValues.push({
        slug,
        selectedValue: value
      })
    }
    else {
      existingFilterValue.selectedValue = value;
    }

    onUpdate(updatedFilterValues);
    // setCurrentFilterValues([ ...updatedFilterValues]);
  }
  const renderFilterDetailItems = () => {
    return filters?.map((filter, idx) => {
      const filterValue = filterValues?.find(fv => fv.slug === filter.slug);

      return (
        <FilterDetail
          key={idx}
          filter={filter}
          filterValue={filterValue}
          onSelect={getSelectHandler(filter.slug)}
          optionRenderer={filter.optionRenderer}
        />
      )
    })
  }
  const resetFilter = () => {
    if (onReset) {
      onReset();
    }
  }
  return (
    <Box className="m-second-step">
      <Flex direction={{ md: "column", lg: "column" }} alignItems={'center'} justifyContent={'space-between'}>
        {text('section.subtitle', 'Job Filters')}
        <Button colorScheme='teal' variant={'outline'} size="sm" onClick={resetFilter} mb={5}>Reset All</Button>
      </Flex>
      {direction === 'row' ? (
        <VStack gap={5} align="flex-start">
          {renderFilterDetailItems()}
        </VStack>
      ) : (
        <HStack gap={7} align="flex-start">
          {renderFilterDetailItems()}
        </HStack>
      )}
    </Box>
  )
};

export default FilterDetailGroup;