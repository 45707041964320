import { Box } from "@chakra-ui/react";
import { IUser } from "../../types/user";
import Header from "./Header";
import {
  useNavigate,
} from "react-router-dom";

interface LayoutProps {
  user?: IUser,
  children?: any,
  onProductTourEnable: () => void,
  onLogout: () => void,
}

const Layout = ({ user, children, onLogout, onProductTourEnable }: LayoutProps) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Header user={user} onHomeNav={() => navigate('/')} onLogout={onLogout} onProductTourEnable={onProductTourEnable} />
      {children}
    </Box>
  )
};

export default Layout;