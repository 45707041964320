import { createContext, useContext, useState } from "react";
import _ from 'lodash';

import { api } from "../api";
import { CompetencyRatingMap } from "../components/shared/CategoryRater";
import { IJobPostWithStats } from "../types/job-post";
import { IJob } from "../types/job";
import { UserContextValue, useUser } from "./UserStore";

export type JobPostContextValue = {
  jobPost: IJobPostWithStats | undefined;
  jobPosts: IJobPostWithStats[] | undefined;
  isFetchingJobPosts: boolean;
  isFetchingJobPost: boolean;
  fetchJobPosts: () => void;
  fetchJobPost: (jobPostId: string) => void;
  createJobPost: (job: IJob, jobPostPayload: any,competencyRatings: any) => Promise<boolean>;
  updateJobPost: (jobPostId: string,jobPostPayload: any,competencyRatings: CompetencyRatingMap) => Promise<void>;
  closeJobPost: (jobPostId: string) => Promise<void>;
  unpublishedJobPost: (jobPostId: string) => Promise<void>;
  activeJobPost: (jobPostId: string) => Promise<void>;
};

const JobPostContext = createContext<JobPostContextValue | undefined>(undefined);

export const useJobPost = () => useContext(JobPostContext);

// eslint-disable-next-line react/function-component-definition
const JobPostProvider = ({ children }: any) => {
  const { currentUser } = useUser() as UserContextValue;
  const [isFetchingJobPosts, setIsFetchingJobPosts] = useState(true);
  const [isFetchingJobPost, setIsFetchingJobPost] = useState(true);
  const [jobPosts, setJobPosts] = useState<IJobPostWithStats[]>([]);
  const [jobPost, setJobPost] = useState<IJobPostWithStats>();

  /* eslint-disable consistent-return */
  const fetchJobPosts = async () => {
    try {
      setIsFetchingJobPosts(true);

      const filterUrl = `filter[recruiterId][_eq]=${currentUser?.id}`;
      const res = await api.get(`/items/jobPosts?${filterUrl}`);
      setJobPosts(res.data?.data);
      setIsFetchingJobPosts(false);
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };
  /* eslint-enable consistent-return */

  const fetchJobPost = async (jobPostId: string) => {
    try {
      setIsFetchingJobPost(true);
      const response = await api.get(`/items/jobPosts/${jobPostId}`);
      setJobPost(response.data.data);
      setIsFetchingJobPost(false);
    } catch (error) {
      setIsFetchingJobPost(false);
    }
  };

  const createJobPost = async (
    job: IJob,
    jobPostPayload: any,
    competencyRatings: any
  ): Promise<boolean> => {
    try {
      const result =  await api.post("/items/jobPosts", {
        jobId: job.id,
         ...jobPostPayload
       });

       const jobCompetencies = [];
 
       for(const competencyId in competencyRatings) {
         if(!competencyId) continue;
 
         const rating = competencyRatings[competencyId];
         let categoryId;
 
         for(const category of job.categories) {
           const competencyToFind: any = _.find(category.competencies, (competency) => {
             return competency.id === competencyId
           }) 
 
           if(competencyToFind) {
             categoryId = category.id;
             break;
           }
         }
 
         if(!competencyId || !categoryId) continue;
         jobCompetencies.push(
           {
             categoryId,
             competencyId,
             jobId: job.id,
             jobPostId: result.data.data.id,
             rating: rating,
             proficiencyRatedAt: new Date().toISOString()
           }
         )
       }
 
       await api.post("/items/job_post_competencies", jobCompetencies);
       return true;
     } catch (error) {
       console.error('create post error', error);
       setIsFetchingJobPosts(false);
       return false;
     }
  };

  const updateJobPost = async (
    jobPostId: string,
    jobPostPayload: any,
    competencyRatings: CompetencyRatingMap
  ): Promise<void> => {
    try {
      setIsFetchingJobPosts(true);
      await api.patch(`/items/jobPosts/${jobPostId}`, {
        ...jobPostPayload
      });
      await api.patch(`/items/jobPosts/${jobPostId}`, { jobPostId, competencyRatings });
      fetchJobPosts();
      setIsFetchingJobPosts(false);
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };

  const closeJobPost = async (jobPostId: string): Promise<void> => {
    try {
      await api.patch(`/items/jobPosts/${jobPostId}`, {
        status: "closed",
      });
      fetchJobPosts();
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };
  /* eslint-enable consistent-return */

  const unpublishedJobPost = async (jobPostId: string): Promise<void> => {
    try {
      await api.patch(`/items/jobPosts/${jobPostId}`, {
        status: "unpublished"
      });
      fetchJobPosts();
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };

  const activeJobPost = async (jobPostId: string): Promise<void> => {
    try {
      await api.patch(`/items/jobPosts/${jobPostId}`, {
        status: "active"
      });
      fetchJobPosts();
    } catch (error) {
      setIsFetchingJobPosts(false);
    }
  };

  return (
    <JobPostContext.Provider
      value={{
        jobPost,
        jobPosts,
        isFetchingJobPosts,
        isFetchingJobPost,
        fetchJobPost,
        fetchJobPosts,
        createJobPost,
        updateJobPost,
        closeJobPost,
        unpublishedJobPost,
        activeJobPost,
      }}
    >
      {children}
    </JobPostContext.Provider>
  );
  /* eslint-enable react/jsx-no-constructed-context-values */
};

export default JobPostProvider;
