// import { Center, Spinner } from "@chakra-ui/react";
// import UserHomePage from "../../components/user/UserHomePage";
// import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
// import { UserContextValue, useUser } from "../../stores/UserStore";

// const UserHomeContainer = () => {
//   const { currentUser } = useUser() as UserContextValue;
//   const { userJobMatch: matches, isFetchingNextUserJobMatchPage } = useMatch() as MatchContextValue;

  

//   if (isFetchingNextUserJobMatchPage) {
//     return (
//       <Center height="90vh">
//         <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
//       </Center>
//     );
//   }

//   if (!currentUser || !matches) {
//     return null;
//   }

//   return <UserHomePage user={currentUser} jobMatches={matches} />
// };

// export default UserHomeContainer;

import { Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { MatchContextValue, useMatch } from "../../stores/MatchProvider";
import { UserContextValue, useUser } from "../../stores/UserStore";
import UserHomePage from "../../components/user/UserHomePage";
import { JobSeekerHomeSteps } from "../match/JobseekerOnboardingSteps";
import { OnboardingStatus } from "../../types/user";

const UserHomeContainer = () => {
  const { currentUser } = useUser() as UserContextValue;

  const { summaryMatches, isFetchingSummaryMatches, fetchSummaryMatches} = useMatch() as MatchContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchSummaryMatches();
    }
  }, []);

  if (isFetchingSummaryMatches) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }

  if (!currentUser || !summaryMatches) {
    return null;
  }

  const onboardingSteps = JobSeekerHomeSteps;
  const showOnboarding = currentUser.onboardingStage === OnboardingStatus.ProductTour;
  
  return <UserHomePage user={currentUser} summary={summaryMatches} onboardingSteps={onboardingSteps} showOnboarding={showOnboarding} />  
};

export default UserHomeContainer;
