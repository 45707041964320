import { useEffect, useState } from 'react';
import { Box, Center, Spinner, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import ShowEvidences from '../../components/common/ShowEvidences';
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobContextValue, useJob } from '../../stores/JobProvider';
import { text } from '../../components/util/texts';
import JobRater from '../../components/shared/JobRater';
import { OnboardingStatus, UserRole } from '../../types/user';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';
import { RatingContextValue, useRating } from '../../stores/RatingProvider';
import { EvidenceContextValue, useEvidence } from '../../stores/EvidenceStore';

const UserJobRatingCreateContainer = () => {
  const { jobId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const { isOpen: isEvidenceModalOpen, onOpen: onEvidenceModalOpen, onClose: onEvidenceModalClose } = useDisclosure();

  const { currentUser, uploadUserFile, updateOnboardingStatus } = useUser() as UserContextValue;
  const { job, isFetchingJobs, fetchJob } = useJob() as JobContextValue;
  const { rating, createUserJobRating } = useRating() as RatingContextValue;

  const {
    evidences,
    isFetchingEvidences,
    deleteEvidence,
    addEvidence,
    updateEvidence,
    fetchEvidences,
  } = useEvidence() as EvidenceContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJob(jobId as string)      
    }
  }, []);



  if (isFetchingJobs) {
    <Center height="90vh">
      <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
    </Center>
  }

  if (!currentUser || !job) {
    return null;
  }

  if (isFetchingEvidences) {
    return (
      text("body.regular" ,"Loading ...")
    )
  }

  if (evidences === undefined) {
    text("body.regular" ,"Error fetching evidences")
  }
  
  const onEvidenceDelete = (evidenceId: string) => {
    deleteEvidence(evidenceId);
    setTimeout(() => fetchEvidences(currentUser.id), 500);
  };

  const onEvidenceAddOrUpdate = async (formVaules: any, file?: File) => {
    if (rating) {
      if (file !== undefined) {
        const fileId = await uploadUserFile(file);
        console.log('fileId', fileId);
        formVaules.fileUpload = fileId;
      }

      if (formVaules.id) {
        updateEvidence(formVaules, rating.id)
      }
      else {
        addEvidence(formVaules, rating.id);
      }      
      setTimeout(() => fetchEvidences(currentUser.id), 750);
    }    
  };

  const renderEvidences = () => {
    if (!rating) return null;

    const jobRatingEvidences = evidences?.filter(e => e.jobRatingId === rating.id) || [];

    return (
      <Box mb={"4"}>
        <ShowEvidences 
        evidences={jobRatingEvidences}
        onEvidenceDelete={onEvidenceDelete}
        onEvidenceAddOrUpdate={onEvidenceAddOrUpdate}
        canHide={false}
        />
      </Box>
    )
  }

  const renderEvidenceModal = () => {
    return (
      <Modal isOpen={isEvidenceModalOpen} onClose={onEvidenceModalClose}>
        <ModalOverlay />
        <ModalContent maxWidth="56rem">
          <ModalHeader>
            {text('section.title', 'Would you like to add evidence?')}            
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {text(
              'body.regular',
              `Add any evidence items that you think demonstrate skills for this job '${job.title}'. Evidence can be added using links that contain examples of your work.`,
              4
            )}
            {text(
              'body.regular',
              `When you are done adding evidence for this job, or if you want to do it later, click on 'Go to See Job Matches' button below to see how you fit job openings for '${job.title}'`,
              4
            )}
            {renderEvidences()}
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => {
              onEvidenceModalClose()
              if (currentUser?.onboardingStage === OnboardingStatus.JobSetup) {
                await updateOnboardingStatus(OnboardingStatus.ProductTour);
              }
              navigate(`/jobMatches?job-rating=${rating?.id}`);
            }}
              colorScheme='blue'
            >
              Go to see Job Matches
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const onSubmit = (updatedValues: CompetencyRatingMap) => {
    createUserJobRating(job.id, updatedValues)
    .then(async (rating) => {
      toast({
        title: 'Job skillset added to profile!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onEvidenceModalOpen();
    })
  }

  return (
    <Box px={20} py={15}>
      {text('page.title', job.title)}
      {text('page.instruction', 'For each skill, select the proficieny statement that best represents your current capabilities.', 5)}
      <JobRater job={job} ratingRole={UserRole.User} onSubmit={onSubmit} />
      {renderEvidenceModal()}
    </Box>
  )
};

export default UserJobRatingCreateContainer;