import {
  Container,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon,
  Flex,
  Button,
  useToast,
  Box,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { UserContextValue, useUser } from "../../stores/UserStore";
import { borders } from "../../styles/common";
import { RegularExpressions } from "../util/validation";
import PasswordError from "./PasswordError";
import { text } from "../util/texts";

const passwordRegExp: RegExp = RegularExpressions.password;

function ResetPassword() {
  const navigate = useNavigate();
  const toast = useToast();

  const { resetPassword, passwordResetStatus, currentUser, updateOnboardingStatus } = useUser() as UserContextValue;
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isReseting, setIsReseting] = useState(false);

  const showToast = (
    title: string,
    description: string,
    status: "info" | "warning" | "success" | "error" | "loading"
  ) => {
    toast({
      position: "top",
      title,
      description,
      status,
      duration: 3000,
      isClosable: true,
    });
  };

  const resetPasswordHandler = async (
    oldPassword: string,
    newPwd: string,
    pwdConfirm: string
  ): Promise<void> => {
    setIsReseting(true);
    const result = await resetPassword(oldPassword, newPwd, pwdConfirm);
    if(result === 'success') {
      showToast(
        "Success",
        "Your password is changed successfuly.",
        "success"
      );

      if(currentUser?.customStatus === 'unverified'){
        await updateOnboardingStatus('active');
        navigate("/");
      } else {
        navigate("/");
      }
    } else{
      showToast("Error", passwordResetStatus, "error");
    }
  };

  const validatePassword = (pswd: string) => {
    // if password is empty
    if (!pswd) {
      return "Password is required";
    }

   // const isPasswordValid = passwordRegExp.test(pswd);
      return pswd;
  };

  const validateOldPassword = (pswd: string) => {
    const validationResult = validatePassword(pswd);

    if (validationResult === pswd) {
      setPassword(pswd);
    } else {
      return validationResult;
    }
  };

  const validateNewPassword = (pswd: string) => {
    const validationResult = validatePassword(pswd);

    if (validationResult === pswd) {
      setNewPassword(pswd);
    } else {
      return validationResult;
    }
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    // if confirm password is empty
    if (!confirmPassword) {
      return "Confirm password is required";
    }

    // if password and confirm password are not the same
    if (newPassword !== confirmPassword) {
      return "Password mismatch";
    }
  };

  return (
    <Box>      
      <Container mt={100} {...borders.cardRaised} style={{ marginTop: currentUser?.customStatus === 'unverified' ? 65 : 75 }}>
        {currentUser?.customStatus === 'unverified' ? text('page.subtitle', 'Welcome to connect. Please reset your password to proceed', 5) : text('page.subtitle', 'Password Reset', 5) }
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            await resetPasswordHandler(
              values.oldPassword,
              values.newPassword,
              values.confirmPassword
            );
          }}
        >
          {(props) => (
            <Form>
              <Field name="oldPassword" validate={validateOldPassword}>
                {({ field, form }: { field: any; form: any }) => (
                  <React.Fragment>
                    <FormControl
                      isRequired={true}
                      isInvalid={
                        form.errors.oldPassword && form.touched.oldPassword
                      }
                    >
                      <FormLabel>Old Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={showOldPassword ? "text" : "password"}
                          {...field}
                          placeholder="Old Password"
                        />
                        <InputRightElement width="4.5rem">
                          {showOldPassword ? (
                            <Icon
                              as={AiFillEyeInvisible}
                              onClick={() => setShowOldPassword(false)}
                            />
                          ) : (
                            <Icon
                              as={AiFillEye}
                              onClick={() => setShowOldPassword(true)}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        <PasswordError password={field.value} />
                      </FormErrorMessage>
                    </FormControl>
                  </React.Fragment>
                )}
              </Field>
              <Field name="newPassword" validate={validateNewPassword}>
                {({ field, form }: { field: any; form: any }) => (
                  <React.Fragment>
                    <FormControl
                      isRequired={true}
                      isInvalid={
                        form.errors.newPassword && form.touched.newPassword
                      }
                    >
                      <FormLabel>New Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={showNewPassword ? "text" : "password"}
                          {...field}
                          placeholder="New Password"
                        />
                        <InputRightElement width="4.5rem">
                          {showNewPassword ? (
                            <Icon
                              as={AiFillEyeInvisible}
                              onClick={() => setShowNewPassword(false)}
                            />
                          ) : (
                            <Icon
                              as={AiFillEye}
                              onClick={() => setShowNewPassword(true)}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        <PasswordError password={field.value} />
                      </FormErrorMessage>
                    </FormControl>
                  </React.Fragment>
                )}
              </Field>
              <Field name="confirmPassword" validate={validateConfirmPassword}>
                {({ field, form }: { field: any; form: any }) => (
                  <React.Fragment>
                    <FormControl
                      mt={5}
                      isRequired={true}
                      isInvalid={
                        form.errors.confirmPassword &&
                        form.touched.confirmPassword
                      }
                    >
                      <FormLabel>Confirm Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={showConfirmPassword ? "text" : "password"}
                          {...field}
                          placeholder="Confirm password"
                        />
                        <InputRightElement width="4.5rem">
                          {showConfirmPassword ? (
                            <Icon
                              as={AiFillEyeInvisible}
                              onClick={() => setShowConfirmPassword(false)}
                            />
                          ) : (
                            <Icon
                              as={AiFillEye}
                              onClick={() => setShowConfirmPassword(true)}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                  </React.Fragment>
                )}
              </Field>
              <Flex justifyContent="center">
                <Button
                  mt={4}
                  colorScheme="red"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Reset
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Container>
   </Box>
  );
}

export default ResetPassword;
