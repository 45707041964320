import { Box } from "@chakra-ui/react";
import { borders } from "../../styles/common";
import { IJob } from "../../types/job";
import { text } from "../util/texts";

interface JobItemProps {
  job: IJob,
  onClick: () => void,
}

const JobItem = ({
  job,
  onClick,
}: JobItemProps) => {
  return (
    <Box onClick={onClick} cursor="pointer" { ...borders.cardRaised } p={5} height="100%">
      {text('card.title', job.title)}
      {text('body.regular', job.description)}
    </Box>    
  )
}

export default JobItem;