import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { UserContextValue, useUser } from '../../stores/UserStore';
import { JobContextValue, useJob } from '../../stores/JobProvider';
import { JobPostContextValue, useJobPost } from '../../stores/JobPostProvider';
import JobPost from '../../components/recruiter/jobPost/JobPost';
import { CompetencyRatingMap } from '../../components/shared/CategoryRater';
import { OnboardingStatus } from '../../types/user';

const RecruiterJobPostCreateContainer = () => {
  const { jobId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const { currentUser, updateOnboardingStatus } = useUser() as UserContextValue;
  const { job, isFetchingJobs, fetchJob } = useJob() as JobContextValue;

  const { createJobPost } = useJobPost() as JobPostContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJob(jobId as string)
    }
  }, []);

  if (isFetchingJobs) {
    <Center height="90vh">
      <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
    </Center>
  }

  if (!currentUser || !job) {
    return null;
  }

  const onSubmit = async (jobPostDetails: any, updatedValues: CompetencyRatingMap) => {
    const result = await createJobPost(job, jobPostDetails, updatedValues)
    if(result) { 
      toast({
        title: 'Job Post created!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      if(currentUser?.onboardingStage === OnboardingStatus.JobSetup) {
        await updateOnboardingStatus(OnboardingStatus.ProductTour);
        return navigate('/');
      }
      return navigate('/jobPosts');
    }

    toast({
      title: 'Failed to create Job Post',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }

  return (
    <Box px={20} py={15}>
      <JobPost job={job} onSubmit={onSubmit} />
    </Box>
  )
};

export default RecruiterJobPostCreateContainer;