import { Box, Button, Heading, Input, useToast, VStack, Link, Avatar } from "@chakra-ui/react";
import { Formik } from "formik";
import { renderInput, renderRadios } from "../../util/forms";
import { IUser } from "../../../types/user";
import { useState } from "react";
import { text } from "../../util/texts";
import { getFullName, getAvatarUrl } from "../../../utils/users";
import { DownloadIcon } from "@chakra-ui/icons";
import { ApiInstance } from "../../../api/ApiInstance";

interface UserProfileProps {
  user?: IUser;
  onSubmit: (values: any, file?: File, avatarImg?: File) => void;
}

const required = { isRequired: true };
const UserProfile = ({ user, onSubmit }: UserProfileProps) => {
  const toast = useToast();
  const [file, setFile] = useState<File | undefined>();
  const [avatarImg, setAvatarImg] = useState<File | undefined>();

  const handleResumeChange = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleAvatarUpload = (event: any) => {
    console.log('handleAvatarUpload');
    if (event.target.files) {
      setAvatarImg(event.target.files[0]);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          firstName: user ? user.firstName : '',
          lastName: user ? user.lastName : '',
          headline: user?.headline === null ? '' : user?.headline,
          email: user ? user.email : '',
          location: user ? user.location : '',
          summary: user ?  user.summary : '' ,
          locationType: user?.locationType || '',
          workType: user ? user?.workType : '',
          salary: user?.salary || 0,
          linkedIn: user?.linkedIn === null ? '' : user?.linkedIn,
          resume: (user?.resume === undefined || user?.resume === null) ? undefined : (user?.resume.id || user?.resume),
        }}
        onSubmit={(values) => {
          const val: any = {
            first_name: values.firstName,
            last_name: values.lastName,
            headline: values.headline,
            email: values.email,
            location: values.location,
            summary: values.summary,
            locationType: values.locationType,
            workType: values.workType,
            salary: values.salary,
            linkedIn: values.linkedIn,
            resume: values.resume,
          }
          onSubmit(val, file, avatarImg);
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <Heading fontSize="2xl">Basic Information</Heading>
              {renderInput('text', 'firstName', 'First Name', required, errors, touched)}
              {renderInput('text', 'lastName', 'Last Name', required, errors, touched)}
              {renderInput('text', 'headline', 'Headline', {isRequired: false} , errors, touched)}
              {renderInput('email', 'email', 'Email', required, errors, touched)}
              {renderInput('text', 'location', 'Location (e.g. City, State)', required, errors, touched)}
              {renderInput('text', 'summary', 'Brief Intro', required, errors, touched)}
              {renderInput('text', 'linkedIn', 'LinkedIn Profile Link (Please create a profile on https://www.linkedin.com/ if you do not have one!)', {isRequired: false} , errors, touched)}
              
              {text('body.regular', 'Resume Upload')}
              {user?.resume && (
                <Link href={`${ApiInstance.assetsUrl}/${user?.resume.id}?access_token=${localStorage.getItem("authorizationToken")}&download`} target="_blank" download={user.resume.filename_download} cursor="pointer" style={{ color: 'blue' }} fontSize={{ base: 'md' }} color='blue.600'>
                  <DownloadIcon /> Download Resume ({user?.resume.filename_download})
                </Link>
              )}
              <Input type="file" id='resume' name="resume" onChange={handleResumeChange} />
              
              {text('body.regular', 'Profile Picture')}
              {(user?.avatar || user?.avatarUrl) && (
                <Avatar
                  name={getFullName(user)}
                  src={getAvatarUrl(user)}
                />
              )}
              <Input type="file" id='avatar' name="avatar" onChange={handleAvatarUpload} />

              <Heading fontSize="2xl">Preferences</Heading>
              {renderRadios('locationType', 'Location Type', [
                { label: 'Remote', slug: 'remote' },
                { label: 'Office', slug: 'office' },
                { label: 'Hybrid', slug: 'hybrid' },
              ], required, errors, touched)}
              {renderRadios('workType', 'Work Type', [
                { label: 'Contract', slug: 'contract' },
                { label: 'Full-Time', slug: 'full-time' },
                { label: 'Part-Time', slug: 'part-time' },
              ], required, errors, touched)}
              {/* {renderInput('text', 'salary', 'Salary', required, errors, touched)} */}
              <Button onClick={() =>
                toast({
                  title: 'Profile is updated.',
                  description: "Your profile was updated.",
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                })}
                type="submit" colorScheme={"blue"}>Submit</Button>
            </VStack>
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default UserProfile;