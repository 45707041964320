import { RouteObject } from "react-router-dom";
import HomeContainer from "./HomeContainer";
import RecruiterMatchContainer from "./recruiter/RecruiterMatchContainer";
import UserJobProfileContainer from "./user/UserJobProfileContainer";
import UserJobMatchContainer from "./user/UserJobMatchContainer";
import RecruiterJobPostsContainer from "./recruiter/RecruiterJobPostsContainer";
import UserJobRatingContainer from "./user/UserJobRatingContainer";
import ProfileContainer from "./ProfileContainer";
import RecruiterJobPostContainer from "./recruiter/RecruiterJobPostContainer";
import RecruiterJobSelectContainer from "./recruiter/RecruiterJobSelectContainer";
import UserJobSelectContainer from "./user/UserJobSelectContainer";
import UserJobRatingCreateContainer from "./user/UserJobRatingCreateContainer";
import LoginContainer from "./auth/LoginContainer";
import ResetPasswordContainer from "./auth/ResetPasswordContainer";
import ReportsContainer from "./ReportsContainer";

//Store Providers
import EvidenceProvider from "../stores/EvidenceStore";
import JobPostProvider from "../stores/JobPostProvider";
import JobProvider from "../stores/JobProvider";
import MatchProvider from "../stores/MatchProvider";
import RatingProvider from "../stores/RatingProvider";
import JobPostSaveProvider from "../stores/JobPostSaveStore";
import JobPostStatusProvider from "../stores/JobPostStatusStore";
import JobMatchActivityProvider from "../stores/JobMatchActivityProvider";
import RecruiterJobPostCreateContainer from "./recruiter/RecruiterJobPostCreateContainer";
import CompanyProfileContainer from "./CompanyProfileContainer";
import SingleMatchContainer from "./SingleMatchContainer";

type Route = {
  routeObj: RouteObject,
  isProtected: Boolean
}

const routes: Route[] = [
  {
    routeObj: {
      path: "/",
      element:
        <MatchProvider>
          <JobPostProvider>
            <HomeContainer />
          </JobPostProvider>
        </MatchProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/verify",
      element: <ResetPasswordContainer />
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/update-profile",
      element: <ProfileContainer />
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobMatches",
      element:
        <JobPostSaveProvider>
          <JobPostStatusProvider>
            <MatchProvider>
              <JobMatchActivityProvider>
                <UserJobMatchContainer />
              </JobMatchActivityProvider>
            </MatchProvider>
          </JobPostStatusProvider>
        </JobPostSaveProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobProfile",
      element:
        <EvidenceProvider>
          <RatingProvider>
            <UserJobProfileContainer />
          </RatingProvider>
        </EvidenceProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobRating/:jobRatingId",
      element:
        <EvidenceProvider>
          <RatingProvider>
            <UserJobRatingContainer />
          </RatingProvider>
        </EvidenceProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobProfile/selectJob",
      element:
        <JobProvider>
          <RatingProvider>
            <UserJobSelectContainer />
          </RatingProvider>
        </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobProfile/create/:jobId",
      element:
        <JobProvider>
          <RatingProvider>
            <EvidenceProvider>
              <UserJobRatingCreateContainer />
            </EvidenceProvider>
          </RatingProvider>
        </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/profileMatches",
      element:
        <MatchProvider>
          <JobMatchActivityProvider>
            <RecruiterMatchContainer />
          </JobMatchActivityProvider>
        </MatchProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobs",
      element:
        <JobProvider>
          <JobPostProvider>
            <RecruiterJobSelectContainer />
          </JobPostProvider>
        </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobPosts",
      element:
      <JobProvider>
        <JobMatchActivityProvider>
          <JobPostProvider>
            <RecruiterJobPostsContainer />
          </JobPostProvider>
        </JobMatchActivityProvider>
      </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobPost/selectJob",
      element:
        <JobProvider>
          <JobPostProvider>
            <RecruiterJobSelectContainer />
          </JobPostProvider>
        </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobPost/create/:jobId",
      element:
        <JobProvider>
           <JobMatchActivityProvider>
            <JobPostProvider>
              <RecruiterJobPostCreateContainer />
            </JobPostProvider>
          </JobMatchActivityProvider>
        </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/jobPost/:jobPostId",
      element:
      <JobProvider>
        <JobPostProvider>
          <RecruiterJobPostContainer />
        </JobPostProvider>
       </JobProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/profile",
      element: <ProfileContainer />
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/match",
      element: <MatchProvider><SingleMatchContainer /></MatchProvider>
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/companyProfile",
      element: <CompanyProfileContainer />
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/login",
      element: <LoginContainer />
    },
    isProtected: false
  },
  {
    routeObj: {
      path: "/resetpassword",
      element: <ResetPasswordContainer />
    },
    isProtected: true
  },
  {
    routeObj: {
      path: "/reports",
      element:
        <MatchProvider>
          <ReportsContainer />
        </MatchProvider>
    },
    isProtected: true
  },
];

export default routes;
