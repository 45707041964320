import { Box, Button, Heading, useToast, VStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { renderInput, renderRadios } from "../../util/forms";
import { IUser } from "../../../types/user";
interface RecruiterProfileProps {
  user?: IUser;
  onSubmit: (values: any) => void;
}

const required = { isRequired: true };
const RecruiterProfile = ({ user, onSubmit, }: RecruiterProfileProps) => {
  const toast = useToast();
  return (
    <Box>
      <Formik
        initialValues={{
          username: user ? user.username : '',
          firstName: user ? user.firstName : '',
          lastName: user ? user.lastName : '',
          headline: user?.headline === null ? '' : user?.headline,
          email: user ? user.email : '',
          location: user ? user.location : '',
          summary: user ?  user.summary : '' ,
          locationType: user?.locationType || 'N/A',
          workType: user?.workType || 'N/A',
          salary: user?.salary || 0,
          linkedIn: user?.linkedIn === null ? '' : user?.linkedIn,
        }}
        onSubmit={(values) => {
          const val: any = {
            first_name: values.firstName,
            last_name: values.lastName,
            headline: values.headline,
            email: values.email,
            location: values.location,
            summary: values.summary,
            locationType: values.locationType,
            workType: values.workType,
            salary: values.salary,
            linkedIn: values.linkedIn,
          }
          onSubmit(val);
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <Heading fontSize="2xl">Basic Information</Heading>
              {renderInput('text', 'firstName', 'First Name', required, errors, touched)}
              {renderInput('text', 'lastName', 'Last Name', required, errors, touched)}
              {renderInput('text', 'headline', 'Headline', {isRequired: false} , errors, touched)}
              {renderInput('email', 'email', 'Email', required, errors, touched)}
              {renderInput('text', 'location', 'Location (e.g. City, State)', required, errors, touched)}
              {renderInput('text', 'summary', 'Brief Intro', required, errors, touched)}
              {renderInput('text', 'linkedIn', 'LinkedIn Link (https://www.linkedin.com/in/)', {isRequired: false} , errors, touched)}
              <Button onClick={() =>
                toast({
                  title: 'Profile is updated.',
                  description: "Your Profile was updated in the Recruiter Profile.",
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                })}
                type="submit" colorScheme={"blue"}>Submit</Button>
            </VStack>
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default RecruiterProfile;