import { useEffect, useState } from "react";
import { Box, Flex, Center, Spinner, Select, HStack } from "@chakra-ui/react";
import { UserContextValue, useUser } from "../../stores/UserStore";
import MatchContainer from "../match/MatchContainer";
import { UserRole } from "../../types/user";
import { text } from "../../components/util/texts";

const NavigatorHomeContainer = () => {
  const [selectedJobSeekerId, setSelectedJobSeekerId] = useState<string | undefined>();

  const { 
    currentUser,
    jobSeekersForNavigator,
    isFetchingJobSeekersForNavigator,
    fetchJobSeekersForNavigator
  } = useUser() as UserContextValue;

  useEffect(() => {
    if (currentUser) {
      fetchJobSeekersForNavigator()
    }
  }, [])

  // useEffect(() => {
  //   if (currentUser && selectedJobSeeker) {
  //     fetchMatches({ searchTerm: '', filterValues: [
  //       { slug: 'user', selectedValue: selectedJobSeeker.id as string }
  //     ]})
  //   }
  // }, [selectedJobSeeker]);

  if (isFetchingJobSeekersForNavigator) {
    return (
      <Center height="90vh">
        <Spinner marginX="auto" thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Center>
    )
  }

  if (!currentUser || !jobSeekersForNavigator) {
    return null;
  }

  // const onboardingSteps = JobSeekerHomeSteps;
  // const showOnboarding = currentUser.onboardingStage === OnboardingStatus.ProductTour;

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log('select job seeker', event.target.value)
    setSelectedJobSeekerId(event.target.value);
  };

  return (
    <Box>
      <Box px={5} py={3} borderBottom="solid 2px" borderColor="gray.200" bg='gray.200'>
        <HStack gap={2}>
          <Box>
            {text('body.regular', 'Select Candidate to View Job Matches:', 0)}
          </Box>          
          <Box>
            <Select
              borderColor="blue.500"
              placeholder='Select'
              onChange={handleSelect}
            >
              {jobSeekersForNavigator.map((jobSeeker: any) => (
                <option value={jobSeeker['id']}>{jobSeeker['first_name']} {jobSeeker['last_name']}</option>
              ))} 
            </Select>
          </Box>          
        </HStack>  
      </Box>          
      {selectedJobSeekerId && (
        <MatchContainer userRole={UserRole.Navigator} selectedJobSeekerId={selectedJobSeekerId} />
      )}
    </Box>
  );
};

export default NavigatorHomeContainer;