import { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  Link
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { IEvidence } from "../../types/evidence";
import { DownloadIcon } from "@chakra-ui/icons";
import { ApiInstance } from "../../api/ApiInstance";

interface AddOrUpdateEvidenceProps {
  onSubmit: (formValues: any, file?: File) => void;
  onCancel: () => void;
  evidence?: IEvidence | undefined | null;
  formMode: "Create" | "Update";
}

const AddOrUpdateEvidence = ({
  onSubmit,
  onCancel,
  evidence,
  formMode,
}: AddOrUpdateEvidenceProps) => {
  const [evidenceMode, setEvidenceMode] = useState<'link' | 'file'>(evidence?.fileUpload ? 'file' : 'link');
  const [file, setFile] = useState<File | undefined>();

  const toast = useToast();

  const handleEvidenceUpload = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          title: evidence ? evidence.title : "",
          description: evidence ? evidence.description : "",
          link: evidence ? evidence.link : "",
        }}
        onSubmit={(values) => {
          if (formMode === "Create") {
            onSubmit(values, file);
          } else if (formMode === "Update") {
            if (evidence) {
              // clear out stale link or file upload
              const overrides: any = {};
              if (evidence.link && evidenceMode === 'file' && file) {
                overrides.link = null;
              }
              else if (evidence.fileUpload && evidenceMode === 'link' && values.link) {
                overrides.fileUpload = null;
              }

              const evidenceToBeUpdated: IEvidence = {
                id: evidence.id,
                userId: evidence.userId,
                jobRatingId: evidence.jobRatingId,
                ...values,
                ...overrides,
              };
              onSubmit(evidenceToBeUpdated, file);
            }            
          }
          onCancel();
        }}
      >
        {({ handleSubmit, errors, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl isInvalid={!!errors.title && touched.title} isRequired>
              <FormLabel htmlFor="title">Title</FormLabel>
              <Field
                as={Input}
                id="title"
                name="title"
                type="name"
                variant="filled"
                validate={(value: any) => {
                  let error;

                  if (value.length < 1) {
                    error = "Title is Requiried";
                  }

                  return error;
                }}
              />
              <FormErrorMessage>{errors.title}</FormErrorMessage>
            </FormControl>
            <FormControl marginTop={"15px"} isRequired>
              <FormLabel htmlFor="description">Description</FormLabel>
              <Field
                as={Input}
                id="description"
                name="description"
                type="text"
                variant="filled"
              />
            </FormControl>
            <FormControl marginTop={"15px"} isRequired>
              <FormLabel htmlFor="link">Link or Upload</FormLabel>
              <ButtonGroup isAttached mb={3}>
                <Button onClick={() => setEvidenceMode('link')} size="sm" colorScheme={evidenceMode === 'link' ? 'blue' : 'gray'} variant={evidenceMode === 'link' ? 'solid' : 'outline'}>Add Link</Button>
                <Button onClick={() => setEvidenceMode('file')} size="sm" colorScheme={evidenceMode === 'file' ? 'blue' : 'gray'} variant={evidenceMode === 'file' ? 'solid' : 'outline'}>Upload a File</Button>
              </ButtonGroup>
              { evidenceMode === 'link' && (
                <Field
                  as={Input}
                  id="link"
                  name="link"
                  type="url"
                  placeholder="https://example.com"
                  variant="filled"
                />  
              )}
              { evidenceMode === 'file' && (
                <Box>
                  {evidence?.fileUpload && (
                    <Link href={`${ApiInstance.assetsUrl}/${evidence.fileUpload.id}?access_token=${localStorage.getItem("authorizationToken")}&download`} target="_blank" download={evidence.fileUpload.filename_download} cursor="pointer" style={{ color: 'blue' }} fontSize={{ base: 'md' }} color='blue.600'>
                      <DownloadIcon /> Download File ({evidence.fileUpload.filename_download})
                    </Link>
                  )}
                  <Field
                    as={Input}
                    id="file"
                    name="file"
                    type="file"
                    placeholder="Upload evidence file from your computer"
                    variant="filled"
                    onChange={handleEvidenceUpload}
                  />
                </Box>                  
              )}
            </FormControl>
            <Box marginTop={"20px"}>
              <Button
                onClick={() =>
                  toast({
                    title: "Evidence created.",
                    description: "The New Evidence was added.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                }
                type="submit"
                colorScheme="teal"
              >
                Submit
              </Button>
              <Button onClick={() => onCancel()} ml={"2"} colorScheme="red">
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
export default AddOrUpdateEvidence;
